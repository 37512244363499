<template>
  <ion-modal :is-open="isOpenModal" :backdropDismiss="false" mode="md">
    <ion-page>
      <ion-content>
        <ion-grid>
          <ion-row>
            <div class="form-logo">
              <ion-img :src="TreeDots" />
            </div>
          </ion-row>
          <div class="form-text">
            <ion-text><div class="form-text-title">This app is currently down for maintenance</div></ion-text>
            <ion-text
              ><div class="form-text-content">
                We apologize for any inconvenience caused {{ description }}.
              </div></ion-text
            >
            <ion-text
              ><div class="form-text-time">
                We will be back
                {{ !isCountdown ? 'shortly!' : hours + ':' + minutes + ':' + seconds + 's' }}
              </div></ion-text
            >
          </div>
          <div class="form-maintenan">
            <ion-img :src="MaintenanceImage" />
          </div>
        </ion-grid>
        <ion-row>
          <ion-button mode="md" @click="handleRefresh">
            <ion-icon :icon="refreshOutline" class="card-icon" />Refresh Page
          </ion-button>
        </ion-row>
      </ion-content>
    </ion-page>
  </ion-modal>
</template>
<script>
import MaintenanceImage from '@/assets/images/newdesign/MaintenanceImage.svg';
import TreeDots from '@/assets/images/newdesign/TreeDots_Logo.png';
import { ACTIONS } from '@/modules/b2b/store/version/actions';
import { alertController, toastController } from '@ionic/vue';
import { refreshOutline } from 'ionicons/icons';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/version');

export default {
  setup() {
    return {
      TreeDots,
      MaintenanceImage,
      refreshOutline
    };
  },
  data() {
    return {
      countDownDate: '',
      hours: '',
      minutes: '',
      seconds: '',
      description: '',
      isCountdown: false,
      isOpenModal: false,
      isRefresh: false,
      isToast: false
    };
  },
  mounted() {
    this.handleGetMaintenanceInfo();
  },
  watch: {
    $route() {
      this.handleGetMaintenanceInfo();
    }
  },
  computed: {
    ...mapGetters(['maintenance'])
  },
  methods: {
    ...mapActions([ACTIONS.CHECK_MAINTENANCE_APP]),
    async handleRefresh() {
      this.isToast = true;
      this.handleGetMaintenanceInfo();
    },
    async openToast() {
      const toast = await toastController.create({
        position: 'top',
        mode: 'ios',
        color: 'dark',
        message: 'Still under maintenance! Try again later.',
        duration: 2000
      });
      return toast.present();
    },
    async handleGetMaintenanceInfo() {
      try {
        await this[ACTIONS.CHECK_MAINTENANCE_APP]();
        if (this.maintenance?.isMaintenance) {
          if (Number(this.maintenance.time_remaining) < 10800000) {
            this.isCountdown = false;
          } else {
            this.countdownTimeStart(this.maintenance.time_remaining);
            this.isCountdown = true;
          }
          this.description = this.maintenance.description;
          this.isOpenModal = true;
          if (this.isToast) this.openToast();
        } else {
          if (this.isToast) this.$router.go('');
          this.isOpenModal = false;
          this.isToast = false;
        }
      } catch (e) {
        await this.presentAlert(e);
      }
    },
    countdownTimeStart(params) {
      this.countDownDate = new Date().getTime() + Number(params);
      setInterval(() => {
        var now = new Date().getTime();
        const distance = Number(this.countDownDate) - Number(now);
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
    async presentAlert(message) {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: 'Notice',
        message: message,
        buttons: [this.$t('OK')],
        backdropDismiss: false
      });
      await alert.present();
    }
  }
};
</script>

<style src="./style.scss" lang="scss" scoped></style>
