import { createRouter, createWebHistory } from '@ionic/vue-router';
import sharedRoutes from '../modules/shared/router';
import b2bRoutes from '../modules/b2b/router';
import saleRoutes from '../modules/sale/router';

const routes = [...sharedRoutes, ...b2bRoutes, ...saleRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
