<template>
  <ion-page>
    <ion-header no-border class="bar-header">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/" @click="$emit('cancelOtp')"></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-no-padding">{{ $t('verify_mobile_num') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="content">
        <ion-row class="box">
          <ion-col size="12" class="mb-2">
            <ion-img class="img" src="/assets/images/drawer_access_denied.svg" />
          </ion-col>
          <ion-col size="12">
            <ion-label class="fw-bold fs-3 text-center">{{ $t('sorry_went_wrong') }}</ion-label>
          </ion-col>
          <ion-col size="12">
            <ion-label class="fs-2">{{ $t('we_detected_unusual_activity') }}</ion-label>
          </ion-col>
          <ion-col size="12">
            <ion-label class="fw-bold fs-4" color="primary">{{ minutes + ' : ' + seconds }}</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import dayjs from 'dayjs';
import { computed, defineComponent, inject, ref, watch } from 'vue';

export default defineComponent({
  name: 'error-otp',
  emits: ['cancelOtp'],
  async setup() {
    const storage = inject('$storage');
    const time = new Date();
    const oneHourNext = dayjs(time).add(60, 'minute');
    watch([minutes, seconds], async ([newMinutes, newSeconds]) => {
      if (newMinutes === 0 && newSeconds === 0) {
        clearInterval(interval);
      }
      await storage.setCountDownTime(dayjs(time).add(newMinutes, 'minutes'));
    });

    if ((await storage.getCountDownTime()) === null) {
      await storage.setCountDownTime(oneHourNext);
    }
    const timeNow = dayjs(time);
    const timeLeft = dayjs(await storage.getCountDownTime());

    const minutes = ref(timeLeft.diff(timeNow, 'minutes'));
    const seconds = ref(timeLeft.diff(timeNow, 'seconds') % 60);

    const interval = setInterval(async () => {
      if (seconds.value === 0 && minutes.value === 0) {
        clearInterval(interval);
        await storage.removeCountDownTime();
      } else if (seconds.value === 0) {
        minutes.value--;
        seconds.value = 59;
      } else {
        seconds.value--;
      }
    }, 1000);

    const formattedSeconds = computed(() => {
      if (seconds.value < 10) return `0${seconds.value}`;
      else return seconds.value;
    });

    const formattedMinutes = computed(() => {
      if (minutes.value < 10) return `0${minutes.value}`;
      else return minutes.value;
    });

    return { minutes: formattedMinutes, seconds: formattedSeconds };
  }
});
</script>

<style scoped lang="scss">
@import '@/views/Home/home.scss';
.box {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.img {
  width: 60%;
  margin: 0 auto;
}
</style>
