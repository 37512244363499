import { FAILURE, REQUEST, SUCCESS } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  GET_STATUS_CUSTOMER_REQUEST: 'GET_STATUS_CUSTOMER_REQUEST',
  GET_STATUS_CUSTOMER_SUCCESS: 'GET_STATUS_CUSTOMER_SUCCESS',
  GET_STATUS_CUSTOMER_FAILURE: 'GET_STATUS_CUSTOMER_FAILURE',

  GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',

  GET_CUSTOMERS_BRANCH_REQUEST: 'GET_CUSTOMERS_BRANCH_REQUEST',
  GET_CUSTOMERS_BRANCH_SUCCESS: 'GET_CUSTOMERS_BRANCH_SUCCESS',
  GET_CUSTOMERS_BRANCH_FAILURE: 'GET_CUSTOMERS_BRANCH_FAILURE',

  GET_VIEWS_CUSTOMER_TENANTS_REQUEST: 'GET_VIEWS_CUSTOMER_TENANTS_REQUEST',
  GET_VIEWS_CUSTOMER_TENANTS_SUCCESS: 'GET_VIEWS_CUSTOMER_TENANTS_SUCCESS',
  GET_VIEWS_CUSTOMER_TENANTS_FAILURE: 'GET_VIEWS_CUSTOMER_TENANTS_FAILURE',

  GET_UNCONNECTED_TENANT_REQUEST: 'GET_UNCONNECTED_TENANT_REQUEST',
  GET_UNCONNECTED_TENANT_SUCCESS: 'GET_UNCONNECTED_TENANT_SUCCESS',
  GET_UNCONNECTED_TENANT_FAILURE: 'GET_UNCONNECTED_TENANT_FAILURE',

  ADD_CUSTOMER_TENANT_JOIN_REQUEST: 'ADD_CUSTOMER_TENANT_JOIN_REQUEST',
  ADD_CUSTOMER_TENANT_JOIN_SUCCESS: 'ADD_CUSTOMER_TENANT_JOIN_SUCCESS',
  ADD_CUSTOMER_TENANT_JOIN_FAILURE: 'ADD_CUSTOMER_TENANT_JOIN_FAILURE',

  GET_CUSTOMER_VIEW_REQUEST: 'GET_CUSTOMER_VIEW_REQUEST',
  GET_CUSTOMER_VIEW_SUCCESS: 'GET_CUSTOMER_VIEW_SUCCESS',
  GET_CUSTOMER_VIEW_FAILURE: 'GET_CUSTOMER_VIEW_FAILURE',

  GET_CUSTOMER_DETAIL_SALE_REQUEST: 'GET_CUSTOMER_DETAIL_SALE_REQUEST',
  GET_CUSTOMER_DETAIL_SALE_SUCCESS: 'GET_CUSTOMER_DETAIL_SALE_SUCCESS',
  GET_CUSTOMER_DETAIL_SALE_FAILURE: 'GET_CUSTOMER_DETAIL_SALE_FAILURE',

  GET_CUSTOMER_IMAGE_REQUEST: 'GET_CUSTOMER_IMAGE_REQUEST',
  GET_CUSTOMER_IMAGE_SUCCESS: 'GET_CUSTOMER_IMAGE_SUCCESS',
  GET_CUSTOMER_IMAGE_FAILURE: 'GET_CUSTOMER_IMAGE_FAILURE',

  GET_SUB_BUYERS_REQUEST: 'GET_SUB_BUYERS_REQUEST',
  GET_SUB_BUYERS_SUCCESS: 'GET_SUB_BUYERS_SUCCESS',
  GET_SUB_BUYERS_FAILURE: 'GET_SUB_BUYERS_FAILURE',

  GET_GROUPS_AND_COUNTRIES_AND_DAYS_REQUEST: 'GET_GROUPS_AND_COUNTRIES_AND_DAYS_REQUEST',
  GET_GROUPS_AND_COUNTRIES_AND_DAYS_SUCCESS: 'GET_GROUPS_AND_COUNTRIES_AND_DAYS_SUCCESS',
  GET_GROUPS_AND_COUNTRIES_AND_DAYS_FAILURE: 'GET_GROUPS_AND_COUNTRIES_AND_DAYS_FAILURE',

  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILURE: 'UPDATE_CUSTOMER_FAILURE',

  GET_CUSTOMER_TENANTS_REQUEST: 'GET_CUSTOMER_TENANTS_REQUEST',
  GET_CUSTOMER_TENANTS_SUCCESS: 'GET_CUSTOMER_TENANTS_SUCCESS',
  GET_CUSTOMER_TENANTS_FAILURE: 'GET_CUSTOMER_TENANTS_FAILURE',

  ADD_PERSON_REQUEST: 'ADD_PERSON_REQUEST',
  ADD_PERSON_SUCCESS: 'ADD_PERSON_SUCCESS',
  ADD_PERSON_FAILURE: 'ADD_PERSON_FAILURE',

  ADD_ADDRESS_REQUEST: 'ADD_ADDRESS_REQUEST',
  ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
  ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',

  UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE',

  HOURS_ADD_REQUEST: 'HOURS_ADD_REQUEST',
  HOURS_ADD_SUCCESS: 'HOURS_ADD_SUCCESS',
  HOURS_ADD_FAILURE: 'HOURS_ADD_FAILURE',

  ADD_SUB_BUYER_REQUEST: 'ADD_SUB_BUYER_REQUEST',
  ADD_SUB_BUYER_SUCCESS: 'ADD_SUB_BUYER_SUCCESS',
  ADD_SUB_BUYER_FAILURE: 'ADD_SUB_BUYER_FAILURE',

  UPDATE_SUB_BUYER_REQUEST: 'UPDATE_SUB_BUYER_REQUEST',
  UPDATE_SUB_BUYER_SUCCESS: 'UPDATE_SUB_BUYER_SUCCESS',
  UPDATE_SUB_BUYER_FAILURE: 'UPDATE_SUB_BUYER_FAILURE',

  ADD_CUSTOMER_REQUEST: 'ADD_CUSTOMER_REQUEST',
  ADD_CUSTOMER_SUCCESS: 'ADD_CUSTOMER_SUCCESS',
  ADD_CUSTOMER_FAILURE: 'ADD_CUSTOMER_FAILURE',

  GET_HEAD_COMPANY_REQUEST: 'GET_HEAD_COMPANY_REQUEST',
  GET_HEAD_COMPANY_SUCCESS: 'GET_HEAD_COMPANY_SUCCESS',
  GET_HEAD_COMPANY_FAILURE: 'GET_HEAD_COMPANY_FAILURE',

  ADD_BRANCH_REQUEST: 'ADD_BRANCH_REQUEST',
  ADD_BRANCH_SUCCESS: 'ADD_BRANCH_SUCCESS',
  ADD_BRANCH_FAILURE: 'ADD_BRANCH_FAILURE',

  ADD_CUSTOMER_IMAGE_REQUEST: 'ADD_CUSTOMER_IMAGE_REQUEST',
  ADD_CUSTOMER_IMAGE_SUCCESS: 'ADD_CUSTOMER_IMAGE_SUCCESS',
  ADD_CUSTOMER_IMAGE_FAILURE: 'ADD_CUSTOMER_IMAGE_FAILURE',

  UPDATE_CUSTOMER_IMAGE_REQUEST: 'UPDATE_CUSTOMER_IMAGE_REQUEST',
  UPDATE_CUSTOMER_IMAGE_SUCCESS: 'UPDATE_CUSTOMER_IMAGE_SUCCESS',
  UPDATE_CUSTOMER_IMAGE_FAILURE: 'UPDATE_CUSTOMER_IMAGE_FAILURE',

  RESET_CUSTOMER_IMAGE: 'RESET_CUSTOMER_IMAGE',
  // Reset error and status back to default value
  RESET_STATUS_ERROR: 'RESET_STATUS_ERROR',

  UPDATE_SELECTED_CUSTOMER: 'UPDATE_SELECTED_CUSTOMER',

  DELETE_PERSON_REQUEST: 'DELETE_PERSON_REQUEST',
  DELETE_PERSON_SUCCESS: 'DELETE_PERSON_SUCCESS',
  DELETE_PERSON_FAILURE: 'DELETE_PERSON_FAILURE',

  GET_CUSTOMER_DETAILS_SUCCESS: 'GET_CUSTOMER_DETAILS_SUCCESS',
  GET_CUSTOMER_DETAILS_FAILURE: 'GET_CUSTOMER_DETAILS_FAILURE',

  CHANGE_TOTAL_COUNT: 'CHANGE_TOTAL_COUNT'
};

export default {
  [MUTATIONS.UPDATE_SELECTED_CUSTOMER](state, selectedCustomer) {
    state.selectedCustomer = selectedCustomer;
  },

  // GET_CUSTOMER_VIEW
  [MUTATIONS.GET_CUSTOMER_VIEW_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CUSTOMER_VIEW_SUCCESS](state, customerView) {
    SUCCESS(state);
    state.customerView = customerView;
  },
  [MUTATIONS.GET_CUSTOMER_VIEW_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // ADD_CUSTOMER_JOIN_TENANT
  [MUTATIONS.ADD_CUSTOMER_JOIN_TENANT_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_CUSTOMER_JOIN_TENANT_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_CUSTOMER_JOIN_TENANT_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // GET_STATUS_CUSTOMER
  [MUTATIONS.GET_STATUS_CUSTOMER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_STATUS_CUSTOMER_SUCCESS](state, joinStatus) {
    SUCCESS(state);
    state.joinStatus = joinStatus;
  },
  [MUTATIONS.GET_STATUS_CUSTOMER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.GET_CUSTOMERS_REQUEST](state, isLoadMore) {
    if (!isLoadMore) {
      REQUEST(state);
    }
    state.isLoadMore = isLoadMore;
  },
  [MUTATIONS.GET_CUSTOMERS_SUCCESS](state, { list }) {
    SUCCESS(state);
    if (state.isLoadMore) {
      const items = [...state.list.items, ...list.items];
      state.list = {
        ...list,
        items
      };
    } else {
      state.list = list;
    }
  },
  [MUTATIONS.GET_CUSTOMERS_FAILURE](state, error) {
    FAILURE(state, error);
  },
  // search
  [MUTATIONS.UPDATE_QUERY_PARAMS](state, { params }) {
    state.params = {
      ...state.params,
      ...params
    };
  },

  // GET_CUSTOMER_BRANCH
  [MUTATIONS.GET_CUSTOMERS_BRANCH_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CUSTOMERS_BRANCH_SUCCESS](state, customerBranch) {
    SUCCESS(state);
    state.customerBranch = customerBranch;
  },
  [MUTATIONS.GET_CUSTOMERS_BRANCH_FAILURE](state, error) {
    FAILURE(state, error);
  },
  // view customer tenant
  [MUTATIONS.GET_VIEWS_CUSTOMER_TENANTS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_VIEWS_CUSTOMER_TENANTS_SUCCESS](state, customerConnect) {
    SUCCESS(state);
    state.customerConnect = customerConnect;
  },
  [MUTATIONS.GET_VIEWS_CUSTOMER_TENANTS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // unconnect tenant
  [MUTATIONS.GET_UNCONNECTED_TENANT_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_UNCONNECTED_TENANT_SUCCESS](state, customerConnect) {
    SUCCESS(state);
    state.customerConnect = customerConnect;
  },
  [MUTATIONS.GET_UNCONNECTED_TENANT_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.ADD_CUSTOMER_TENANT_JOIN_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_CUSTOMER_TENANT_JOIN_SUCCESS](state, customerConnect) {
    SUCCESS(state);
    state.customerConnect = customerConnect;
  },
  [MUTATIONS.ADD_CUSTOMER_TENANT_JOIN_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get customer detail buyer
  [MUTATIONS.GET_CUSTOMER_DETAIL_SALE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CUSTOMER_DETAIL_SALE_SUCCESS](state, saleGetCustomerDetail) {
    SUCCESS(state);
    state.saleGetCustomerDetail = saleGetCustomerDetail;
  },
  [MUTATIONS.GET_CUSTOMER_DETAIL_SALE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get customer image
  [MUTATIONS.GET_CUSTOMER_IMAGE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CUSTOMER_IMAGE_SUCCESS](state, customerImage) {
    SUCCESS(state);
    state.customerImage = customerImage;
  },
  [MUTATIONS.GET_CUSTOMER_IMAGE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get customer detail buyer
  [MUTATIONS.GET_SUB_BUYERS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_SUB_BUYERS_SUCCESS](state, subBuyers) {
    SUCCESS(state);
    state.subBuyers = subBuyers;
  },
  [MUTATIONS.GET_SUB_BUYERS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get groups and countries and days
  [MUTATIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS_SUCCESS](state, groupsAndCountriesAndDays) {
    SUCCESS(state);
    state.groupsAndCountriesAndDays = groupsAndCountriesAndDays;
  },
  [MUTATIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update customer
  [MUTATIONS.UPDATE_CUSTOMER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_CUSTOMER_SUCCESS](state, updateCustomer) {
    SUCCESS(state);
    state.updateCustomer = updateCustomer;
  },
  [MUTATIONS.UPDATE_CUSTOMER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get customer tenants
  [MUTATIONS.GET_CUSTOMER_TENANTS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CUSTOMER_TENANTS_SUCCESS](state, customerTenants) {
    SUCCESS(state);
    state.customerTenants = customerTenants;
  },
  [MUTATIONS.GET_CUSTOMER_TENANTS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add person
  [MUTATIONS.ADD_PERSON_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_PERSON_SUCCESS](state, addPerson) {
    SUCCESS(state);
    state.addPerson = addPerson;
  },
  [MUTATIONS.ADD_PERSON_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add address
  [MUTATIONS.ADD_ADDRESS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_ADDRESS_SUCCESS](state, addAddress) {
    SUCCESS(state);
    state.addAddress = addAddress;
  },
  [MUTATIONS.ADD_ADDRESS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update address
  [MUTATIONS.UPDATE_ADDRESS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_ADDRESS_SUCCESS](state, updateAddressB2b) {
    SUCCESS(state);
    state.updateAddressB2b = updateAddressB2b;
  },
  [MUTATIONS.UPDATE_ADDRESS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // hours add
  [MUTATIONS.HOURS_ADD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.HOURS_ADD_SUCCESS](state, hoursAdd) {
    SUCCESS(state);
    state.hoursAdd = hoursAdd;
  },
  [MUTATIONS.HOURS_ADD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add sub buyer
  [MUTATIONS.ADD_SUB_BUYER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_SUB_BUYER_SUCCESS](state, hoursAdd) {
    SUCCESS(state);
    state.hoursAdd = hoursAdd;
  },
  [MUTATIONS.ADD_SUB_BUYER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update sub buyer
  [MUTATIONS.UPDATE_SUB_BUYER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_SUB_BUYER_SUCCESS](state, hoursAdd) {
    SUCCESS(state);
    state.hoursAdd = hoursAdd;
  },
  [MUTATIONS.UPDATE_SUB_BUYER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add customer
  [MUTATIONS.ADD_CUSTOMER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_CUSTOMER_SUCCESS](state, addCustomer) {
    SUCCESS(state);
    state.addCustomer = addCustomer;
  },
  [MUTATIONS.ADD_CUSTOMER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get head company
  [MUTATIONS.GET_HEAD_COMPANY_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_HEAD_COMPANY_SUCCESS](state, headCompany) {
    SUCCESS(state);
    state.headCompany = headCompany;
  },
  [MUTATIONS.GET_HEAD_COMPANY_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add branch
  [MUTATIONS.ADD_BRANCH_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_BRANCH_SUCCESS](state, addBranch) {
    SUCCESS(state);
    state.addBranch = addBranch;
  },
  [MUTATIONS.ADD_BRANCH_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add customer image
  [MUTATIONS.ADD_CUSTOMER_IMAGE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_CUSTOMER_IMAGE_SUCCESS](state, saleAddCustomerImage) {
    SUCCESS(state);
    state.saleAddCustomerImage = saleAddCustomerImage;
  },
  [MUTATIONS.ADD_CUSTOMER_IMAGE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update customer image
  [MUTATIONS.UPDATE_CUSTOMER_IMAGE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_CUSTOMER_IMAGE_SUCCESS](state, saleUpdateCustomerImage) {
    SUCCESS(state);
    state.saleUpdateCustomerImage = saleUpdateCustomerImage;
  },
  [MUTATIONS.UPDATE_CUSTOMER_IMAGE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.RESET_CUSTOMER_IMAGE](state) {
    state.customerImage = [];
  },
  // Reset status and error back to default value
  [MUTATIONS.RESET_STATUS_ERROR](state) {
    state.status = true;
    state.error = null;
  },

  // delete person
  [MUTATIONS.DELETE_PERSON_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.DELETE_PERSON_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.DELETE_PERSON_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get customer details
  [MUTATIONS.GET_CUSTOMER_DETAILS_SUCCESS](state, { customerDetails }) {
    SUCCESS(state);
    state.customerDetails = customerDetails;
  },
  [MUTATIONS.GET_CUSTOMER_DETAILS_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.CHANGE_TOTAL_COUNT](state) {
    state.totalCount = Date.now();
  }
};
