import { getAppVersion } from '@/modules/sale/services/graphql';
import { MUTATIONS } from './mutations';
import { apolloClient } from '@/main';
export const ACTIONS = {
  CHECK_VERSION_APP: 'CHECK_VERSION_APP'
};

export default {
  async [ACTIONS.CHECK_VERSION_APP]({ commit }, params) {
    const { data } = await apolloClient.query({
      query: getAppVersion,
      variables: params
    });
    commit(MUTATIONS.CHECK_VERSION_APP, data);
  }
};
