import gql from 'graphql-tag';

export const addCustomerImage = gql`
  mutation addCustomerImageB2b($payload: [CustomerImageInput]) {
    addCustomerImageB2b(payload: $payload)
  }
`;

export const updateCustomerImage = gql`
  mutation updateCustomerImageB2b($id: [Int]) {
    updateCustomerImageB2b(id: $id)
  }
`;

export const getStatusCustomerJoinTenant = gql`
  query getStatusCustomerJoinTenant($customer_id: Int!, $tenant_id: Int!) {
    getStatusCustomerJoinTenant(customer_id: $customer_id, tenant_id: $tenant_id) {
      tenantId
      status
    }
  }
`;

export const getUserCustomerBranch = gql`
  query getUserCustomerBranch(
    $tenant_id: Int
    $id: Int
    $searchQuery: String
    $isIncludeTotalCount: Boolean
    $limit: Int
    $offset: Int
    $tagged: Boolean
  ) {
    getUserCustomerBranch(
      tenant_id: $tenant_id
      id: $id
      searchQuery: $searchQuery
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
      tagged: $tagged
    ) {
      totalCount
      first_company_status
      companiesInReviews {
        id
        name
        account_number
        customer_type_id
        address
        hub
        halal_products
        xero_id
        minimum_order
        delivery_charge
        payment_type
        storecove_id
        peppol_id
        user_customer_id
        user_id
        user_type_id
        tagged
        stripe_customer_id
        stripe_card_id
        branches {
          id
          branch
          account_number
          user_type_id
          buyer_type
          stripe_customer_id
          stripe_card_id
          xero_id
          payment_type
          minimum_order
          delivery_charge
          storecove_id
          peppol_id
          persons {
            phone
          }
        }
        persons {
          phone
        }
      }
      data {
        id
        name
        account_number
        customer_type_id
        address
        hub
        halal_products
        xero_id
        minimum_order
        delivery_charge
        payment_type
        storecove_id
        peppol_id
        user_customer_id
        user_id
        user_type_id
        tagged
        stripe_customer_id
        stripe_card_id
        branches {
          id
          address
          branch
          account_number
          user_type_id
          buyer_type
          stripe_customer_id
          stripe_card_id
          xero_id
          payment_type
          minimum_order
          delivery_charge
          storecove_id
          peppol_id
          persons {
            phone
          }
        }
        persons {
          phone
        }
      }
    }
  }
`;

export const getViewsCustomerTenants = gql`
  query getViewsCustomerTenants($id: Int!) {
    getViewsCustomerTenants(id: $id) {
      id
      name
      tenant_name
      customer_type_id
      hub
      tenant_id
      tenant_class_id
      xero_id
      payment_type
      active
      xero_tenant_id
    }
  }
`;

export const getUnconnectedTenant = gql`
  query GET_UNCONNECTED_TENANT(
    $customerId: Int!
    $keyword: String
    $isProduction: Boolean
    $userCountryId: Int!
  ) {
    getUnconnectedTenant(
      customerId: $customerId
      keyword: $keyword
      isProduction: $isProduction
      userCountryId: $userCountryId
    ) {
      id
      name
      unique_id
      registration_number
      request_join_status
    }
  }
`;

export const addCustomerTenantJoinRequest = gql`
  mutation addCustomerTenantJoinRequest(
    $customer_id: Int!
    $tenant_id: Int!
    $created_by: Int!
    $updated_by: Int
    $approved: Boolean
  ) {
    addCustomerTenantJoinRequest(
      customer_id: $customer_id
      tenant_id: $tenant_id
      created_by: $created_by
      updated_by: $updated_by
      approved: $approved
    )
  }
`;

export const getCustomerView = gql`
  query getCustomerView($id: Int!, $tenantIds: [Int]) {
    getCustomerView(id: $id, tenantIds: $tenantIds) {
      id
      name
      tenant_id
      payment_type
      default_credit_card_term
      delivery_instruction
      customer_credit_term {
        customerCreditTermId
        tenantCreditTermId
        displayName
        termPeriod
        gracePeriod
      }
      default_address {
        id
        customer_id
        floor_number
        street_number
        road
        building
        unit
        stall
        city
        state
        postal_code
        latlng
        country_id
        address_type_id
        active
        is_default
      }
      keyPerson {
        first_name
        last_name
        email
        phone
      }
    }
  }
`;

export const getCustomerDetailBuyer = gql`
  query getCustomerDetailBuyer($id: Int!) {
    getCustomerDetailBuyer(id: $id) {
      id
      name
      account_number
      customer_type_id
      group_id
      active
      profile
      profile_id
      halal_products
      cod
      minimum_order
      hub
      delivery_charge
      payment_type
      xero_id
      delivery_instruction
      customer_credit_term {
        displayName
      }
      group {
        id
        name
        description
        active
      }
      customerType {
        id
        name
        description
        active
      }
      addresses {
        id
        floor_number
        street_number
        road
        building
        unit
        stall
        city
        state
        postal_code
        country {
          name
        }
        country_id
        addressType {
          name
        }
        address_type_id
        latlng
        is_default
        active
      }
      working_hours {
        id
        day_id
        day_name
        open_hour
        open_minute
        close_hour
        close_minute
        active
      }
      persons {
        id
        first_name
        last_name
        email
        phone
        position
        active
        user_id
        language
      }
      branches {
        id
        branch
        user_type_id
        account_number
        buyer_type
        stripe_customer_id
        stripe_card_id
        xero_id
        payment_type
        minimum_order
        delivery_charge
        storecove_id
        peppol_id
        cod
        delivery_instruction
        customerImage {
          id
          image
        }
        addresses {
          id
          floor_number
          street_number
          road
          building
          unit
          stall
          city
          state
          postal_code
          country {
            name
          }
          country_id
          addressType {
            name
          }
          address_type_id
          latlng
          is_default
          active
        }
        halal_products
        profile
        profile_id
        working_hours {
          id
          day_id
          day_name
          open_hour
          open_minute
          close_hour
          close_minute
          active
        }
        sub_buyer {
          id
          first_name
          last_name
          mobile
          email
          active
          language
        }
        persons {
          id
          first_name
          last_name
          email
          phone
          position
          active
          user_id
          language
        }
      }
      customerImage {
        id
        image
      }
      sub_buyer {
        id
        first_name
        last_name
        mobile
        email
        active
        language
      }
    }
  }
`;

export const getCustomerImage = gql`
  query getCustomerImage($supplierId: Int!, $active: Boolean) {
    getCustomerImage(supplierId: $supplierId, active: $active) {
      id
      image
      customer_id
    }
  }
`;

export const getSubBuyers = gql`
  query getSubBuyers($supplierId: Int!) {
    getSubBuyers(supplierId: $supplierId) {
      id
      first_name
      last_name
      mobile
      email
      active
    }
  }
`;

export const getGroupsAndCountriesAndDays = gql`
  query getGroupsAndCountriesAndDays {
    getGroupsAndCountriesAndDays {
      countries {
        id
        name
        description
      }
      groups {
        id
        name
        description
      }
      days {
        id
        description
      }
    }
  }
`;

export const updateCustomer = gql`
  mutation updateCustomer($item: UpdateCustomerInput!) {
    updateCustomer(item: $item)
  }
`;

export const getCustomerTenants = gql`
  query getCustomerTenants($customer_id: Int!, $tenant_id: Int!) {
    getCustomerTenants(customer_id: $customer_id, tenant_id: $tenant_id) {
      id
      customer_id
      tenant_id
      active
      xero_customer_id
      alias_name
      cod
      payment_type
      delivery_instruction
      delivery_charge
      minimum_order
      commission_rate
      auto_create_spree
    }
  }
`;

export const addPerson = gql`
  mutation addPersonB2b($item: AddPersonB2bInputType!) {
    addPersonB2b(item: $item) {
      id
      customer_id
      first_name
      last_name
      email
      phone
      position
      is_default
      active
    }
  }
`;

export const addAddress = gql`
  mutation addAddress(
    $supplierId: Int!
    $floorNumber: String
    $streetNumber: String
    $road: String
    $building: String
    $unit: String
    $stall: String
    $city: String
    $state: String
    $postalCode: String!
    $latlng: LatLngInput
    $countryId: Int!
    $addressTypeId: Int!
    $isDefault: Boolean
  ) {
    addAddress(
      supplierId: $supplierId
      floorNumber: $floorNumber
      streetNumber: $streetNumber
      road: $road
      building: $building
      unit: $unit
      stall: $stall
      city: $city
      state: $state
      postalCode: $postalCode
      latlng: $latlng
      countryId: $countryId
      addressTypeId: $addressTypeId
      isDefault: $isDefault
    )
  }
`;

export const updateAddress = gql`
  mutation updateAddressB2b($payload: AddressCustomType, $addressId: Int!) {
    updateAddressB2b(payload: $payload, addressId: $addressId) {
      id
      customer_id
      floor_number
      street_number
      road
      building
      unit
      stall
      city
      state
      postal_code
      latlng
      country_id
      address_type_id
      active
      is_default
    }
  }
`;

export const hoursAdd = gql`
  mutation hoursAdd($isDashboardPage: Boolean!, $deliveryIntruction: String, $payload: [HourAddInput]) {
    hoursAdd(isDashboardPage: $isDashboardPage, deliveryIntruction: $deliveryIntruction, payload: $payload)
  }
`;

export const addSubBuyer = gql`
  mutation addSubBuyer(
    $country_id: Int!
    $customer_id: Int!
    $email: String!
    $mobile: String!
    $first_name: String!
    $last_name: String!
    $user_type_id: Int!
    $buyer_type: Int!
    $password: String!
  ) {
    addSubBuyer(
      country_id: $country_id
      customer_id: $customer_id
      email: $email
      mobile: $mobile
      first_name: $first_name
      last_name: $last_name
      user_type_id: $user_type_id
      buyer_type: $buyer_type
      password: $password
    )
  }
`;

export const updateSubBuyer = gql`
  mutation updateSubBuyer($id: Int!, $supplierId: Int!, $active: Boolean!) {
    updateSubBuyer(id: $id, supplierId: $supplierId, active: $active)
  }
`;

export const addCustomer = gql`
  mutation addCustomer($item: AddCustomerInput!) {
    addCustomer(item: $item) {
      id
    }
  }
`;

export const addBranch = gql`
  mutation addBranch($customerId: Int!, $customerBranchId: Int!) {
    addBranch(customerId: $customerId, customerBranchId: $customerBranchId)
  }
`;

export const deletePersonB2b = gql`
  mutation deletePersonB2b($customerId: Int!, $personId: Int!) {
    deletePersonB2b(customerId: $customerId, personId: $personId)
  }
`;
export const checkConnectPerson = gql`
  query checkConnectPerson($userId: Int!) {
    checkConnectPerson(userId: $userId) {
      id
      sale_user_name
      supplier_name
      customer_id
      customer_name
      user_id
      user_type_id
      status
      position
      customer {
        account_number
        addresses {
          id
          floor_number
          street_number
          road
          building
          unit
          stall
          city
          state
          postal_code
          latlng
        }
        customerImage {
          image
        }
      }
    }
  }
`;
export const addConnectPerson = gql`
  mutation addConnectPerson($requestId: Int!, $isAccept: Boolean) {
    addConnectPerson(requestId: $requestId, isAccept: $isAccept)
  }
`;

export const getCustomerDetails = gql`
  query getCustomerDetails($id: Int) {
    getCustomerDetails(id: $id) {
      totalCount
      data {
        id
        name
        account_number
        customer_type_id
        hub
        halal_products
        xero_id
        minimum_order
        delivery_charge
        payment_type
        storecove_id
        peppol_id
        user_customer_id
        user_id
        user_type_id
        tagged
        stripe_customer_id
        stripe_card_id
        persons {
          phone
        }
      }
    }
  }
`;

export const getCustomerProfiles = gql`
  query getCustomerProfiles {
    getCustomerProfiles {
      id
      name
    }
  }
`;
