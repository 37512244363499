export const defaultCountry = [
  {
    description: 'Malaysia',
    id: 127,
    name: 'MY'
  },
  {
    description: 'Singapore',
    id: 193,
    name: 'SG'
  },
  {
    description: 'Indonesia',
    id: 96,
    name: 'ID'
  }
];
export const defaultLanguages = [
  {
    value: 'English',
    key: 'en-US'
  },
  {
    value: 'Chinese (Simplified)',
    key: 'zh-CN'
  },
  {
    value: 'Bahasa Melayu',
    key: 'ms-MY'
  },
  {
    value: 'Bahasa Indonesia',
    key: 'id-ID'
  }
];

export const picDefaultLanguages = [
  {
    value: 'English',
    key: 'en-US'
  },
  {
    value: 'Chinese (Simplified)',
    key: 'zh-CN'
  },
  {
    value: 'Bahasa Melayu',
    key: 'ms-MY'
  },
  {
    value: 'Bahasa Indonesia',
    key: 'id-ID'
  },
  {
    value: 'Tamil',
    key: 'ta-IN'
  },
  {
    value: 'Thai',
    key: 'th-TH'
  }
];
