const FLUID_ICON = '/assets/icon/payment/fluid-icon.svg';

const TENANT_IDS = [1, 7];

const DEFAULT_CARD = {
  id: null,
  brand: '',
  exp_month: 0,
  exp_year: 0,
  country: '',
  funding: '',
  last4: '',
  fingerprint: ''
};

const DEFAULT_ORDER = {
  customerId: 0,
  standalone: false,
  deliveryAddressId: 0,
  billingAddressId: 0,
  cartItems: [],
  isBooking: false
};
const SUB_BUYER_ID = 12;

const PAYMENT_METHOD = {
  PAYNOW: 'paynow',
  NETS: 'nets',
  STRIPE: 'stripe',
  FLUID: 'fluid'
};

export { DEFAULT_CARD, DEFAULT_ORDER, FLUID_ICON, PAYMENT_METHOD, SUB_BUYER_ID, TENANT_IDS };
