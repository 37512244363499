import { customerGuard } from './guards';

const saleRoutes = [
  {
    path: '/sale/select-customer',
    name: 'sale-select-customer',
    component: () => import('./views/customer/SelectCustomerPage'),
    beforeEnter: customerGuard
  },
  {
    path: '/sale',
    redirect: '/sale/main',
    beforeEnter: customerGuard
  },
  {
    path: '/sale/main',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        redirect: '/sale/main/home'
      },
      {
        path: '/sale/main/home',
        component: () => import('./views/home/Home'),
        name: 'sale_home'
      }
    ]
  },
  {
    path: '/sale/main/product-detail/:id',
    component: () => import('./views/product-detail'),
    beforeEnter: customerGuard
  },
  {
    path: '/sale/main/product-detail/error',
    component: () => import('./views/product-detail/ErrorPage.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/sale/order',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('../shared/views/orders/list'),
        name: 'sale_order_list'
      }
    ]
  },
  {
    path: '/sale/order/purchase/order-detail', // Redirect from this path
    redirect: '/sale/order' // Redirect to this path
  },
  {
    path: '/sale/order/purchase/order-detail/:order_id',
    component: () => import('../shared/views/orders/order-detail/ViewOrder'),
    name: 'sale_order_detail'
  },
  {
    path: '/sale/order/purchase/track-order/:order_id',
    component: () => import('../shared/views/orders/order-detail/TrackOrder'),
    name: 'sale_track_order'
  },
  {
    path: '/sale/cart',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/cart/Cart'),
        name: 'sale_cart'
      }
    ]
  },
  {
    path: '/sale/account',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/Account'),
        name: 'account-sale'
      }
    ]
  },
  {
    path: '/sale/update-profile',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/UpdateProfile')
      }
    ]
  },
  {
    path: '/sale/change-password',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/ChangePassword')
      }
    ]
  },
  {
    path: '/sale/customers',
    component: () => import('./views/customer/SelectCustomerPage'),
    beforeEnter: customerGuard
  },
  {
    path: '/sale/customers/add',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/customer/AddCustomer')
      }
    ]
  },
  {
    path: '/sale/customers/add/no-companies',
    component: () => import('./views/Tabs'),
    children: [
      {
        path: '',
        component: () => import('./views/customer/AddCustomer')
      }
    ]
  },
  {
    path: '/sale/customers/:id',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/customer/CustomerDetail')
      }
    ]
  },
  {
    path: '/sale/about',
    name: 'About',
    component: () => import('@/modules/shared/views/AboutApp')
  },
  {
    path: '/sale/add-company',
    name: 'SaleAddCompany',
    component: () => import('./views/customer/AddCustomer')
  },
  {
    path: '/sale/invoices/select-invoices',
    name: 'select-invoices-sale',
    component: () => import('./views/invoices/select-invoices/index.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/sale/invoices/select-payment',
    name: 'select-payment-sale',
    component: () => import('./views/invoices/select-payment/index.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/sale/invoices/payment-details',
    name: 'payment-details-sale',
    component: () => import('./views/invoices/payment-details/index.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/sale/notifications/',
    name: 'SaleNotificationList',
    beforeEnter: customerGuard,
    component: () => import('./views/notifications/notifications-list/')
  },
  {
    path: '/sale/notifications/orders-and-invoices',
    name: 'SaleNotifOrderAndInvoicesList',
    beforeEnter: customerGuard,
    component: () => import('./views/notifications/orders-and-invoices')
  },
  {
    path: '/sale/notifications/products-updates',
    name: 'SaleNotifProductsUpdates',
    beforeEnter: customerGuard,
    component: () => import('./views/notifications/products-updates')
  },
  {
    path: '/sale/notifications/quotations-updates',
    name: 'SaleNotifQuotationsUpdates',
    beforeEnter: customerGuard,
    component: () => import('./views/notifications/quotations-updates')
  },
  {
    path: '/sale/notifications/company-info',
    name: 'SaleNotifCompanyInfo',
    beforeEnter: customerGuard,
    component: () => import('./views/notifications/company-info')
  },

  {
    path: '/sale/search-products',
    name: 'saleSearchProducts',
    component: () => import('./views/home/components/search-page/index')
  }
];

export default saleRoutes;
