export const MUTATIONS = {
  CHECK_VERSION_APP: 'CHECK_VERSION_APP',
  CHECK_MAINTENANCE_APP: 'CHECK_MAINTENANCE_APP'
};
export default {
  [MUTATIONS.CHECK_VERSION_APP](state, params) {
    state.version = params;
  },
  [MUTATIONS.CHECK_MAINTENANCE_APP](state, params) {
    state.maintenance = params;
  }
};
