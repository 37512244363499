import { DEFAULT_GETTERS } from '@/modules/b2b/constants';

export default {
  ...DEFAULT_GETTERS,
  customer: (state) => state.customer,
  list: (state) => state.list,
  totalCount: (state) => state.totalCount,
  params: (state) => state.params,
  joinStatus: (state) => (state.joinStatus ? state.joinStatus.status : ''),
  customerBranch: (state) => state.customerBranch,
  customerConnect: (state) => state.customerConnect,
  customerUnConnect: (state) => state.customerUnConnect,
  customerView: (state) => state.customerView,
  customerDetailBuyer: (state) => state.customerDetailBuyer,
  customerImage: (state) => state.customerImage,
  subBuyers: (state) => state.subBuyers,
  groupsAndCountriesAndDays: (state) => state.groupsAndCountriesAndDays,
  customerTenants: (state) => state.customerTenants,
  addCustomer: (state) => state.addCustomer,
  headCompany: (state) => state.headCompany,
  selectedCustomer: (state) => state.selectedCustomer,
  listData: (state) => state.list,
  customerProfile: (state) => state.customerProfile
};
