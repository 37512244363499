import { DEFAULT_GETTERS } from '@/modules/b2b/constants';

export default {
  ...DEFAULT_GETTERS,
  user: (state) => state.user,
  updateProfile: (state) => state.updateProfile,
  peppolSchemesAndCountries: (state) => state.peppolSchemesAndCountries,
  connectPeppolPage: (state) => state.connectPeppolPage,
  checkOldPassword: (state) => state.checkOldPassword,
  cropImg: (state) => state.cropImg,
  isRender: (state) => state.isRender
};
