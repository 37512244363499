import { DEFAULT_STATE } from '@/modules/sale/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  ...DEFAULT_STATE,
  list: [],
  notTaggedItemsIds: [],
  timeSlot: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
