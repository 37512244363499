<template #default>
  <ion-page>
    <ion-content :fullscreen="true" class="onboardingContainer">
      <div style="position: absolute; top: 10%; left: 12%; width: 20%">
        <ion-img src="/assets/images/treedots_logo.svg" />
      </div>
      <ion-slides pager="true" :options="slideOpts" class="onboardingSlider text-center" ref="slides">
        <ion-slide>
          <ion-grid>
            <ion-row>
              <div class="img">
                <ion-img class="onboardingImg mx-auto" src="/assets/images/boarding_cart.png" />
              </div>
              <div class="w-90-center">
                <ion-label class="onboardingTitle mx-auto">
                  <span class="text-primary-green-600">
                    {{ $t('high_quality') }}
                  </span>
                  <span class="text-gray-900">
                    {{ $t('and') }}
                  </span>
                  <span class="text-primary-green-600">
                    {{ `${$t('low_cost_food')} ` }}
                  </span>
                  <span class="text-gray-900">
                    {{ $t('for_your_business') }}
                  </span>
                </ion-label>
              </div>
            </ion-row>
            <ion-row class="bottom">
              <ion-col size="12">
                <ion-button class="onboardingButton" @click="onNextSlide(1)"> {{ $t('next') }}</ion-button>
              </ion-col>
              <ion-col size="12">
                <ion-button fill="clear" @click="onRedirectHome()"> {{ $t('skip') }}</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-slide>
        <ion-slide>
          <ion-grid>
            <ion-row>
              <div class="img">
                <ion-img class="onboardingImg mx-auto" src="/assets/images/boarding_entity.png" />
              </div>
              <div class="w-90-center">
                <ion-label class="onboardingTitle mx-auto">
                  <span class="text-gray-900">
                    {{ `${$t('manage')} ` }}
                  </span>
                  <span class="text-primary-green-600">
                    {{ `${$t('multiple_entities')} ` }}
                  </span>
                  <span class="text-gray-900">
                    {{ $t('in_one_simple') }}
                  </span>
                </ion-label>
              </div>
            </ion-row>
            <ion-row class="bottom">
              <ion-col size="12">
                <ion-button class="onboardingButton" @click="onRedirectHome()">
                  {{ $t('get_started') }}</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-page>
</template>

<script>
import { USER_TYPE } from '@/modules/shared/constants';
import { useConfig, useGetStorageData } from '@/usecases';
import { useAlert } from '@/usecases/global';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Onboarding',
  setup() {
    const slideOpts = {
      initialSlide: 0,
      slidesPerView: 1
    };
    const { selectedUser } = useGetStorageData();
    const { createAlert } = useAlert();
    const { storage, router, t } = useConfig();
    const slides = ref(null);
    const onRedirectHome = async () => {
      try {
        await storage.setSkippedOnBoarding();
        const { user_type_id } = await selectedUser.value;
        if (USER_TYPE.SALE.includes(user_type_id)) {
          router.push('/sale');
        } else {
          router.push('/b2b');
        }
      } catch (error) {
        if (error instanceof Error) {
          createAlert(
            'Sorry for inconvenience',
            'looks like there is an error on your login, please relog in again.',
            async () => {
              await storage.clearStorage();
              router.replace('/');
            },
            t('OK')
          );
        }
      }
    };
    const onNextSlide = (idx) => {
      slides.value.$el.slideTo(idx);
    };
    return {
      slides,
      onRedirectHome,
      onNextSlide,
      router,
      slideOpts,
      storage
    };
  }
});
</script>

<style lang="scss" scoped>
@import 'onboarding';
ion-button {
  text-transform: none;
  --border-radius: 10px;
  font-size: 1.97vh;
  width: 95%;
}

.bottom {
  position: fixed;
  bottom: 70px;
  width: 100%;
}
.img {
  width: 70%;
  margin: 0 auto;
  margin-top: -50px;
}
</style>
