export const customerGuard = async (to, from, next) => {
  const selectedCompany = await from.meta.$storage.getSelectedCompany();

  if (selectedCompany && to.name === 'sale-select-customer') {
    next();
  } else if (to.name !== 'sale-select-customer' && !selectedCompany) {
    next('/sale/select-customer');
  } else {
    next();
  }
};
