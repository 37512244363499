import { SORTING_DATA } from '../../../components/sort/data';

export const DEFAULT_SORT_ALL_KEY = SORTING_DATA.listSortForAll[1].key;
export const DEFAULT_SORT_HISTORY_KEY = SORTING_DATA.listSortForHistory[1].key;

export const MUTATIONS = {
  UPDATE_FILTER: 'UPDATE_FILTER'
};
export const GETTER = {
  GET_FILTER: 'GET_FILTER'
};

export const MODULES_FILTER = 'shared/filter';
export const SALE_PRODUCT_STORE = 'sale/product';
export const B2B_PRODUCT_STORE = 'b2b/product';
