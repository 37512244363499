const getUserLS = () => JSON.parse(localStorage.getItem('user'));

const getGenderName = (gender) => {
  return gender.toLowerCase() == 'f' ? 'Female' : gender.toLowerCase() == 'm' ? 'Male' : '-';
};

const getRegexEmail = () => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
};

const hideEmail = function (email) {
  return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
};

const hideMobile = function (mobile) {
  return mobile.slice(0, 3) + mobile.slice(3).replace(/.(?=....)/g, '*');
};

const displayPrice = (price, fixed = 2) => {
  return parseFloat(Math.round(Number(price) * 100) / 100).toFixed(fixed);
};

const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    tomorrow.getFullYear() +
    '-' +
    ('0' + (tomorrow.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + tomorrow.getDate()).slice(-2)
  );
};

const stringifyArrays = (arrays) => {
  const stringifiedArrays = {};
  for (const key in arrays) {
    if (Object.prototype.hasOwnProperty.call(arrays, key)) {
      stringifiedArrays[key] = JSON.stringify(arrays[key]);
    }
  }
  return stringifiedArrays;
};

export {
  displayPrice,
  getGenderName,
  getRegexEmail,
  getTomorrow,
  getUserLS,
  hideEmail,
  hideMobile,
  stringifyArrays
};
