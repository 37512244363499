<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="md">
        <ion-title>{{ $t('update_app') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="notice">
        <ion-img class="mb-2" :src="updateAppImage" />
        <ion-label>{{ $t('better_experience') }}</ion-label>
        <ion-text>
          {{ $t('wait_update') }}
        </ion-text>
        <div class="row-div mt-3">
          <ion-spinner name="lines-small"></ion-spinner>
          <div class="download-text ml-1">{{ $t('downloading') }}: {{ percentProgress }}%</div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import updateAppImage from '@/assets/images/newdesign/loading-update.svg';
import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  modalController
} from '@ionic/vue';
import { Deploy } from 'cordova-plugin-ionic';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'ProgressBarModal',
  setup() {
    const percentProgress = ref(0);
    return {
      percentProgress,
      updateAppImage
    };
  },

  async mounted() {
    await this.checkUpdateProgress();
    this.close();
  },

  components: {
    IonImg,
    IonContent,
    IonHeader,
    IonTitle,
    IonPage,
    IonToolbar,
    IonSpinner
  },

  methods: {
    async checkUpdateProgress() {
      await Deploy.sync({ updateMethod: 'auto' }, (percentDone) => {
        this.percentProgress = percentDone;
      });
    },

    close() {
      return modalController.dismiss();
    }
  }
});
</script>

<style lang="scss" scoped>
.toolbar-title {
  padding: 10px;
}
ion-title {
  font-size: 17px;
  line-height: 20px;
  color: #181818;
}
ion-content {
  --overflow: hidden;
}
.notice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  & > ion-img {
    width: 160px;
    height: 160px;
  }
  & > ion-label {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    max-width: 300px;
    text-align: center;
    margin: 25px 0px 16px 0px;
  }
  & > ion-text {
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    padding: 0px 20px;
  }
}
.row-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.download-text {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #9e9e9e;
}
</style>
