import { alertController } from '@ionic/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
export const useAlert = () => {
  const alert = ref();
  const { t } = useI18n();
  const createAlert = async (header, message, functionClose, labelConfirm = t('OK')) => {
    alert.value = await alertController.create({
      mode: 'ios',
      header,
      message,
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: labelConfirm,
          role: 'cancel',
          handler: functionClose
        }
      ]
    });
    return alert.value.present();
  };
  const createAlertTwoAction = async (
    header,
    message,
    labelConfirm = t('OK'),
    functionConfirm,
    labelCancel = t('OK'),
    cssClass = 'alert-cancel',
    cancelHandler
  ) => {
    alert.value = await alertController.create({
      mode: 'ios',
      header,
      message,
      cssClass,
      buttons: [
        {
          text: labelConfirm,
          role: 'confirm',
          handler: functionConfirm
        },
        {
          text: labelCancel,
          role: 'cancel',
          handler: cancelHandler
        }
      ]
    });
    return alert.value.present();
  };
  const createAlertConfirmTwoAction = async (
    header,
    message,
    labelConfirm = t('OK'),
    functionConfirm,
    labelCancel = t('OK'),
    cssClass = 'alert-cancel',
    cancelHandler
  ) => {
    alert.value = await alertController.create({
      mode: 'ios',
      header,
      message,
      cssClass,
      buttons: [
        {
          text: labelCancel,
          role: 'cancel',
          handler: cancelHandler
        },
        {
          text: labelConfirm,
          role: 'confirm',
          handler: functionConfirm
        }
      ]
    });
    return alert.value.present();
  };

  return {
    createAlert,
    createAlertTwoAction,
    createAlertConfirmTwoAction,
    alert
  };
};

export const presentAlert = async (payload) => {
  const { mode, cssClass, header, subHeader, message, buttons } = payload;
  const alert = await alertController.create({
    mode,
    cssClass,
    header,
    subHeader,
    message,
    buttons
  });
  await alert.present();
};
