import { toastController } from '@ionic/vue';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

export const openToast = async (params) => {
  const {
    mode = 'ios',
    color = 'primary',
    header,
    message,
    icon,
    position = 'top',
    buttons,
    duration = 2000
  } = params;
  const toast = await toastController.create({
    mode: mode ?? 'ios',
    color: color,
    header: header,
    message: message,
    icon: icon,
    position: position,
    buttons: buttons,
    duration: duration
  });
  return toast.present();
};

export const toastSuccess = async (message, mode = 'ios') => {
  const toast = await toastController.create({
    mode,
    color: 'dark',
    position: 'top',
    duration: 2000,
    message,
    icon: checkmarkOutline
  });
  toast.present();
};

export const toastError = async (message, mode = 'ios') => {
  const toast = await toastController.create({
    mode,
    color: 'danger',
    position: 'top',
    duration: 2000,
    message,
    icon: closeOutline
  });
  toast.present();
};
