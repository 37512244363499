import { apolloClient } from '@/main';
import {
  checkOldPassword,
  deleteMyAccount,
  getConnectPeppolPage,
  getCurrentUser,
  getPeppolSchemesAndCountries,
  registerPeppol,
  requestNewProduct,
  updateCustomerPeppol,
  updatePassword,
  updateProfile
} from '@/modules/b2b/services/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  LOG_OUT: 'LOG_OUT',
  GET_PEPPOL_SCHEME_AND_COUNTRIES: 'GET_PEPPOL_SCHEME_AND_COUNTRIES',
  GET_CONNTECT_PEPPOL_PAGE: 'GET_CONNTECT_PEPPOL_PAGE',
  REGISTER_PEPPOL: 'REGISTER_PEPPOL',
  UPDATE_CUSTOMER_PEPPOL: 'UPDATE_CUSTOMER_PEPPOL',
  REQUEST_NEW_PRODUCT: 'REQUEST_NEW_PRODUCT',
  CHECK_OLD_PASSWORD: 'CHECK_OLD_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',

  // Reset error and status back to default value
  RESET_STATUS_ERROR: 'RESET_STATUS_ERROR'
};

export default {
  async [ACTIONS.GET_CURRENT_USER]({ commit }) {
    commit(MUTATIONS.GET_CURRENT_USER_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getCurrentUser
      });
      commit(MUTATIONS.GET_CURRENT_USER_SUCCESS, data.me);
    } catch (error) {
      commit(MUTATIONS.GET_CURRENT_USER_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_PROFILE]({ commit, dispatch }, userUpdate) {
    commit(MUTATIONS.UPDATE_PROFILE_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: updateProfile,
        variables: userUpdate
      });
      if (data) {
        commit(MUTATIONS.UPDATE_PROFILE_SUCCESS, data);
        dispatch(ACTIONS.GET_CURRENT_USER);
      } else {
        commit(MUTATIONS.UPDATE_PROFILE_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_PROFILE_FAILURE, error);
    }
  },

  // reset status error back to default value
  async [ACTIONS.LOG_OUT]({ commit }) {
    commit(MUTATIONS.RESET_STATE, {});
  },

  async [ACTIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES]({ commit }) {
    commit(MUTATIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getPeppolSchemesAndCountries
      });
      if (data) {
        commit(MUTATIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES_SUCCESS, data.getPeppolSchemesAndCountries);
      } else {
        commit(MUTATIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES_FAILURE, error);
    }
  },

  async [ACTIONS.GET_CONNTECT_PEPPOL_PAGE]({ commit }, params) {
    commit(MUTATIONS.GET_CONNTECT_PEPPOL_PAGE_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getConnectPeppolPage,
        variables: params
      });
      if (data) {
        commit(MUTATIONS.GET_CONNTECT_PEPPOL_PAGE_SUCCESS, data.getConnectPeppolPage);
      } else {
        commit(MUTATIONS.GET_CONNTECT_PEPPOL_PAGE_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_CONNTECT_PEPPOL_PAGE_FAILURE, error);
    }
  },

  async [ACTIONS.REGISTER_PEPPOL]({ commit }, params) {
    commit(MUTATIONS.REGISTER_PEPPOL_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: registerPeppol,
        variables: params
      });
      if (data) {
        commit(MUTATIONS.REGISTER_PEPPOL_SUCCESS, data.registerPeppol);
      } else {
        commit(MUTATIONS.REGISTER_PEPPOL_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.REGISTER_PEPPOL_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_CUSTOMER_PEPPOL]({ commit }, params) {
    commit(MUTATIONS.UPDATE_CUSTOMER_PEPPOL_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: updateCustomerPeppol,
        variables: params
      });
      if (data) {
        commit(MUTATIONS.UPDATE_CUSTOMER_PEPPOL_SUCCESS, data.updateCustomerPeppol);
      } else {
        commit(MUTATIONS.UPDATE_CUSTOMER_PEPPOL_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_CUSTOMER_PEPPOL_FAILURE, error);
    }
  },

  async [ACTIONS.REQUEST_NEW_PRODUCT]({ commit }, params) {
    commit(MUTATIONS.REQUEST_NEW_PRODUCT_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: requestNewProduct,
        variables: params
      });
      if (data) {
        commit(MUTATIONS.REQUEST_NEW_PRODUCT_SUCCESS, data.requestNewProduct);
      } else {
        commit(MUTATIONS.REQUEST_NEW_PRODUCT_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.REQUEST_NEW_PRODUCT_FAILURE, error);
    }
  },

  async [ACTIONS.CHECK_OLD_PASSWORD]({ commit }, params) {
    commit(MUTATIONS.CHECK_OLD_PASSWORD_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: checkOldPassword,
        variables: params
      });
      if (data) {
        commit(MUTATIONS.CHECK_OLD_PASSWORD_SUCCESS, data.checkOldPassword);
      } else {
        commit(MUTATIONS.CHECK_OLD_PASSWORD_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CHECK_OLD_PASSWORD_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_PASSWORD]({ commit }, params) {
    commit(MUTATIONS.UPDATE_PASSWORD_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: updatePassword,
        variables: params
      });
      if (data) {
        commit(MUTATIONS.UPDATE_PASSWORD_SUCCESS, data.updatePassword);
      } else {
        commit(MUTATIONS.UPDATE_PASSWORD_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_PASSWORD_FAILURE, error);
    }
  },

  async [ACTIONS.DELETE_ACCOUNT]({ commit }) {
    commit(MUTATIONS.DELETE_ACCOUNT_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: deleteMyAccount
      });
      commit(MUTATIONS.DELETE_ACCOUNT_SUCCESS, data.deleteMyAccount);
    } catch (error) {
      commit(MUTATIONS.DELETE_ACCOUNT_FAILURE, error);
    }
  },

  // reset status error back to default value
  async [ACTIONS.RESET_STATUS_ERROR]({ commit }) {
    commit(MUTATIONS.RESET_STATUS_ERROR);
  }
};
