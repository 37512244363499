import { DEFAULT_STATE } from '@/modules/sale/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state = {
  ...DEFAULT_STATE,
  notificationsLists: {
    data: []
  },
  notificationsBadges: {},
  totalCount: 0,
  params: {
    isIncludeTotalCount: true,
    limit: 20,
    offset: 0
  },
  item: null,
  numberBadge: null,
  quotationsPending: null,
  quotationsApproved: null,
  quotationsRejected: null,
  saleApproveQuotation: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
