import dayjs from 'dayjs';

const monthMap = () => {
  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({
      text: dayjs()
        .month(i - 1)
        .format('MMM'),
      value: i < 10 ? `0${i}` : i.toString()
    });
  }
  return months;
};

const dayMap = () => {
  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push({
      text: i < 10 ? `0${i}` : i.toString(),
      value: i < 10 ? `0${i}` : i.toString()
    });
  }
  return days;
};

const yearMap = () => {
  const years = [];
  for (let i = 2022; i <= 2050; i++) {
    years.push({
      text: i.toString(),
      value: i.toString()
    });
  }
  return years;
};

export const getMonth = monthMap();
export const getDay = dayMap();
export const getYear = yearMap();
