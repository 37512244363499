import { DEFAULT_GETTERS } from '@/modules/sale/constants';

export default {
  ...DEFAULT_GETTERS,
  notificationsLists: (state) => state.notificationsLists || [],
  notificationsBadges: (state) => state.notificationsBadges || {},
  totalCount: (state) => state.totalCount,
  item: (state) => state.item?.list,
  numberBadge: (state) => state.numberBadge?.data,
  orderId: (state) => state.orderId,
  saleApproveQuotation: (state) => state.saleApproveQuotation,
  quotationsPending: (state) => state.quotationsPending?.data || [],
  quotationsApproved: (state) => state.quotationsApproved?.data || [],
  quotationsRejected: (state) => state.quotationsRejected?.data || []
};
