import { apolloClient } from '@/main';
const handleCallApi = async (params) => {
  const { isQuery, services, variables } = params;
  try {
    const { data, errors } = isQuery
      ? await apolloClient.query({
          query: services,
          variables: variables
        })
      : await apolloClient.mutate({
          mutation: services,
          variables: variables
        });

    if (errors && errors[0].message) throw new Error(errors[0].message);

    return data;
  } catch (e) {
    return handleError(e);
  }
};
const handleError = (e) => {
  throw e;
};
export default handleCallApi;
