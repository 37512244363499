import { DEFAULT_SORT_HISTORY_KEY } from '../../constants/sort';
import { getters } from './getter';
import { mutations } from './mutation';

const state = {
  sortKey: DEFAULT_SORT_HISTORY_KEY
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
