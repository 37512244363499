import { apolloClient } from '@/main';
import { getBadgeNumbers } from '@/services/shared/graphql';
import filter from './modules/filter';

export const state = {
  isLoggedIn: false,
  user: null,
  isLoading: false,
  notificationBadgeNumber: 0,
  cartBadgeNumber: 0
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  getResetPassUser(state) {
    return state.setResetPassUser;
  },
  getNotificationBadgeNumber(state) {
    return state.notificationBadgeNumber;
  },
  getCartBadgeNumber(state) {
    return state.cartBadgeNumber;
  }
};

const actions = {
  setUser({ commit }, payload) {
    commit('setUser', payload.user);
  },
  clearStore({ commit }) {
    commit('clearStore');
  },
  setResetPassUser({ commit }, payload) {
    commit('setResetPassUser', payload.user);
  },
  clearResetPassUser({ commit }) {
    commit('clearResetPassUser');
  },
  async setBadgeNumber({ commit }, customerId) {
    try {
      const { data } = await apolloClient.query({
        query: getBadgeNumbers,
        variables: {
          customerId
        }
      });

      commit('setBadgeNumber', data.getBadgeNumbers);
    } catch (error) {
      console.log(`Can get badge notiifcation number ${error.message}`);
    }
  }
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
    localStorage.setItem('user', JSON.stringify(payload));
    localStorage.setItem('token_jwt', payload.tokenJWT);
  },
  clearStore(state) {
    state.user = null;
    localStorage.setItem('user', null);
    localStorage.setItem('token_jwt', null);
  },
  setResetPassUser(state, payload) {
    state.resetPassUser = payload;
    localStorage.setItem('resetPassUser', JSON.stringify(payload));
  },
  clearResetPassUser(state) {
    state.resetPassUser = null;
    localStorage.setItem('resetPassUser', JSON.stringify(null));
  },
  setBadgeNumber(state, payload) {
    state.notificationBadgeNumber = payload.notifications;
    state.cartBadgeNumber = payload.carts;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    filter
  }
};
