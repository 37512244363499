import { MUTATIONS } from './mutations';

export const ACTIONS = {
  RESET_STATE: 'RESET_STATE'
};

export default {
  async [ACTIONS.RESET_STATE]({ commit }) {
    commit(MUTATIONS.RESET_STATE);
  }
};
