import all from '@/assets/images/sale/home/all.svg';
import bookings from '@/assets/images/sale/home/bookings.svg';
import history from '@/assets/images/sale/home/history.svg';
// import newest from '@/assets/images/sale/home/newest.svg';
// import quoted from '@/assets/images/sale/home/quoted.svg';
import { heart } from 'ionicons/icons';

export const IMAGE_URL = process.env.VUE_APP_IMAGES_STORAGE;

export const NOTIFICATION_TYPE = {
  NEW_QUOTATION: 'new_quotation'
};

export const USER_TYPE = {
  BENEFICIARY: 'beneficiary'
};

export const JOIN_STATUS = {
  NOT_JOIN: 'not_joined',
  JOINED: 'joined',
  WAITING: 'waiting_for_approval'
};

export const QUOTATION_STATUS = {
  REQUESTED: 'requested',
  RECEIVED: 'received'
};

export const defaultSize = 10;

export const TYPE_SEARCH = {
  INPUT: 'INPUT',
  SORT: 'SORT',
  CATEGORY: 'CATEGORY'
};

export const listCates = [
  {
    id: 'history',
    useSrc: true,
    useIcon: true,
    image: history,
    name: 'History'
  },
  {
    id: 'bookings',
    useSrc: true,
    useIcon: true,
    image: bookings,
    name: 'Bookings'
  },
  {
    id: 'favourite',
    useSrc: false,
    useIcon: true,
    icon: heart,
    name: 'Favourite'
  },
  {
    id: 'all',
    useSrc: true,
    useIcon: true,
    image: all,
    name: 'All'
  }
  // {
  //   id: 'new',
  //   useSrc: true,
  //   useIcon: true,
  //   image: newest,
  //   name: 'Newest'
  // },
  // {
  //   id: 'quoted',
  //   useSrc: true,
  //   useIcon: true,
  //   image: quoted,
  //   name: 'Quoted'
  // }
];

export const listImages = [
  '/assets/images/sale/home/sale-slide-1.jpeg',
  '/assets/images/sale/home/sale-slide-1.jpeg',
  '/assets/images/sale/home/sale-slide-1.jpeg',
  '/assets/images/sale/home/sale-slide-2.jpeg',
  '/assets/images/sale/home/sale-slide-3.jpeg',
  '/assets/images/sale/home/sale-slide-4.jpeg',
  '/assets/images/sale/home/sale-slide-5.jpeg',
  '/assets/images/sale/home/sale-slide-6.jpeg'
];
