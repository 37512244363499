import gql from 'graphql-tag';

export const getListCustomerCard = gql`
  query getListStripeCard($customerId: Int!) {
    listCardByCustomerId(customerId: $customerId) {
      __typename
      ... on ListCardByCustomerId {
        data {
          id
          brand
          exp_month
          exp_year
          country
          funding
          last4
          fingerprint
        }
        has_more
        url
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const getDefaultCard = gql`
  query getDefaultCardByCustomer($customerId: Int!) {
    getDefaultCardByCustomer(customerId: $customerId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const removeStripeCard = gql`
  mutation removeCardByCustomer($customerId: Int!, $cardId: String!) {
    removeCardByCustomer(customerId: $customerId, cardId: $cardId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
export const addStripeCard = gql`
  mutation addCardByCustomer(
    $customerId: Int!
    $cardNumber: String!
    $expYear: Int!
    $expMonth: Int!
    $cvc: String!
  ) {
    addCardByCustomer(
      customerId: $customerId
      cardNumber: $cardNumber
      expYear: $expYear
      expMonth: $expMonth
      cvc: $cvc
    ) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const setDefaultCard = gql`
  mutation setDefaultCardByCustomer($customerId: Int!, $cardId: String!) {
    setDefaultCardByCustomer(customerId: $customerId, cardId: $cardId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
export const addStripeCardAddCustomer = gql`
  mutation addStripeCardB2B(
    $stripeCustomerId: String
    $cardNumber: String!
    $expYear: Int!
    $expMonth: Int!
    $cvc: String!
  ) {
    addStripeCardB2B(
      stripeCustomerId: $stripeCustomerId
      cardNumber: $cardNumber
      expYear: $expYear
      expMonth: $expMonth
      cvc: $cvc
    ) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
export const listCardByStripeCustomerId = gql`
  query listCardByStripeCustomerId($stripeCustomerId: String!) {
    listCardByStripeCustomerId(stripeCustomerId: $stripeCustomerId) {
      __typename
      ... on ListCardByCustomerId {
        data {
          id
          brand
          exp_month
          exp_year
          country
          funding
          last4
          fingerprint
        }
        has_more
        url
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
export const setDefaultCardByStripeCustomerId = gql`
  mutation setDefaultCardByStripeCustomerId($stripeCustomerId: String!, $cardId: String!) {
    setDefaultCardByStripeCustomerId(stripeCustomerId: $stripeCustomerId, cardId: $cardId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
export const removeCardByStripeCustomerId = gql`
  mutation removeCardByStripeCustomerId($stripeCustomerId: String!, $cardId: String!) {
    removeCardByStripeCustomerId(stripeCustomerId: $stripeCustomerId, cardId: $cardId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
export const getListNetsCard = gql`
  query getListNetsCard($deviceId: String!) {
    getListNetsCard(deviceId: $deviceId) {
      id
      user_id
      merchant_token
      mtoken_expiry_date
      mtoken_status
      last_4_digits_fpan
      issuer_short_name
    }
  }
`;
export const getNetsMerchantToken = gql`
  query getNetsMerchantToken($t0102: String!, $deviceId: String!) {
    getNetsMerchantToken(t0102: $t0102, deviceId: $deviceId) {
      user_id
      merchant_token
      mtoken_expiry_date
      mtoken_status
      last_4_digits_fpan
      issuer_short_name
    }
  }
`;
export const removeNetsCard = gql`
  mutation removeNetsCard($deviceId: String!) {
    removeNetsCard(deviceId: $deviceId)
  }
`;

export const getInvoicesByCustomer = gql`
  query getInvoicesByCustomer(
    $tenantId: Int!
    $customerId: Int!
    $limit: Int
    $offset: Int
    $isIncludeTotalCount: Boolean
  ) {
    getInvoicesByCustomer(
      tenantId: $tenantId
      customerId: $customerId
      limit: $limit
      offset: $offset
      isIncludeTotalCount: $isIncludeTotalCount
    ) {
      invoices {
        id
        invoice_number
        actual_delivery_date
        description
        currency_code
        currency_symbol
        amount
        cod_paid_amount
        payment_type
        due_date
        status_id
        resync_status
        status_id
        created_at
        company_name
        is_pending_approval
        credit_term_display_name
        outstanding_amount
        payment_type_id
        is_overdue
        is_due_today
      }
      totalCount
    }
  }
`;

export const createB2bAutomaticBatchPayments = gql`
  mutation createB2bAutomaticBatchPayments(
    $paymentTypeId: Int!
    $invoiceIDs: [Int]!
    $totalPaidAmount: Float!
    $balanceAllocationAmount: Float!
    $stripeCardId: String
    $stripeCustomerId: String
    $isMobile: Boolean!
  ) {
    createB2bAutomaticBatchPayments(
      paymentTypeId: $paymentTypeId
      invoiceIDs: $invoiceIDs
      totalPaidAmount: $totalPaidAmount
      balanceAllocationAmount: $balanceAllocationAmount
      stripeCardId: $stripeCardId
      stripeCustomerId: $stripeCustomerId
      isMobile: $isMobile
    ) {
      id
      redirectUrl
      expires_at
      payment_status
      status
      cancel_url
      totalData
      totalCustomerBalanceUsed
      transactionDate
      valueDate
      notes
      details {
        batchPayment {
          id
          amount
          status
          payment_date
          payment_value_date
          created_by
          created_at
          updated_by
          updated_at
          batchPaymentInvoices {
            id
            invoice_id
            batch_payment_id
            paid_amount
            created_at
            updated_at
          }
          batchPaymentBalanceAllocations {
            id
            invoice_id
            batch_payment_id
            balance_allocation_amount
          }
        }
        customerCreditBatchPayment {
          id
          amount
          payment_date
          payment_value_date
          status
          created_by
          created_at
          updated_by
          updated_at
          batchPaymentInvoices {
            id
            invoice_id
            batch_payment_id
            paid_amount
            created_at
            updated_at
          }
          batchPaymentBalanceAllocations {
            id
            invoice_id
            batch_payment_id
            balance_allocation_amount
          }
        }
      }
    }
  }
`;

export const createPaymentB2b = gql`
  mutation createPaymentB2b(
    $transactionDate: String!
    $valueDate: String!
    $paymentTypeId: Int!
    $paymentProof: [String]!
    $referenceCode: String!
    $invoiceIDs: [Int]!
    $notes: String
    $totalPaidAmount: Float!
    $balanceAllocationAmount: Float!
  ) {
    createPaymentB2b(
      transactionDate: $transactionDate
      valueDate: $valueDate
      paymentTypeId: $paymentTypeId
      paymentProof: $paymentProof
      referenceCode: $referenceCode
      invoiceIDs: $invoiceIDs
      notes: $notes
      totalPaidAmount: $totalPaidAmount
      balanceAllocationAmount: $balanceAllocationAmount
    ) {
      totalData
      transactionDate
      totalPaidAmount
      totalCustomerBalanceUsed
      valueDate
      notes
      details {
        id
        batchPaymentInvoices {
          id
        }
      }
    }
  }
`;

export const createPaymentChequeCash = gql`
  mutation createPaymentChequeCash(
    $depositDate: String!
    $paymentTypeId: Int!
    $paymentProof: [String]!
    $referenceCode: String!
    $invoiceIDs: [Int]!
    $notes: String
    $totalPaidAmount: Float!
    $balanceAllocationAmount: Float!
  ) {
    createPaymentChequeCash(
      depositDate: $depositDate
      paymentTypeId: $paymentTypeId
      paymentProof: $paymentProof
      referenceCode: $referenceCode
      invoiceIDs: $invoiceIDs
      notes: $notes
      totalPaidAmount: $totalPaidAmount
      balanceAllocationAmount: $balanceAllocationAmount
    ) {
      totalData
      transactionDate
      totalPaidAmount
      totalCustomerBalanceUsed
      notes
      details {
        id
        batchPaymentInvoices {
          id
        }
      }
    }
  }
`;
export const getXeroCustomer = gql`
  query getXeroCustomer($customerId: Int!, $tenantId: Int!) {
    getXeroCustomer(customerId: $customerId, tenantId: $tenantId) {
      id
      customer_id
      outstanding_amount
      overdue_amount
      overpayment_amount
      credit_notes_amount
      active
      last_xero_sync
      created_at
      updated_at
    }
  }
`;

export const getTotalUnpaidInvoices = gql`
  query getTotalUnpaidInvoices($tenantId: Int!, $customerId: Int!) {
    getTotalUnpaidInvoices(tenantId: $tenantId, customerId: $customerId) {
      total_unpaid_invoices
      total_overdue_invoices
    }
  }
`;

export const getIsFinanceModuleEnabled = gql`
  query getIsFinanceModuleEnabled($tenantId: Int!, $customerId: Int!) {
    getIsFinanceModuleEnabled(tenantId: $tenantId, customerId: $customerId)
  }
`;

export const getIsEnableSoftBlockOrder = gql`
  query getIsEnableSoftBlockOrder($tenantId: Int!, $customerId: Int!) {
    getIsEnableSoftBlockOrder(tenantId: $tenantId, customerId: $customerId)
  }
`;

export const getBatchPaymentDetail = gql`
  query getBatchPaymentDetail($batchPaymentId: Int!, $tenantId: Int!) {
    getBatchPaymentDetail(batchPaymentId: $batchPaymentId, tenantId: $tenantId) {
      id
      status
      amount
      reference_number
    }
  }
`;
