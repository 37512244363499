import gql from 'graphql-tag';

export const getStatusCustomerJoinTenant = gql`
  query getStatusCustomerJoinTenant($customer_id: Int!, $tenant_id: Int!) {
    getStatusCustomerJoinTenant(customer_id: $customer_id, tenant_id: $tenant_id) {
      tenantId
      status
    }
  }
`;

export const saleGetUserCustomerList = gql`
  query saleGetUserCustomerList(
    $tenantId: Int
    $searchQueries: String
    $isIncludeTotalCount: Boolean
    $limit: Int
    $offset: Int
  ) {
    saleGetUserCustomerList(
      tenantId: $tenantId
      searchQueries: $searchQueries
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      items {
        id
        name
        address
        account_number
        tenant_id
        xero_customer_id
        black_list
        user_id
        halal_products
        has_credit_block
        persons {
          phone
        }
      }
    }
  }
`;

export const saleGetUserCustomerBranch = gql`
  query saleGetUserCustomerBranch($id: Int, $tagged: Boolean, $isAdmin: Boolean, $tenant_id: Int) {
    saleGetUserCustomerBranch(id: $id, tagged: $tagged, isAdmin: $isAdmin, tenant_id: $tenant_id) {
      data {
        id
        name
        account_number
        address
        customer_type_id
        hub
        halal_products
        xero_id
        minimum_order
        black_list
        has_credit_block
        delivery_charge
        payment_type
        storecove_id
        peppol_id
        user_customer_id
        user_id
        user_type_id
        tagged
        stripe_customer_id
        buyer_type
        stripe_card_id
        branches {
          id
          address
          branch
          account_number
          black_list
          has_credit_block
          address
          user_type_id
          buyer_type
          stripe_customer_id
          stripe_card_id
          xero_id
          payment_type
          minimum_order
          delivery_charge
          storecove_id
          peppol_id
          persons {
            phone
          }
        }
        persons {
          phone
        }
      }
    }
  }
`;

export const getViewsCustomerTenants = gql`
  query getViewsCustomerTenants($id: Int!) {
    getViewsCustomerTenants(id: $id) {
      id
      name
      tenant_name
      customer_type_id
      hub
      tenant_id
      tenant_class_id
      xero_id
      payment_type
      active
      xero_tenant_id
    }
  }
`;

export const getUnconnectedTenant = gql`
  query GET_UNCONNECTED_TENANT(
    $customerId: Int!
    $keyword: String
    $isProduction: Boolean
    $userCountryId: Int!
  ) {
    getUnconnectedTenant(
      customerId: $customerId
      keyword: $keyword
      isProduction: $isProduction
      userCountryId: $userCountryId
    ) {
      id
      name
      unique_id
      registration_number
      request_join_status
    }
  }
`;

export const addCustomerTenantJoinRequest = gql`
  mutation addCustomerTenantJoinRequest(
    $customer_id: Int!
    $tenant_id: Int!
    $created_by: Int!
    $updated_by: Int
    $approved: Boolean
  ) {
    addCustomerTenantJoinRequest(
      customer_id: $customer_id
      tenant_id: $tenant_id
      created_by: $created_by
      updated_by: $updated_by
      approved: $approved
    )
  }
`;

export const saleGetCustomerView = gql`
  query saleGetCustomerView($id: Int!, $tenantIds: [Int]) {
    saleGetCustomerView(id: $id, tenantIds: $tenantIds) {
      id
      name
      tenant_id
      payment_type
      default_credit_card_term
      delivery_instruction
      customer_credit_term {
        customerCreditTermId
        tenantCreditTermId
        displayName
        termPeriod
        gracePeriod
      }
      default_address {
        id
        customer_id
        floor_number
        street_number
        road
        building
        unit
        stall
        city
        state
        postal_code
        latlng
        country_id
        address_type_id
        active
        is_default
      }
      keyPerson {
        first_name
        last_name
        email
        phone
      }
      black_list
    }
  }
`;

export const saleGetCustomerDetail = gql`
  query saleGetCustomerDetail($id: Int!, $tenant_id: Int) {
    saleGetCustomerDetail(id: $id, tenant_id: $tenant_id) {
      id
      isBranch
      name
      account_number
      customer_type_id
      customerType {
        name
      }
      group_id
      active
      profile
      profile_id
      halal_products
      cod
      minimum_order
      hub
      delivery_charge
      payment_type
      black_list
      xero_id
      tenant_id
      delivery_instruction
      customer_credit_term {
        displayName
      }
      group {
        name
      }
      addresses {
        active
        addressType {
          name
        }
        address_type_id
        building
        city
        country {
          name
        }
        country_id
        floor_number
        id
        is_default
        postal_code
        road
        stall
        state
        street_number
        unit
        latlng
      }
      working_hours {
        id
        day_id
        day_name
        open_hour
        open_minute
        close_hour
        close_minute
        active
      }
      persons {
        id
        first_name
        last_name
        email
        phone
        position
        active
        fax
        user_id
        language
        is_soa_receiver
      }
      customerImage {
        id
        image
      }
      branches {
        id
        branch
        account_number
        user_type_id
        buyer_type
        customer_credit_term {
          displayName
        }
        stripe_customer_id
        stripe_card_id
        xero_id
        payment_type
        minimum_order
        delivery_charge
        storecove_id
        peppol_id
        delivery_instruction
        cod
        customerImage {
          id
          image
        }
        addresses {
          id
          floor_number
          street_number
          road
          building
          unit
          stall
          city
          state
          postal_code
          country {
            name
          }
          country_id
          addressType {
            name
          }
          address_type_id
          latlng
          is_default
          active
        }
        halal_products
        profile
        profile_id
        working_hours {
          id
          day_id
          day_name
          open_hour
          open_minute
          close_hour
          close_minute
          active
        }
        persons {
          id
          first_name
          last_name
          email
          phone
          position
          active
          fax
          user_id
          language
        }
      }
    }
  }
`;

export const getCustomerImage = gql`
  query saleGetCustomerImages($customerId: Int!, $active: Boolean) {
    saleGetCustomerImages(customerId: $customerId, active: $active) {
      id
      image
      customer_id
    }
  }
`;

export const getSubBuyers = gql`
  query getSubBuyers($supplierId: Int!) {
    getSubBuyers(supplierId: $supplierId) {
      id
      first_name
      last_name
      mobile
      email
      active
    }
  }
`;

export const getGroupsAndCountriesAndDays = gql`
  query getGroupsAndCountriesAndDays {
    getGroupsAndCountriesAndDays {
      countries {
        id
        name
        description
      }
      groups {
        id
        name
        description
      }
      days {
        id
        description
      }
    }
  }
`;

export const updateCustomer = gql`
  mutation saleUpdateCustomer($item: UpdateCustomerInput!) {
    saleUpdateCustomer(item: $item)
  }
`;

export const getCustomerTenants = gql`
  query getCustomerTenants($customer_id: Int!, $tenant_id: Int!) {
    getCustomerTenants(customer_id: $customer_id, tenant_id: $tenant_id) {
      id
      customer_id
      tenant_id
      active
      xero_customer_id
      alias_name
      cod
      payment_type
      delivery_instruction
      delivery_charge
      minimum_order
      commission_rate
      auto_create_spree
    }
  }
`;

export const addPerson = gql`
  mutation saleAddPerson($isSelected: Boolean, $item: PersonInput!) {
    saleAddPerson(isSelected: $isSelected, item: $item) {
      id
      customer_id
      first_name
      last_name
      email
      phone
      position
      is_default
      active
      is_soa_receiver
    }
  }
`;

export const addAddress = gql`
  mutation saleAddAddress(
    $supplierId: Int!
    $floorNumber: String
    $streetNumber: String
    $road: String
    $building: String
    $unit: String
    $stall: String
    $city: String
    $state: String
    $postalCode: String!
    $latlng: LatLngInput
    $countryId: Int!
    $addressTypeId: Int!
    $isDefault: Boolean
  ) {
    saleAddAddress(
      supplierId: $supplierId
      floorNumber: $floorNumber
      streetNumber: $streetNumber
      road: $road
      building: $building
      unit: $unit
      stall: $stall
      city: $city
      state: $state
      postalCode: $postalCode
      latlng: $latlng
      countryId: $countryId
      addressTypeId: $addressTypeId
      isDefault: $isDefault
    )
  }
`;

export const updateAddress = gql`
  mutation saleUpdateAddress($payload: AddressCustomType, $addressId: Int!) {
    saleUpdateAddress(payload: $payload, addressId: $addressId) {
      id
      customer_id
      floor_number
      street_number
      road
      building
      unit
      stall
      city
      state
      postal_code
      latlng
      country_id
      address_type_id
      active
      is_default
    }
  }
`;

export const hoursAdd = gql`
  mutation saleAddHour($deliveryIntruction: String, $tenantId: Int, $payload: [HourAddInput]) {
    saleAddHour(deliveryIntruction: $deliveryIntruction, tenantId: $tenantId, payload: $payload)
  }
`;

export const addSubBuyer = gql`
  mutation addSubBuyer(
    $country_id: Int!
    $customer_id: Int!
    $email: String!
    $mobile: String!
    $first_name: String!
    $last_name: String!
    $user_type_id: Int!
    $buyer_type: Int!
    $password: String!
  ) {
    addSubBuyer(
      country_id: $country_id
      customer_id: $customer_id
      email: $email
      mobile: $mobile
      first_name: $first_name
      last_name: $last_name
      user_type_id: $user_type_id
      buyer_type: $buyer_type
      password: $password
    )
  }
`;

export const updateSubBuyer = gql`
  mutation updateSubBuyer($id: Int!, $supplierId: Int!, $active: Boolean!) {
    updateSubBuyer(id: $id, supplierId: $supplierId, active: $active)
  }
`;

export const addCustomer = gql`
  mutation saleAddCustomer($item: AddCustomerInput!) {
    saleAddCustomer(item: $item) {
      id
    }
  }
`;

export const addBranch = gql`
  mutation saleAddBranch($customerId: Int!, $customerBranchId: Int!) {
    saleAddBranch(customerId: $customerId, customerBranchId: $customerBranchId)
  }
`;

export const addCustomerImage = gql`
  mutation saleAddCustomerImage($payload: [CustomerImageInput]) {
    saleAddCustomerImage(payload: $payload)
  }
`;

export const updateCustomerImage = gql`
  mutation saleUpdateCustomerImage($id: [Int]) {
    saleUpdateCustomerImage(id: $id)
  }
`;

export const deletePersonB2b = gql`
  mutation deletePersonB2b($customerId: Int!, $personId: Int!) {
    deletePersonB2b(customerId: $customerId, personId: $personId)
  }
`;
export const saleCheckDuplicateCustomer = gql`
  query saleCheckDuplicateCustomer($tenantId: Int!, $postalCode: String) {
    saleCheckDuplicateCustomer(tenantId: $tenantId, postalCode: $postalCode) {
      id
      name
      is_branch
      addresses {
        id
        floor_number
        street_number
        road
        building
        unit
        stall
        city
        state
        postal_code
      }
      userCustomers {
        user {
          first_name
          last_name
        }
      }
      CustomerTenant {
        id
        active
        tenant_name
      }
    }
  }
`;
export const saleAddCustomer = gql`
  mutation saleAddCustomer($item: AddCustomerInput!) {
    saleAddCustomer(item: $item) {
      id
    }
  }
`;
export const saleCheckDuplicatePerson = gql`
  query saleCheckDuplicatePerson($tenantId: Int!, $data: PersonInput!) {
    saleCheckDuplicatePerson(tenantId: $tenantId, data: $data) {
      id
      name
      is_branch
      persons {
        first_name
        last_name
        phone
      }
      userCustomers {
        user {
          first_name
          last_name
        }
      }
      CustomerTenant {
        id
        active
        tenant_name
      }
    }
  }
`;

export const getCustomerDetails = gql`
  query getCustomerDetails($id: Int) {
    getCustomerDetails(id: $id) {
      totalCount
      data {
        id
        name
        account_number
        customer_type_id
        hub
        halal_products
        xero_id
        minimum_order
        delivery_charge
        payment_type
        storecove_id
        peppol_id
        user_customer_id
        user_id
        user_type_id
        tagged
        stripe_customer_id
        stripe_card_id
        persons {
          phone
        }
      }
    }
  }
`;

export const getDefaultCreditTerm = gql`
  query getDefaultCreditTerm($mainCompayId: Int, $tenantId: Int) {
    getDefaultCreditTerm(mainCompayId: $mainCompayId, tenantId: $tenantId)
  }
`;
