import { DEFAULT_STATE } from '@/modules/b2b/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state = {
  ...DEFAULT_STATE,
  listCustomerCard: {
    data: []
  },
  listNetsCard: [],
  listNetsMerchant: [],
  stripeId: null,
  listCardByStripeId: [],
  listInvoices: {
    invoices: []
  },
  totalCountInvoices: 0,
  paramsListInvoices: {
    tenantId: 0,
    customerId: 0,
    isIncludeTotalCount: true,
    limit: 20,
    offset: 0,
    isLoadMore: false
  },
  paramsCreatePayment: {
    invoiceIDs: [],
    transactionDate: null,
    valueDate: null,
    paymentTypeId: 4,
    paymentProof: null,
    referenceCode: null,
    notes: null,
    totalPaidAmount: null,
    balanceAllocationAmount: null
  },
  paymentSummary: {
    totalData: 0,
    totalPaidAmount: 0,
    totalCustomerBalanceUsed: 0,
    transactionDate: null,
    valueDate: null,
    notes: null,
    balanceAllocationNotes: null,
    details: []
  },
  xeroCustomer: null,
  totalUnpaidInvoices: 0,
  totalOverdueInvoices: 0,
  selectedPaymentInvoices: null,
  isFinanceModuleEnabled: false,
  paymentHistory: null,
  cancelManualPayment: null,
  isLoadingCancellingPayment: false,
  cancelPrepayment: null,
  cancelStripePaymentIntent: null,
  isEnterStripeUrl: false
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
