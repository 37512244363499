export const IMAGE_PATH = {
  USER: 'profile-pictures',
  CUSTOMER: 'customer-pictures',
  PRODUCT: 'products',
  CATEGORY: 'category-images/new',
  CATEGORY_ICON: 'category-images/category-icons'
};

export const DEFAULT_PRODUCT_IMAGE = '/assets/images/new-default-product-img.svg';

export const DEFAULT_AVATAR = '/assets/images/no-avatar.png';

export const DEFAULT_USER_AVARTAR = '/assets/images/user.svg';
