import dayjs from 'dayjs';

export const useDateFormatter = () => {
  const locale = dayjs.locale();
  const zhLocale = locale === `zh-custom`;

  const isValidDate = (date) => {
    return dayjs(date).isValid();
  };

  const formatDate = (date) => {
    return zhLocale ? dayjs(date).format('LL') : dayjs(date).format('DD/MM/YYYY');
  };

  const formatDateTime = (date) => {
    return zhLocale ? dayjs(date).format('LLL') : dayjs(date).format('DD MMM YYYY hh:mm A');
  };

  const formatTime = (date) => {
    return zhLocale ? dayjs(date).format('LT') : dayjs(date).format('hh:mm A');
  };

  const formatDateMonthYear = (date) => {
    return zhLocale ? dayjs(date).format('LL') : dayjs(date).format('DD MMM YYYY');
  };

  const formatExpirationTime = (date) => {
    return zhLocale ? dayjs(date).format('hh:mm A, LL') : dayjs(date).format('hh:mm A, DD MMM YYYY');
  };

  const formatDayDateTimeWithCompleteMonthsName = (date) => {
    return zhLocale ? dayjs(date).format('llll') : dayjs(date).format('ddd, DD MMMM YYYY hh:mm A');
  };

  const formatDayDate = (date) => {
    return zhLocale ? dayjs(date).format('ddd, LL') : dayjs(date).format('ddd, DD MMM YYYY');
  };

  const formatDayDateTime = (date) => {
    return zhLocale ? dayjs(date).format('ddd, LL hh:mm A') : dayjs(date).format('ddd, DD MMM YYYY, hh:mm A');
  };

  const formatDateMonth = (date) => {
    return zhLocale ? dayjs(date).format('DD 日/MM 月') : dayjs(date).format('DD/MM');
  };

  const formatDay = (date) => {
    return dayjs(date).format('dd');
  };

  const formatDatePayload = (date) => {
    // as a payload sent to be
    return dayjs(date).format('YYYY-MM-DD');
  };

  const formatISO8601 = (date) => {
    return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');
  };

  return {
    locale,
    isValidDate,
    formatDayDateTime,
    formatDatePayload,
    formatDate,
    formatDayDate,
    formatDateTime,
    formatTime,
    formatDateMonthYear,
    formatExpirationTime,
    formatDateMonth,
    formatDay,
    formatDayDateTimeWithCompleteMonthsName,
    formatISO8601
  };
};
