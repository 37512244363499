import account from './account';
import address from './address';
import cart from './cart';
import category from './category';
import customer from './customer';
import notification from './notification';
import order from './order';
import purchase from './orders/purchase';
import payment from './payment';
import product from './product';
import productSearch from './product-search';
import version from './version';

export default {
  namespaced: true,
  modules: {
    product,
    category,
    account,
    payment,
    customer,
    cart,
    purchase,
    notification,
    address,
    order,
    version,
    productSearch
  }
};
