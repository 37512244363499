import { apolloClient } from '@/main';
import {
  getUpdateBadgeNotifications,
  getViewsQuotations,
  getViewsUserNotifications,
  readAllUserNotificationsB2b,
  updateUserNotification
} from '@/modules/b2b/services/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  LOAD_MORE_NOTIFICATION: 'LOAD_MORE_NOTIFICATION',
  LOAD_MORE_QUOTATIONS: 'LOAD_MORE_QUOTATIONS',
  UPDATE_SUB_BUYER: 'UPDATE_SUB_BUYER',
  GET_VIEWS_QUOTATIONS_DETAIL: 'GET_VIEWS_QUOTATIONS_DETAIL',
  GET_BADGE_NUMBERS: 'GET_BADGE_NUMBERS',
  MARK_ALL_AS_READ_NOTIFICATIONS: 'MARK_ALL_AS_READ_NOTIFICATIONS',
  RESET_QUERY_PARAMS: 'RESET_QUERY_PARAMS',

  GET_PENDING_QUOTATION_LIST: 'GET_PENDING_QUOTATION_LIST',
  GET_APPROVED_QUOTATION_LIST: ' GET_APPROVED_QUOTATION_LIST',
  GET_REJECTED_QUOTATION_LIST: 'GET_REJECTED_QUOTATION_LIST',

  UPDATE_BADGE_NOTIFICATION: 'UPDATE_BADGE_NOTIFICATION'
};

export default {
  async [ACTIONS.GET_NOTIFICATIONS]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_NOTIFICATIONS_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsUserNotifications,
        variables: {
          ...state.params,
          ...params
        }
      });
      if (state.params.isIncludeTotalCount) state.totalCount = data.getViewsUserNotifications.totalCount;
      if (onFinish) onFinish(data.getViewsUserNotifications);
      commit(MUTATIONS.GET_NOTIFICATIONS_SUCCESS, {
        notificationsLists: data.getViewsUserNotifications,
        notificationsBadges: data.getViewsUserNotificationBadges
      });
    } catch (error) {
      commit(MUTATIONS.GET_NOTIFICATIONS_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_NOTIFICATION]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_NOTIFICATIONS, { isLoadMore, onFinish });
  },

  async [ACTIONS.UPDATE_BADGE_NOTIFICATION]({ commit }) {
    commit(MUTATIONS.SET_BADGE_NOTIFICATION_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getUpdateBadgeNotifications
      });
      commit(MUTATIONS.SET_BADGE_NOTIFICATION_SUCCESS, {
        notificationsBadges: data.getViewsUserNotificationBadges
      });
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.SET_BADGE_NOTIFICATION_FAILED, error);
    }
  },

  async [ACTIONS.UPDATE_SUB_BUYER]({ commit, dispatch }, params) {
    commit(MUTATIONS.UPDATE_SUB_BUYER_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: updateUserNotification,
        variables: params
      });
      await dispatch(ACTIONS.UPDATE_BADGE_NOTIFICATION);
      commit(MUTATIONS.UPDATE_SUB_BUYER_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.UPDATE_SUB_BUYER_FAILURE, error);
    }
  },

  async [ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]({ commit }, params) {
    commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getViewsQuotations,
        variables: params
      });
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS, { quotationDetailData: data.getViewsQuotations });
    } catch (error) {
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_FAILURE, error);
    }
  },

  async [ACTIONS.MARK_ALL_AS_READ_NOTIFICATIONS]({ commit, dispatch }) {
    try {
      await apolloClient.mutate({
        mutation: readAllUserNotificationsB2b,
        variables: {}
      });
      await dispatch(ACTIONS.UPDATE_BADGE_NOTIFICATION);
      commit(MUTATIONS.MARK_ALL_AS_READ_NOTIFICATIONS_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.MARK_ALL_AS_READ_NOTIFICATIONS_FAILURE, error);
    }
  },
  async [ACTIONS.RESET_QUERY_PARAMS]({ commit }) {
    commit(MUTATIONS.RESET_QUERY_PARAMS, {
      params: {
        isIncludeTotalCount: true,
        limit: 20,
        offset: 0
      }
    });
  },

  // quotations
  async [ACTIONS.GET_PENDING_QUOTATION_LIST]({ commit }, { params, isLoadMore = false }) {
    commit(MUTATIONS.GET_PENDING_QUOTATION_LIST_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsQuotations,
        variables: {
          ...params,
          status: `PENDING`,
          addPendingAndReceivedQuotation: false
        }
      });

      commit(MUTATIONS.GET_PENDING_QUOTATION_LIST_SUCCESS, {
        quotationsPending: data.getViewsQuotations
      });
    } catch (error) {
      commit(MUTATIONS.GET_PENDING_QUOTATION_LIST_FAILURE, error);
    }
  },

  async [ACTIONS.GET_APPROVED_QUOTATION_LIST]({ commit }, { params, isLoadMore = false }) {
    commit(MUTATIONS.GET_APPROVED_QUOTATION_LIST_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsQuotations,
        variables: {
          ...params,
          status: `APPROVED`,
          addPendingAndReceivedQuotation: false
        }
      });

      commit(MUTATIONS.GET_APPROVED_QUOTATION_LIST_SUCCESS, {
        quotationsApproved: data.getViewsQuotations
      });
    } catch (error) {
      commit(MUTATIONS.GET_APPROVED_QUOTATION_LIST_FAILURE, error);
    }
  },

  async [ACTIONS.GET_REJECTED_QUOTATION_LIST]({ commit }, { params, isLoadMore = false }) {
    commit(MUTATIONS.GET_REJECTED_QUOTATION_LIST_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsQuotations,
        variables: {
          ...params,
          status: `REJECTED`,
          addPendingAndReceivedQuotation: false
        }
      });

      commit(MUTATIONS.GET_REJECTED_QUOTATION_LIST_SUCCESS, {
        quotationsRejected: data.getViewsQuotations
      });
    } catch (error) {
      commit(MUTATIONS.GET_REJECTED_QUOTATION_LIST_FAILURE, error);
    }
  }
};
