import { loadingController } from '@ionic/core';
import { ref } from 'vue';

export const useLoading = () => {
  const loading = ref();
  const createLoading = async () => {
    loading.value = await loadingController.create({
      spinner: 'crescent',
      message: '',
      duration: 5000,
      // translucent: true,
      cssClass: 'custom-loading'
    });
    return loading.value.present();
  };
  const showLoading = (callback) => {
    return Promise.allSettled([callback(), createLoading()]).finally(() => {
      dismissLoading();
    });
  };
  const dismissLoading = async () => {
    //close the loading after already get the data and then kill loadingController
    await loading.value.dismiss(true);
  };
  return {
    createLoading,
    loading,
    dismissLoading,
    showLoading
  };
};
