import { ref } from 'vue';

export const usePullToRefreshPage = () => {
  const isOnline = ref(true);
  const isLoadMoreData = ref(false);
  const detectConnection = () => {
    const connection = navigator.onLine;
    if (connection) {
      isOnline.value = true;
    } else {
      isOnline.value = false;
    }
  };
  const handleRefresh = async (event) => {
    await detectConnection();
    event.target.complete();
  };
  const handleRefreshAndRefetchData = async (event, fetchData) => {
    await handleRefresh(event);
    if (isOnline.value && !isLoadMoreData.value) {
      fetchData();
    }
  };
  return {
    isOnline,
    handleRefreshAndRefetchData
  };
};
