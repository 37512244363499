import { REQUEST, SUCCESS, FAILURE } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  ADD_ORDER_REQUEST: 'ADD_ORDER_REQUEST',
  ADD_ORDER_SUCCESS: 'ADD_ORDER_SUCCESS',
  ADD_ORDER_FAILURE: 'ADD_ORDER_FAILURE',

  CHECK_DOUBLE_ORDER_REQUEST: 'CHECK_DOUBLE_ORDER_REQUEST',
  CHECK_DOUBLE_ORDER_SUCCESS: 'CHECK_DOUBLE_ORDER_SUCCESS',
  CHECK_DOUBLE_ORDER_FAILURE: 'CHECK_DOUBLE_ORDER_FAILURE'
};

export default {
  // ADD ORDER
  [MUTATIONS.ADD_ORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_ORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // CHECK DOUBLE ORDER
  [MUTATIONS.CHECK_DOUBLE_ORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CHECK_DOUBLE_ORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.CHECK_DOUBLE_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  }
};
