import { DEFAULT_GETTERS } from '@/modules/sale/constants';

export default {
  ...DEFAULT_GETTERS,
  list: (state) => state.list,
  moreProductList: (state) => state.moreProductList,
  getProductStatus: (state) => state.getProductStatus,
  listProduct: (state) => state.list.category,
  item: (state) => state.item,
  search: (state) => state.search,
  searchMore: (state) => state.searchMore,
  type: (state) => state.type,
  isLoadMore: (state) => state.isLoadMore,
  listCache: (state) => state.listCache,
  CachesaleUpdatePrice: (state) => state.CachesaleUpdatePrice,
  isChangeAllCategory: (state) => state.isChangeAllCategory,
  oosList: (state) => state.listOosProduct,
  productIdAfterExplore: (state) => state.productIdAfterExplore,
  filterAttributesSales: (state) => state.filterAttributesSales
};
