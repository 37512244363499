import { FAILURE, REQUEST, SUCCESS } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',
  EXPLORE_RELATED_CATEGORIES: 'EXPLORE_RELATED_CATEGORIES'
};

export default {
  [MUTATIONS.GET_CATEGORIES_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CATEGORIES_SUCCESS](state, list) {
    SUCCESS(state);
    state.list = list;
  },
  [MUTATIONS.GET_CATEGORIES_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.EXPLORE_RELATED_CATEGORIES](state, params) {
    state.exploreRelatedCategory = params;
  }
};
