import { FAILURE, REQUEST, SUCCESS } from '@/modules/b2b/services/libs/state';

export const MUTATIONS = {
  GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_FAILURE',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  GET_PEPPOL_SCHEME_AND_COUNTRIES_REQUEST: 'GET_PEPPOL_SCHEME_AND_COUNTRIES_REQUEST',
  GET_PEPPOL_SCHEME_AND_COUNTRIES_SUCCESS: 'GET_PEPPOL_SCHEME_AND_COUNTRIES_SUCCESS',
  GET_PEPPOL_SCHEME_AND_COUNTRIES_FAILURE: 'GET_PEPPOL_SCHEME_AND_COUNTRIES_FAILURE',

  GET_CONNTECT_PEPPOL_PAGE_REQUEST: 'GET_CONNTECT_PEPPOL_PAGE_REQUEST',
  GET_CONNTECT_PEPPOL_PAGE_SUCCESS: 'GET_CONNTECT_PEPPOL_PAGE_SUCCESS',
  GET_CONNTECT_PEPPOL_PAGE_FAILURE: 'GET_CONNTECT_PEPPOL_PAGE_FAILURE',

  REGISTER_PEPPOL_REQUEST: 'REGISTER_PEPPOL_REQUEST',
  REGISTER_PEPPOL_SUCCESS: 'REGISTER_PEPPOL_SUCCESS',
  REGISTER_PEPPOL_FAILURE: 'REGISTER_PEPPOL_FAILURE',

  UPDATE_CUSTOMER_PEPPOL_REQUEST: 'UPDATE_CUSTOMER_PEPPOL_REQUEST',
  UPDATE_CUSTOMER_PEPPOL_SUCCESS: 'UPDATE_CUSTOMER_PEPPOL_SUCCESS',
  UPDATE_CUSTOMER_PEPPOL_FAILURE: 'UPDATE_CUSTOMER_PEPPOL_FAILURE',

  REQUEST_NEW_PRODUCT_REQUEST: 'REQUEST_NEW_PRODUCT_REQUEST',
  REQUEST_NEW_PRODUCT_SUCCESS: 'REQUEST_NEW_PRODUCT_SUCCESS',
  REQUEST_NEW_PRODUCT_FAILURE: 'REQUEST_NEW_PRODUCT_FAILURE',

  CHECK_OLD_PASSWORD_REQUEST: 'CHECK_OLD_PASSWORD_REQUEST',
  CHECK_OLD_PASSWORD_SUCCESS: 'CHECK_OLD_PASSWORD_SUCCESS',
  CHECK_OLD_PASSWORD_FAILURE: 'CHECK_OLD_PASSWORD_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',

  // reset state
  RESET_STATE: 'RESET_STATE',
  SEND_AVATAR_LINK: 'SEND_AVATAR_LINK',
  RENDER_ACCOUNT: 'RENDER_ACCOUNT'
};

export default {
  [MUTATIONS.GET_CURRENT_USER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CURRENT_USER_SUCCESS](state, user) {
    SUCCESS(state);
    state.user = user;
  },
  [MUTATIONS.GET_CURRENT_USER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.UPDATE_PROFILE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_PROFILE_SUCCESS](state, updateUser) {
    SUCCESS(state);
    state.updateUser = updateUser;
  },
  [MUTATIONS.UPDATE_PROFILE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES_SUCCESS](state, peppolSchemesAndCountries) {
    SUCCESS(state);
    state.peppolSchemesAndCountries = peppolSchemesAndCountries;
  },
  [MUTATIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.GET_CONNTECT_PEPPOL_PAGE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CONNTECT_PEPPOL_PAGE_SUCCESS](state, connectPeppolPage) {
    SUCCESS(state);
    state.connectPeppolPage = connectPeppolPage;
  },
  [MUTATIONS.GET_CONNTECT_PEPPOL_PAGE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.REGISTER_PEPPOL_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.REGISTER_PEPPOL_SUCCESS](state, registerPeppol) {
    SUCCESS(state);
    state.registerPeppol = registerPeppol;
  },
  [MUTATIONS.REGISTER_PEPPOL_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.UPDATE_CUSTOMER_PEPPOL_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_CUSTOMER_PEPPOL_SUCCESS](state, updateCustomerPeppol) {
    SUCCESS(state);
    state.updateCustomerPeppol = updateCustomerPeppol;
  },
  [MUTATIONS.UPDATE_CUSTOMER_PEPPOL_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.REQUEST_NEW_PRODUCT_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.REQUEST_NEW_PRODUCT_SUCCESS](state, updateCustomerPeppol) {
    SUCCESS(state);
    state.updateCustomerPeppol = updateCustomerPeppol;
  },
  [MUTATIONS.REQUEST_NEW_PRODUCT_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.CHECK_OLD_PASSWORD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CHECK_OLD_PASSWORD_SUCCESS](state, checkOldPassword) {
    SUCCESS(state);
    state.checkOldPassword = checkOldPassword;
  },
  [MUTATIONS.CHECK_OLD_PASSWORD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.UPDATE_PASSWORD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_PASSWORD_SUCCESS](state, updatePassword) {
    SUCCESS(state);
    state.updatePassword = updatePassword;
  },
  [MUTATIONS.UPDATE_PASSWORD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.DELETE_ACCOUNT_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.DELETE_ACCOUNT_SUCCESS](state, isDeleted) {
    SUCCESS(state);
    if (isDeleted) {
      Object.assign(state, {});
    }
  },
  [MUTATIONS.DELETE_ACCOUNT_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.RESET_STATE](state) {
    Object.assign(state, {});
  },

  // Reset status and error back to default value
  [MUTATIONS.RESET_STATUS_ERROR](state) {
    state.status = true;
    state.error = null;
  },
  async [MUTATIONS.SEND_AVATAR_LINK](state) {
    state.cropImg = new Date().getTime();
  },
  [MUTATIONS.RENDER_ACCOUNT](state, params) {
    state.isRender = params;
  }
};
