import { FAILURE, REQUEST, SUCCESS } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

  GET_MORE_PRODUCTS_REQUEST: 'GET_MORE_PRODUCTS_REQUEST',
  GET_MORE_PRODUCTS_SUCCESS: 'GET_MORE_PRODUCTS_SUCCESS',
  GET_MORE_PRODUCTS_FAILURE: 'GET_MORE_PRODUCTS_FAILURE',

  GET_SALE_PRODUCTS_REQUEST: 'GET_SALE_PRODUCTS_REQUEST',
  GET_SALE_PRODUCTS_SUCCESS: 'GET_SALE_PRODUCTS_SUCCESS',
  GET_SALE_PRODUCTS_FAILURE: 'GET_SALE_PRODUCTS_FAILURE',
  GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
  GET_MORE_PRODUCT_DETAILS_SUCCESS: 'GET_MORE_PRODUCT_DETAILS_SUCCESS',

  UPDATE_SEARCH: 'UPDATE_SEARCH',

  RESET_SEARCH_RESULT: 'RESET_SEARCH_RESULT',
  RESET_CATEGORY: 'RESET_CATEGORY',
  CHANGE_ALL_CATEGORY: 'CHANGE_ALL_CATEGORY'
};

export default {
  // get products
  [MUTATIONS.GET_PRODUCTS_REQUEST](state, isLoadMore) {
    state.isLoadMore = isLoadMore;

    // reset old product list
    REQUEST(state);
    if (!isLoadMore) {
      state.list.items = [];
    }
  },
  async [MUTATIONS.GET_PRODUCTS_SUCCESS](state, { list }) {
    SUCCESS(state);
    state.getProductStatus = 'Success';

    if (state.search.buyerId != list.buyerId) {
      return;
    }

    if (state.isLoadMore) {
      const items = [...state.list.items, ...list.items];
      state.list = {
        ...list,
        items
      };
      state.list.category = state.listCache;
    } else {
      state.list = list;
      state.listCache = state.list.category;
    }
    state.type = null;
  },
  [MUTATIONS.GET_PRODUCTS_FAILURE](state, error) {
    FAILURE(state, error);
    state.getProductStatus = error?.getProductStatus;
  },

  // get more product list search page
  [MUTATIONS.GET_MORE_PRODUCTS_REQUEST](state, isLoadMore) {
    state.isLoadMore = isLoadMore;

    // reset old product list
    REQUEST(state);
    if (!isLoadMore) {
      state.moreProductList.items = [];
    }
  },
  async [MUTATIONS.GET_MORE_PRODUCTS_SUCCESS](state, { moreProductList }) {
    SUCCESS(state);
    state.getProductStatus = 'Success';

    if (state.searchMore.buyerId != moreProductList.buyerId) {
      return;
    }

    if (state.isLoadMore) {
      const items = [...state.moreProductList.items, ...moreProductList.items];
      state.moreProductList = {
        ...moreProductList,
        items
      };
      state.moreProductList.category = state.listCache;
    } else {
      state.moreProductList = moreProductList;
      state.listCache = state.moreProductList.category;
    }
    state.type = null;
  },
  [MUTATIONS.GET_MORE_PRODUCTS_FAILURE](state, error) {
    FAILURE(state, error);
    state.getProductStatus = error?.getProductStatus;
  },

  // get sale products recommend
  [MUTATIONS.GET_SALE_PRODUCTS_SUCCESS](state, listOosProduct) {
    SUCCESS(state);
    state.listOosProduct = listOosProduct;
    state.type = null;
  },
  [MUTATIONS.GET_SALE_PRODUCTS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // search
  [MUTATIONS.UPDATE_SEARCH](state, { search, isLoadMore, type }) {
    state.search = {
      ...state.search,
      ...search
    };
    state.isLoadMore = isLoadMore;
    if (type) {
      state.type = type;
    }
  },

  [MUTATIONS.RESET_CATEGORY](state) {
    state.resetCategory = !state.resetCategory;
  },
  [MUTATIONS.CHANGE_ALL_CATEGORY](state) {
    state.isChangeAllCategory += state.isChangeAllCategory;
  },

  [MUTATIONS.GET_PRODUCT_DETAILS_SUCCESS](state, additionalInfos) {
    // update value for state Item
    if (additionalInfos) {
      state.list.items.forEach((item) => {
        const productAdditionalInfos = additionalInfos.find((obj) => obj.id === item.id);
        if (productAdditionalInfos) {
          item.productAdditionalInfos = productAdditionalInfos;
        }
      });
    }
  },

  [MUTATIONS.GET_MORE_PRODUCT_DETAILS_SUCCESS](state, additionalInfos) {
    // update value for state Item in  More product list
    if (additionalInfos) {
      state.moreProductList.items.forEach((item) => {
        const productAdditionalInfos = additionalInfos.find((obj) => obj.id === item.id);
        if (productAdditionalInfos) {
          item.productAdditionalInfos = productAdditionalInfos;
        }
      });
    }
  },
  [MUTATIONS.RESET_SEARCH_RESULT](state) {
    state.moreProductList = {
      items: []
    };
    state.list = {
      items: []
    };
  }
};
