import { state as account } from './account';
import { state as cart } from './cart';
import { state as category } from './category';
import { state as customer } from './customer';
import { state as notification } from './notification';
import { state as payment } from './payment';
import { state as product } from './product';
import { state as productSearch } from './product-search';

export const state = {
  account,
  cart,
  category,
  customer,
  payment,
  product,
  notification,
  productSearch
};
