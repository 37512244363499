import { apolloClient } from '@/main';
import {
  addStripeCard,
  cancelPrepayment,
  cancelStripePaymentIntent,
  createB2bAutomaticBatchPayments,
  createPaymentB2b,
  createPaymentChequeCash,
  getInvoicesByCustomer,
  getIsFinanceModuleEnabled,
  getListCustomerCard,
  getListNetsCard,
  getNetsMerchantToken,
  getTotalUnpaidInvoices,
  getXeroCustomer,
  listCardByStripeCustomerId,
  removeNetsCard,
  removeStripeCard,
  setDefaultCard
} from '@/modules/b2b/services/graphql';
import {
  cancelManualPayment,
  getDefaultCard,
  getInvoicePaymentHistory
} from '@/modules/shared/services/graphql';
import { state } from '.';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_LIST_CUSTOMER_CARD: 'GET_LIST_CUSTOMER_CARD',
  GET_DEFAULT_CARD: 'GET_DEFAULT_CARD',
  REMOVE_CARD: 'REMOVE_CARD',
  ADD_STRIPE_CARD: 'ADD_STRIPE_CARD ',
  SET_DEFAULT_CARD: 'SET_DEFAULT_CARD',
  GET_LIST_NETS_CARD: 'GET_LIST_NETS_CARD',
  GET_NETS_MERCHANT_TOKEN: 'GET_NETS_MERCHANT_TOKEN',
  REMOVE_NETS_CARD: 'REMOVE_NETS_CARD',
  SET_NETS_CARD_DEFAULT: 'SET_NETS_CARD_DEFAULT',
  SET_STRIPE_ID: 'SET_STRIPE_ID',
  GET_LIST_CARD_BY_STRIPE_ID: 'GET_LIST_CARD_BY_STRIPE_ID',
  GET_CUSTOMER_INVOICES: 'GET_CUSTOMER_INVOICES',
  LOAD_MORE_CUSTOMER_INVOICES: 'LOAD_MORE_CUSTOMER_INVOICES',
  RESET_STATUS_ERROR: 'RESET_STATUS_ERROR',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
  CREATE_CASH_PAYMENT: 'CREATE_CASH_PAYMENT',
  GET_XERO_CUSTOMER: 'GET_XERO_CUSTOMER',
  GET_TOTAL_UNPAID_INVOICES: 'GET_TOTAL_UNPAID_INVOICES',
  SET_SELECTED_PAYMENT_INVOICES: 'SET_SELECTED_PAYMENT_INVOICES',
  REMOVE_SELECTED_PAYMENT_INVOICES: 'REMOVE_SELECTED_PAYMENT_INVOICES',
  CHECK_IS_FINANCE_MODULE_ENABLED: 'CHECK_IS_FINANCE_MODULE_ENABLED',
  CREATE_AUTOMATIC_BATCH_PAYMENTS: 'CREATE_AUTOMATIC_BATCH_PAYMENTS',
  GET_PAYMENT_HISTORY: 'GET_PAYMENT_HISTORY',
  CANCEL_MANUAL_PAYMENT: 'CANCEL_MANUAL_PAYMENT',
  CANCEL_PREPAYMENT: 'CANCEL_PREPAYMENT',
  CANCEL_STRIPE_PAYMENT: 'CANCEL_STRIPE_PAYMENT',
  SET_IS_ENTER_STRIPE_URL: 'SET_IS_ENTER_STRIPE_URL'
};

export default {
  async [ACTIONS.SET_STRIPE_ID]({ commit }, stripeId) {
    commit(MUTATIONS.SET_STRIPE_ID, stripeId);
  },
  async [ACTIONS.GET_LIST_CARD_BY_STRIPE_ID]({ commit }, stripeId) {
    commit(MUTATIONS.GET_LIST_CARD_BY_STRIPE_ID_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: listCardByStripeCustomerId,
        variables: {
          stripeCustomerId: stripeId
        }
      });
      commit(MUTATIONS.GET_LIST_CARD_BY_STRIPE_ID_SUCCESS, data.listCardByStripeCustomerId.data);
    } catch (error) {
      commit(MUTATIONS.GET_LIST_CARD_BY_STRIPE_ID_ERROR, error);
    }
  },

  // get list customer card
  async [ACTIONS.GET_LIST_CUSTOMER_CARD]({ commit }, params) {
    commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getListCustomerCard,
        variables: {
          customerId: params
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_SUCCESS, data.listCardByCustomerId);
      } else {
        commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_FAILURE, errors[0]);
      }
    } catch (error) {
      if (error instanceof Error) commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_FAILURE, error);
      throw new Error(error);
    }
  },

  // get default card
  async [ACTIONS.GET_DEFAULT_CARD]({ commit }, params) {
    commit(MUTATIONS.GET_DEFAULT_CARD_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getDefaultCard,
        variables: {
          customerId: params
        }
      });
      if (!errors) {
        const cardId = data.getDefaultCardByCustomer?.cardId ?? null;
        commit(MUTATIONS.GET_DEFAULT_CARD_SUCCESS, cardId);
      } else {
        commit(MUTATIONS.GET_DEFAULT_CARD_FAILURE, errors[0]);
      }
    } catch (error) {
      if (error instanceof Error) commit(MUTATIONS.GET_DEFAULT_CARD_FAILURE, error);
      throw new Error(error);
    }
  },

  // remove card
  async [ACTIONS.REMOVE_CARD]({ commit }, params) {
    commit(MUTATIONS.REMOVE_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: removeStripeCard,
        variables: params
      });
      if (data.removeCardByCustomer.message) {
        commit(MUTATIONS.REMOVE_CARD_FAILURE, data.removeCardByCustomer);
      } else {
        commit(MUTATIONS.REMOVE_CARD_SUCCESS, data.removeCardByCustomer);
      }
    } catch (error) {
      commit(MUTATIONS.REMOVE_CARD_FAILURE, error);
    }
  },

  // add stripe card
  async [ACTIONS.ADD_STRIPE_CARD]({ commit }, card) {
    commit(MUTATIONS.ADD_STRIPE_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: addStripeCard,
        variables: card
      });
      if (data.addCardByCustomer.message) {
        commit(MUTATIONS.ADD_STRIPE_CARD_FAILURE, data.addCardByCustomer);
      } else {
        commit(MUTATIONS.ADD_STRIPE_CARD_SUCCESS, data.addCardByCustomer);
      }
    } catch (error) {
      commit(MUTATIONS.ADD_STRIPE_CARD_FAILURE, error);
    }
  },

  // set default card
  async [ACTIONS.SET_DEFAULT_CARD]({ commit }, params) {
    commit(MUTATIONS.SET_DEFAULT_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: setDefaultCard,
        variables: params
      });
      if (data.setDefaultCardByCustomer.message) {
        commit(MUTATIONS.SET_DEFAULT_CARD_FAILURE, data.setDefaultCardByCustomer);
      } else {
        commit(MUTATIONS.SET_DEFAULT_CARD_SUCCESS, data.setDefaultCardByCustomer.cardId);
      }
    } catch (error) {
      commit(MUTATIONS.SET_DEFAULT_CARD_FAILURE, error);
    }
  },

  // logic NETS cards
  async [ACTIONS.GET_LIST_NETS_CARD]({ commit }, deviceId) {
    try {
      const { data } = await apolloClient.query({
        query: getListNetsCard,
        variables: {
          deviceId
        }
      });
      commit(MUTATIONS.GET_LIST_NETS_CARD_SUCCESS, data.getListNetsCard);
    } catch (error) {
      commit(MUTATIONS.GET_LIST_NETS_CARD_FAILURE, error);
    }
  },
  async [ACTIONS.GET_NETS_MERCHANT_TOKEN]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: getNetsMerchantToken,
        variables: {
          deviceId: params.deviceId,
          t0102: params.t0102
        }
      });
      commit(MUTATIONS.GET_NETS_LIST_MERCHANT_SUCCESS, data.getNetsMerchantToken);
      return data.getNetsMerchantToken;
    } catch (error) {
      console.log(`echo: ACTIONS.GET_NETS_MERCHANT_TOKEN ERRRR=== ${JSON.stringify(error.message)}`);
      commit(MUTATIONS.GET_NETS_LIST_MERCHANT_FAILURE, error);
    }
  },
  async [ACTIONS.REMOVE_NETS_CARD]({ commit }, params) {
    try {
      await apolloClient.mutate({
        mutation: removeNetsCard,
        variables: {
          deviceId: params.deviceId
        }
      });
      commit(MUTATIONS.REMOVE_NETS_CARD_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.REMOVE_NETS_CARD_FAILURE, error);
    }
  },
  async [ACTIONS.GET_CUSTOMER_INVOICES](
    { commit, state },
    { paramsListInvoices, isLoadMore = false, onFinish }
  ) {
    commit(MUTATIONS.GET_CUSTOMER_INVOICES_REQUEST, isLoadMore);
    try {
      const { data, errors } = await apolloClient.query({
        query: getInvoicesByCustomer,
        variables: {
          ...state.paramsListInvoices,
          ...paramsListInvoices
        }
      });
      if (!errors) {
        if (state.paramsListInvoices.isIncludeTotalCount)
          state.totalCountInvoices = data.getInvoicesByCustomer.totalCount;

        if (onFinish) onFinish();
        commit(MUTATIONS.GET_CUSTOMER_INVOICES_SUCCESS, { listInvoices: data.getInvoicesByCustomer });
      } else {
        commit(MUTATIONS.GET_CUSTOMER_INVOICES_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_INVOICES_FAILURE, error);
    }
  },
  async [ACTIONS.LOAD_MORE_CUSTOMER_INVOICES](
    { commit, dispatch },
    { paramsListInvoices, isLoadMore = true, onFinish }
  ) {
    commit(MUTATIONS.UPDATE_QUERY_PARAMS_GET_CUSTOMER_INVOICE, { paramsListInvoices });
    dispatch(ACTIONS.GET_CUSTOMER_INVOICES, { isLoadMore, onFinish });
  },
  async [ACTIONS.RESET_STATUS_ERROR]({ commit }) {
    commit(MUTATIONS.RESET_STATUS_ERROR);
  },
  async [ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS]({ commit }, { paramsCreatePayment }) {
    commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_REQUEST, paramsCreatePayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createB2bAutomaticBatchPayments,
        variables: {
          ...paramsCreatePayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_SUCCESS, {
          paymentSummary: data.createB2bAutomaticBatchPayments
        });
      } else {
        commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_PAYMENT]({ commit }, { paramsCreatePayment }) {
    commit(MUTATIONS.CREATE_PAYMENT_REQUEST, paramsCreatePayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createPaymentB2b,
        variables: {
          ...state.paramsCreatePayment,
          ...paramsCreatePayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_PAYMENT_SUCCESS, { paymentSummary: data.createPaymentB2b });
      } else {
        commit(MUTATIONS.CREATE_PAYMENT_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_PAYMENT_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_CASH_PAYMENT]({ commit }, { paramsCreateCashChequePayment }) {
    commit(MUTATIONS.CREATE_CASH_PAYMENT_REQUEST, paramsCreateCashChequePayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createPaymentChequeCash,
        variables: {
          ...state.paramsCreateCashChequePayment,
          ...paramsCreateCashChequePayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_CASH_PAYMENT_SUCCESS, { paymentSummary: data.createPaymentChequeCash });
      } else {
        commit(MUTATIONS.CREATE_CASH_PAYMENT_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_CASH_PAYMENT_FAILURE, error);
    }
  },

  async [ACTIONS.GET_XERO_CUSTOMER]({ commit }, params) {
    try {
      const { data, errors } = await apolloClient.query({
        query: getXeroCustomer,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_XERO_CUSTOMER_SUCCESS, data.getXeroCustomer);
      } else {
        commit(MUTATIONS.GET_XERO_CUSTOMER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_XERO_CUSTOMER_FAILURE, error);
    }
  },
  async [ACTIONS.GET_TOTAL_UNPAID_INVOICES]({ commit }, params) {
    try {
      const { data, errors } = await apolloClient.query({
        query: getTotalUnpaidInvoices,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_TOTAL_UNPAID_INVOICES_SUCCESS, data.getTotalUnpaidInvoices);
      } else {
        commit(MUTATIONS.GET_TOTAL_UNPAID_INVOICES_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_TOTAL_UNPAID_INVOICES_FAILURE, error);
    }
  },
  async [ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({ commit }, data) {
    commit(MUTATIONS.SET_SELECTED_PAYMENT_INVOICES, data);
  },

  async [ACTIONS.SET_IS_ENTER_STRIPE_URL]({ commit }, boolean) {
    commit(MUTATIONS.SET_IS_ENTER_STRIPE_URL, boolean);
  },

  async [ACTIONS.REMOVE_SELECTED_PAYMENT_INVOICES]({ commit }) {
    commit(MUTATIONS.REMOVE_SELECTED_PAYMENT_INVOICES);
  },
  async [ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: getIsFinanceModuleEnabled,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      commit(MUTATIONS.CHECK_IS_FINANCE_MODULE_ENABLED_SUCCESS, data.getIsFinanceModuleEnabled);
    } catch (error) {
      commit(MUTATIONS.CHECK_IS_FINANCE_MODULE_ENABLED_FAILURE, error);
    }
  },

  async [ACTIONS.GET_PAYMENT_HISTORY]({ commit }, params) {
    commit(MUTATIONS.GET_PAYMENT_HISTORY_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getInvoicePaymentHistory,
        variables: {
          tenantId: params.tenantId,
          invoiceId: params.invoiceId
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_PAYMENT_HISTORY_SUCCESS, {
          paymentHistory: data.getInvoicePaymentHistory
        });
      } else {
        commit(MUTATIONS.GET_PAYMENT_HISTORY_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_PAYMENT_HISTORY_FAILED, error);
    }
  },

  async [ACTIONS.CANCEL_MANUAL_PAYMENT]({ commit, state, dispatch }, { batchPaymentId, ...rest }) {
    commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: cancelManualPayment,
        variables: {
          batchPaymentId
        }
      });
      if (!errors) {
        await dispatch(ACTIONS.GET_CUSTOMER_INVOICES, {
          paramsListInvoices: {
            ...state.paramsListInvoices,
            offset: 0,
            tenantId: rest.tenantId,
            customerId: rest.customerId
          }
        });
        commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_SUCCESS, {
          cancelManualPayment: data.cancelManualPayment
        });
      } else {
        await dispatch(ACTIONS.GET_CUSTOMER_INVOICES, {
          paramsListInvoices: {
            ...state.paramsListInvoices,
            offset: 0,
            tenantId: rest.tenantId,
            customerId: rest.customerId
          }
        });
        commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_FAILED, error);
    } finally {
      commit(MUTATIONS.END_CANCEL_MANUAL_PAYMENT);
    }
  },

  async [ACTIONS.CANCEL_PREPAYMENT]({ commit, state, dispatch }, { stripeCheckoutSessionId }) {
    commit(MUTATIONS.CANCEL_PREPAYMENT_PAYMENT_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: cancelPrepayment,
        variables: {
          stripeCheckoutSessionId
        }
      });
      if (!errors) {
        await dispatch(ACTIONS.GET_CUSTOMER_INVOICES, {
          paramsListInvoices: { ...state.paramsListInvoices, offset: 0 }
        });
        commit(MUTATIONS.CANCEL_PREPAYMENT_PAYMENT_SUCCESS, {
          cancelPrepayment: data.cancelPrepayment
        });
      } else {
        commit(MUTATIONS.CANCEL_PREPAYMENT_PAYMENT_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CANCEL_PREPAYMENT_PAYMENT_FAILED, error);
    } finally {
      commit(MUTATIONS.END_CANCEL_PREPAYMENT);
    }
  },

  async [ACTIONS.CANCEL_STRIPE_PAYMENT]({ commit, state, dispatch }, { stripePaymentIntentId }) {
    commit(MUTATIONS.CANCEL_STRIPE_PAYMENT_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: cancelStripePaymentIntent,
        variables: {
          stripePaymentIntentId
        }
      });
      if (!errors) {
        await dispatch(ACTIONS.GET_CUSTOMER_INVOICES, {
          paramsListInvoices: { ...state.paramsListInvoices, offset: 0 }
        });
        commit(MUTATIONS.CANCEL_STRIPE_PAYMENT_SUCCESS, {
          cancelStripePaymentIntent: data.cancelStripePaymentIntent
        });
      } else {
        commit(MUTATIONS.CANCEL_STRIPE_PAYMENT_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CANCEL_STRIPE_PAYMENT_FAILED, error);
    } finally {
      commit(MUTATIONS.END_CANCEL_STRIPE_PAYMENT);
    }
  }
};
