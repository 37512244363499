import { apolloClient } from '@/main';
import { getMessage } from '@/services/shared/helper/services.js';
import { MUTATIONS } from './mutations';

import {
  saleAddOrder,
  saleCheckAllowDoubleOrder,
  saleGetDeliveryTimes
} from '@/modules/sale/services/graphql';

export const ACTIONS = {
  ADD_ORDER: 'ADD_ORDER',
  GET_DELIVERY_TIMES: 'GET_DELIVERY_TIMES',
  CHECK_DOUBLE_ORDER: 'CHECK_DOUBLE_ORDER'
};

export default {
  // check duplicate ORDER
  async [ACTIONS.CHECK_DOUBLE_ORDER]({ commit }) {
    commit(MUTATIONS.CHECK_DOUBLE_ORDER_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleCheckAllowDoubleOrder
      });
      commit(MUTATIONS.CHECK_DOUBLE_ORDER_SUCCESS);
      return data.saleCheckAllowDoubleOrder;
    } catch (error) {
      commit(MUTATIONS.CHECK_DOUBLE_ORDER_FAILURE, error);
    }
    return null;
  },

  // add ORDER
  async [ACTIONS.ADD_ORDER]({ commit, state }, params) {
    commit(MUTATIONS.ADD_ORDER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: saleAddOrder,
        variables: params
      });
      if (!errors || errors.length === 0) {
        commit(MUTATIONS.ADD_ORDER_SUCCESS);
      } else {
        commit(MUTATIONS.ADD_ORDER_FAILURE, errors[0]);
        state.notTaggedItemsIds = errors[0].extensions?.skuIds;
      }
      return data;
    } catch (error) {
      commit(MUTATIONS.ADD_ORDER_FAILURE, getMessage(error));
    }
  },

  async [ACTIONS.GET_DELIVERY_TIMES]({ state }, payload) {
    state.timeSlot = [];
    try {
      const { data } = await apolloClient.query({
        query: saleGetDeliveryTimes,
        variables: {
          active: true,
          tenant_id: payload.tenantId,
          selectedDate: payload.selectedDate || null
        }
      });

      if (data) state.timeSlot = data.saleGetDeliveryTimes;
      return data.saleGetDeliveryTimes;
    } catch (error) {
      console.log(error);
    }
  }
};
