import Image from '@/components/atoms/image/Image.vue';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { Storage } from '@ionic/storage';
import * as IonComponents from '@ionic/vue';
import { alertController, IonicVue, isPlatform, modalController } from '@ionic/vue';
import { Deploy } from 'cordova-plugin-ionic';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import TDStorage from './storage';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';

/* Theme variables */
import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';
import './assets/css/global.scss';
import './assets/css/utils.scss';
import './theme/variables.css';

import { getAppVersion } from '@/modules/b2b/services/graphql';
import { globalFunction } from '@/utils/global-function';
import { App as CapacitorApp } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { localize } from '@vee-validate/i18n';
import AllRules from '@vee-validate/rules';
import fetch from 'node-fetch';
import { configure, defineRule } from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import messages from './assets/i18n';
import { authGuard } from './modules/shared/guards';
import store from './store';

import { Browser } from '@capacitor/browser';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { compare } from 'compare-versions';
import meta from '../package.json';
import ProgressBarModal from './components/ProgressBarModal';
import PushNotification from './services/shared/helper/push-notification';
import * as Sentry from './utils/Sentry';

// setting for branch io
// import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import Branch from '@/services/shared/helper/branch';

// NETS payment
// import Payment from '@/plugins/nets-payment-plugin.js';

// firebase
import * as Firebase from './services/shared/helper/firebase';

// VueZoomer for zoom image
import VueVideoPlayer from '@videojs-player/vue';
import 'video.js/dist/video-js.css';
import VueZoomer from 'vue-zoomer';

// dayjs locale
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/ms';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import localeChinese from './plugins/both/dayjs';
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

const {
  VUE_APP_ENV,
  VUE_APP_GRAPHQL_API,
  // VUE_APP_LOG_ROCKET_ID,
  VUE_APP_GOOGLEMAPS_KEY,
  VUE_APP_APPFLOW_ID,
  VUE_APP_APPFLOW_ENV,
  APOLLO_TIMEOUT = 90000
} = process.env;

const isNative = Capacitor.isNativePlatform();

// The following are default rules for vee-validate.
// https://vee-validate.logaretm.com/v4/guide/global-validators#available-rules
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

// Rule 'is' error message set to 'field entered does not match'.
configure({
  generateMessage: localize('en', {
    messages: {
      is: '{field} entered does not match'
    }
  })
});

// Custom rule for phones. We need a json of all countries and their respective masks to compare against.
// Temporarily set to no validation.
const validatePhone = () => {
  return true;
};

defineRule('phoneValidator', (value) => {
  return validatePhone(value) ? true : false;
});

// init ionic storage
const storage = new Storage();
let apolloClient;

// init NET payment in paralle
// if (isNative) {
//   Payment.onInitialization()
//     .then((info) => {
//       console.log(info);
//     })
//     .catch((e) => {
//       console.log('errorrr ', e);
//     });
// }

let i18n;
const configureDeploy = async () => {
  const config = {
    appId: VUE_APP_APPFLOW_ID,
    channel: VUE_APP_APPFLOW_ENV
  };
  try {
    await Deploy.configure(config);
  } catch (e) {
    console.log(e);
  }
};

const performAutomaticUpdate = async (tdStorage) => {
  try {
    const update = await Deploy.checkForUpdate();

    if (update.available) {
      // need to disable native back button
      await tdStorage.setDisableNativeBackButton('1');

      const modal = await modalController.create({
        component: ProgressBarModal
      });
      modal.present();

      modal.onDidDismiss().then(async () => {
        // need to un-disable native back button
        await tdStorage.setDisableNativeBackButton('0');
        await Deploy.reloadApp();
      });
    }
  } catch (err) {
    // We encountered an error.
    await tdStorage.setDisableNativeBackButton('0');
    console.log(err);
  }
};

const checkForUpdate = async () => {
  // get version into
  const { data } = await apolloClient.query({
    query: getAppVersion,
    variables: {
      appType: 4
    }
  });

  const appInfo = await CapacitorApp.getInfo();
  const currentAppVersion = appInfo?.version ? appInfo?.version : meta.version;
  if (data.getAppVersion.length > 0) {
    let iosVersion = data.getAppVersion[0].ios;
    let androidVersion = data.getAppVersion[0].android;
    let hasUpdates = false;
    let storeURL = '';
    if (compare(iosVersion, currentAppVersion, '>') && isPlatform('ios')) {
      hasUpdates = true;
      storeURL = 'https://apps.apple.com/us/app/treedots/id1448811993';
    } else if (compare(androidVersion, currentAppVersion, '>') && isPlatform('android')) {
      hasUpdates = true;
      storeURL = 'https://play.google.com/store/apps/details?id=com.thetreedots';
    }

    if (hasUpdates && storeURL) {
      const alert = await alertController.create({
        cssClass: 'alert-update-style',
        subHeader: 'Update Required',
        message: 'An update to TreeDots for Merchants is required to continue',
        buttons: [
          {
            text: 'UPDATE',
            handler: () => {
              window.open(storeURL, '_system');
              return false;
            }
          }
        ],
        backdropDismiss: false,
        animated: true
      });
      alert.present();
      return true;
    }
  }
};

const checkNewVersion = async (tdStorage) => {
  await tdStorage.setDisableNativeBackButton('0');
  // check live update
  if (VUE_APP_ENV !== 'local' && isNative) {
    try {
      const needForceUpdate = await checkForUpdate();
      if (!needForceUpdate) performAutomaticUpdate(tdStorage);
    } catch (e) {
      console.log(`echo: can't check for update ${isNative}`);
    }
  }
};

storage.create().then(async () => {
  const tdStorage = new TDStorage(storage);
  // add storage object into the meta of route
  // This used to create some guard that need get data from storage
  router.beforeEach(async (to, from, next) => {
    from.meta.$storage = tdStorage;
    await checkNewVersion(tdStorage);
    return authGuard(to, from, next);
  });

  const httpLink = createHttpLink({
    uri: VUE_APP_GRAPHQL_API,
    fetch: fetch
  });

  const authMiddleware = new ApolloLink(async (operation, forward) => {
    // add the authorization to the headers
    const token = await tdStorage.getJWTToken();
    operation.setContext({
      headers: {
        Authorization: token || null,
        'preferred-language': (await tdStorage.get('language')) || 'en-US'
      }
    });
    return forward(operation);
  });
  const timeoutLink = new ApolloLinkTimeout(parseInt(APOLLO_TIMEOUT));
  apolloClient = new ApolloClient({
    link: from([authMiddleware, timeoutLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  });

  let language = (await tdStorage.get('language')) || 'en-US';
  const [lang] = language.split('-');
  dayjs.locale(lang === `zh` ? localeChinese : lang);
  if (Object.keys(messages).indexOf(language) === -1) {
    language = 'en-US';
    tdStorage.set('language', language);
  }
  i18n = createI18n({
    locale: `${language}`,
    fallbackLocale: 'en-US', // using EN as fallback Location
    messages,
    legacy: false,
    warnHtmlInMessage: 'off' // disable of the Detected HTML in message
  });

  // Hide the splash (you should do this on app launch)
  // SplashScreen.hide();

  // Show the splash for an indefinite amount of time:
  // SplashScreen.show({
  //   autoHide: false
  // });

  // Show the splash for two seconds and then automatically hide it:
  // SplashScreen.show({
  //   showDuration: 5000,
  //   autoHide: true
  // });

  if (VUE_APP_ENV !== 'development') {
    /** Sentry Interation */
    Sentry.init(tdStorage);
  }

  // only run auto update on mobile platform
  if (isNative) {
    configureDeploy();
  }

  Firebase.initialize();

  const app = createApp(App, {
    platforms: {
      ios: {
        scrollAssist: false,
        autoFocusAssist: false
      },
      android: {
        scrollAssist: false,
        autoFocusAssist: false
      }
    }
  })
    .provide(DefaultApolloClient, apolloClient)
    .use(store)
    .use(IonicVue)
    .use(router)
    .use(i18n)
    .use(VueTheMask)
    .use(VueGoogleMaps, {
      load: {
        key: VUE_APP_GOOGLEMAPS_KEY,
        libraries: 'places'
      }
    })
    .use(VueZoomer)
    .use(VueVideoPlayer);
  Object.keys(IonComponents).forEach((key) => {
    if (/^Ion[A-Z]\w+$/.test(key)) {
      app.component(key, IonComponents[key]);
    }
  });

  // register image component
  app.component('td-image', Image);

  app.use(VueVirtualScroller);

  // add ionic storage into vue global properties
  // app.config.globalProperties.$storage = tdStorage;
  app.provide('$storage', tdStorage);

  // define global ionstate variable
  app.config.globalProperties.$ionIsActive = true;

  // add global function into the app
  app.mixin({
    methods: globalFunction
  });

  // setting global variable
  if (isNative) {
    app.config.globalProperties.$browser = Browser;
    app.config.globalProperties.$Filesystem = Filesystem;
    app.config.globalProperties.$Directory = Directory;
  }

  router.isReady().then(async () => {
    await PushNotification.init(router);
    app.mount('#app');
    SplashScreen.hide();
    CapacitorApp.addListener('appStateChange', function (state) {
      try {
        Sentry.checkMemory(state.isActive);
      } catch (e) {
        console.log('Sentry not support: ', e.message);
      }
      // update ion state value
      app.config.globalProperties.$ionIsActive = state.isActive;
    });

    if (isNative) {
      // Init Branch.io
      Branch.init(tdStorage, router);

      // register clevertap in-app notification
      document.addEventListener(
        'onCleverTapInAppButtonClick',
        (e) => {
          if (e.customExtras?.url) {
            // dismiss all opening models before redirect to correct page
            const overlays = document.querySelectorAll(
              'ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast'
            );
            overlays.forEach((o) => o.dismiss());

            // redirect
            router.push(e.customExtras.url);
          }
        },
        false
      );
    }
  });
});

export { apolloClient, i18n };
