const FLUID_ICON = '/assets/icon/payment/fluid-icon.svg';

const PAYMENT_STATUS_ICONS = {
  PENDING: '/assets/icon/payment/warning-icon.gif',
  PENDING_WHITE: '/assets/icon/payment/info-icon.svg',
  SUCCESSFUL: '/assets/icon/payment/success-icon.gif',
  FAILED: '/assets/icon/payment/cross-icon.gif'
};

const TENANT_IDS = [1, 7];

const DEFAULT_CARD = {
  id: null,
  brand: '',
  exp_month: 0,
  exp_year: 0,
  country: '',
  funding: '',
  last4: '',
  fingerprint: '',
  card_type: ''
};

const DEFAULT_ORDER = {
  customerId: 0,
  standalone: false,
  deliveryAddressId: 0,
  billingAddressId: 0,
  cartItems: [],
  isMobile: true
};

const DEFAULT_PREORDER = {
  customerId: 0,
  standalone: false,
  deliveryAddressId: 0,
  billingAddressId: 0,
  preOrderItems: {}
};

const PAYMENT_METHOD = {
  PAYNOW: 'paynow',
  NETS: 'nets',
  STRIPE: 'stripe',
  FLUID: 'fluid'
};

const STRIPE_PAYMENT_STATUS = {
  SUCCESS: 'success',
  PENDING: 'pending',
  APPROVED: 'approved',
  FAILED: 'failed',
  REJECTED: 'rejected'
};

export {
  DEFAULT_CARD,
  DEFAULT_ORDER,
  DEFAULT_PREORDER,
  FLUID_ICON,
  PAYMENT_METHOD,
  PAYMENT_STATUS_ICONS,
  STRIPE_PAYMENT_STATUS,
  TENANT_IDS
};
