<template>
  <ion-page>
    <ion-header no-border class="bar-header" v-if="!otpMode">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-no-padding">{{ $t('reset_password') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true" class="content" v-if="!otpMode">
      <div id="home-container">
        <div class="logo-container"></div>
        <div class="flex">
          <ion-text class="title">{{ $t('forgot_your_password') }}</ion-text>
          <ion-text class="description">{{ $t('enter_your_mobile_number') }}</ion-text>
          <Form @submit="onNext" v-slot="{ meta }">
            <Field
              v-model="phoneNumber"
              name="phoneNumber"
              :label="$t('mobile_number')"
              :rules="{ required: true, phoneValidator: true }"
              v-slot="{ field, errorMessage }"
            >
              <ion-item
                class="box phone-number-group"
                lines="none"
                :class="{
                  error: errorMessage !== undefined
                }"
              >
                <ion-label class="hidden">{{ $t('country_code') }}</ion-label>
                <vue-tel-input
                  v-model="phoneNumber"
                  mode="national"
                  enabledCountryCode
                  enabledFlags
                  validCharactersOnly
                  ref="phone-field"
                  pattern="[0-9]*$"
                  styleClasses="no-border	no-box-shadow "
                  disabledFetchingCountry
                  :autoDefaultCountry="false"
                  :defaultCountry="defaultCountryCode"
                  :autoFormat="false"
                  @on-input="onlyNumber"
                  @country-changed="countryChange"
                  @keypress="blockDecimalInput"
                  :inputOptions="{
                    type: 'tel',
                    maxlength: 16,
                    placeholder: $t('mobile_number')
                  }"
                  :onlyCountries="getPreferedCountryCode()"
                  @open="handleOpenDropdown()"
                  @close="handleCloseDropdown()"
                />
                <input v-bind="field" type="tel" maxlength="16" style="display: none" />
              </ion-item>
            </Field>
            <Error-Message name="phoneNumber" class="error p-a" as="div" />
            <div class="button-groups t-m-2">
              <ion-button class="home-btn" expand="block" :disabled="!meta.valid" type="submit">
                <span>{{ $t('send_verification_code') }}</span>
              </ion-button>
              <ion-label class="signup-link"
                >{{ $t('dont_have_an_account') }}
                <router-link to="/shared/auth/signup-info"
                  ><strong>{{ $t('sign_up') }}</strong></router-link
                >
              </ion-label>
            </div>
          </Form>
        </div>
      </div>
    </ion-content>
    <Otp
      v-if="otpMode"
      :title="$t('reset_password')"
      :countryCode="countryCode"
      :phoneNumber="phoneNumber"
      :showError="otpError"
      @cancelOtp="
        otpMode = false;
        otpError = false;
      "
      @onSubmitOtpCode="forgotPasswordOtp"
      @resendCode="resendOtp"
    ></Otp>
    <ion-modal :is-open="isOtpSuspend">
      <otp-suspend @cancelOtp="isOtpSuspend = false" />
    </ion-modal>
    <ion-loading
      :is-open="isOpenLoadingref"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import Otp from '@/components/Otp';
import OtpSuspend from '@/components/OtpSuspend';
import { apolloClient } from '@/main';
import { getDefaultCountryCode, getPreferedCountryCode } from '@/modules/sale/services/libs/helper';
import { preferredCountryCode } from '@/services/shared/helper/home';
import { useQuery, useResult } from '@vue/apollo-composable';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { defineComponent, inject, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { countries, getUserByMobile } from '../../../../services/shared/graphql';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    Field,
    Form,
    ErrorMessage,
    Otp,
    OtpSuspend,
    VueTelInput
  },
  ionViewDidEnter() {
    this.resetState();
  },
  data() {
    return {
      isDropdown: false
    };
  },
  setup() {
    const storage = inject('$storage');
    const otpError = ref(false);
    const otpMode = ref(false);
    const isOtpSuspend = ref(false);
    const router = useRouter();
    const { result } = useQuery(countries);
    const allCountries = useResult(result, null, (data) => data.allCountries);
    const phoneNumber = ref('');
    const countryCode = ref(preferredCountryCode[0]);
    const defaultCountryCode = ref('SG');
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => {
      isOpenLoadingref.value = state;
    };

    const resetState = () => {
      if (phoneNumber.value) {
        defaultCountryCode.value = 'SG';
        phoneNumber.value = '';
        otpError.value = false;
        otpMode.value = false;
        countryCode.value = preferredCountryCode[0];
      }
    };
    const vform = reactive({});

    const onNext = async (values, actions) => {
      vform.values = values;
      vform.actions = actions;

      const fullPhoneNumber = `${parseInt(countryCode.value)}${vform.values.phoneNumber}`;
      if (fullPhoneNumber) {
        setOpenLoading(true);
        try {
          await apolloClient.query({
            query: getUserByMobile,
            variables: {
              mobile: fullPhoneNumber
            }
          });

          otpMode.value = true;
          setOpenLoading(false);
        } catch (error) {
          if (error.message.includes(`too many`)) {
            isOtpSuspend.value = true;
            setOpenLoading(false);
          } else {
            setOpenLoading(false);
            vform.actions.setFieldError('phoneNumber', error.message);
          }
        }
      }
    };
    const phoneMask = '#### ####';

    const forgotPasswordOtp = async (otp) => {
      const phoneNumber = `${parseInt(countryCode.value)}${vform.values.phoneNumber}`;
      try {
        setOpenLoading(true);
        const { data } = await apolloClient.query({
          query: getUserByMobile,
          variables: {
            mobile: phoneNumber,
            otp: otp
          }
        });
        // set reset user into storage
        await storage.setUserResetPass({
          email: data.getUserByMobile.email,
          userId: data.getUserByMobile.id
        });
        router.push({
          name: 'ResetPassword'
        });
        setOpenLoading(false);
      } catch (error) {
        if (error.message.includes(`too many`)) {
          isOtpSuspend.value = true;
          setOpenLoading(false);
        } else {
          setOpenLoading(false);
          otpError.value = true;
        }
      }
    };

    const resendOtp = async () => {
      const fullPhoneNumber = `${parseInt(countryCode.value)}${vform.values.phoneNumber}`;
      if (fullPhoneNumber) {
        setOpenLoading(true);
        try {
          await apolloClient.query({
            query: getUserByMobile,
            variables: {
              mobile: fullPhoneNumber
            }
          });
        } catch (error) {
          if (error.message.includes(`too many`)) {
            isOtpSuspend.value = true;
            setOpenLoading(false);
          } else {
            setOpenLoading(false);
            otpError.value = true;
          }
        }
      }
    };

    return {
      otpMode,
      otpError,
      countryCode,
      phoneNumber,
      preferredCountryCode,
      allCountries,
      onNext,
      phoneMask,
      forgotPasswordOtp,
      getPreferedCountryCode,
      defaultCountryCode,
      resetState,
      isOpenLoadingref,
      setOpenLoading,
      resendOtp,
      isOtpSuspend
    };
  },
  mounted() {
    this.$refs['phone-field'].choose('SG');
    this.$refs['phone-field'].focus();
  },
  methods: {
    onlyNumber(val) {
      // Get the current input value
      let input = val.toString();

      // Remove any leading zeros and decimal point
      input = input.replace(/^0+|^,|^\.+/g, '');

      // Remove any non-numeric characters
      input = input.replace(/[^\d.,]/g, '');
      input = input.replace(/[.,]/g, '');

      this.phoneNumber = input;
    },

    blockDecimalInput(event) {
      // block decimal and only allow number
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    countryChange(val) {
      this.defaultCountryCode = val.iso2;
      this.countryCode = val.dialCode || getDefaultCountryCode();
      this.$refs['phone-field'].focus();
    },
    handleOpenDropdown() {
      this.isDropdown = true;
    },
    handleCloseDropdown() {
      this.isDropdown = false;
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/views/Home/home.scss';
@import 'ForgotPassword.scss';
ion-select::part(icon) {
  background-repeat: no-repeat;
  width: 18px;
}
ion-select {
  margin-left: 10px;
  color: #9ca3af !important;
}
ion-select ~ .phone-number {
  margin-left: 4px;
}
</style>
