<template>
  <ion-page>
    <ion-header no-border class="bar-header">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/" @click="$emit('cancelOtp')"></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-no-padding">{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true" class="content">
      <div id="home-container">
        <div class="logo-container"></div>
        <div class="flex">
          <ion-text class="title">{{ $t('verify_your_number') }}</ion-text>
          <ion-text
            class="description"
            v-html="$t('please_enter_verification_code', { countryCode: countryCode, number: phoneNumber })"
          ></ion-text>
          <div ref="digitGroup" class="digit-group">
            <input
              :class="{ error: showError || showErrorSignUp }"
              type="tel"
              id="digit-4"
              name="digit-4"
              ref="forcusInput"
              data-previous="digit-3"
              v-model="otpCode"
              maxlength="4"
              oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
            />
          </div>
          <div ref="errorDiv" class="error">
            {{ showError || showErrorSignUp ? $t('incorrect_verification_code') : '' }}
          </div>
          <ion-label class="get-code"
            >{{ $t('get_code') }} <strong @click="resendCode">{{ resendCodeText }}</strong></ion-label
          >
        </div>
      </div>
    </ion-content>
    <ion-toast
      :is-open="openToast"
      mode="ios"
      color="dark"
      :message="toastMsg"
      :duration="2000"
      position="top"
    >
    </ion-toast>
  </ion-page>
</template>

<script>
import { apolloClient } from '@/main';
import { checkUser } from '@/services/shared/graphql';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

dayjs.extend(duration);
dayjs.extend(relativeTime);

// Credits to johnhoulder for the OTP template.
// https://codepen.io/johnhoulder/pen/VOKJPo

export default defineComponent({
  name: 'Otp',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonPage,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonLabel,
    IonText
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    countryCode: {
      type: String,
      default: ''
    },
    phoneNumber: {
      type: String,
      default: ''
    },
    smsOtp: {
      type: String,
      default: ''
    },
    showError: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    },
    mobile: {
      type: String,
      default: ''
    },
    openToast: {
      type: Boolean,
      default: false
    },
    toastMsg: {
      type: String,
      default: ''
    }
  },
  emits: ['cancelOtp', 'resendCode', 'onSubmitOtpCode', 'hiddenError', 'otpSuspend'],
  setup(props, { emit }) {
    const digitGroup = ref(null);
    const { t } = useI18n();
    const resendCodeDuration = computed(() => {
      return dayjs.duration(duration.value * 1000).format('mm:ss');
    });
    const resendCodeText = computed(() => {
      return duration.value > 0
        ? t('resend_code_in', { duration: resendCodeDuration.value })
        : t('resend_code');
    });
    const duration = ref(59);
    const isSubmitting = ref(false);
    watch(
      () => props.showError,
      (current) => {
        if (current) {
          isSubmitting.value = false;
        }
      }
    );
    const resendCodeFn = () => {
      duration.value--;
      if (duration.value <= 0) {
        clearInterval(resendCodeInterval);
      }
    };
    let resendCodeInterval = setInterval(resendCodeFn, 1000);
    const resendCode = () => {
      if (duration.value <= 0) {
        duration.value = 59;
        resendCodeInterval = setInterval(resendCodeFn, 1000);
        const number = props.countryCode + props.phoneNumber;
        emit('resendCode', number);
      }
    };
    return {
      digitGroup,
      resendCode,
      resendCodeDuration,
      resendCodeText
    };
  },
  data() {
    return {
      otpCode: '',
      showErrorSignUp: false
    };
  },
  methods: {
    onSubmitOtpCode() {
      this.$emit('onSubmitOtpCode', this.otpCode);
    },
    async handleSignUpOtp() {
      try {
        const response = await apolloClient.query({
          query: checkUser,
          variables: {
            email: this.email,
            mobile: this.mobile,
            otp: this.otpCode
          }
        });
        if (response.errors) {
          this.showErrorSignUp = true;
        } else {
          this.$emit('onSubmitOtpCode', response);
        }
      } catch (error) {
        if (error.message.includes(`too many`)) {
          this.$emit('otpSuspend', error);
        } else {
          this.showErrorSignUp = true;
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.forcusInput.focus();
    }, 500);
  },
  unmounted() {
    this.$emit('cancelOtp');
  },
  watch: {
    otpCode() {
      if (this.otpCode.length === 4) {
        if (this.$router.currentRoute._value.href === '/shared/auth/signup-info') {
          this.handleSignUpOtp();
        } else {
          this.onSubmitOtpCode();
        }
      } else {
        this.$emit('hiddenError');
        this.showErrorSignUp = false;
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/views/Home/home.scss';
@import 'Otp.scss';
</style>
