import { STATUS_CODE_MESSAGE } from '@/services/shared/helper/constants.js';
export const getMessage = (error) => {
  const graphqlError = [...error.graphQLErrors].shift();
  const code = graphqlError.extensions?.exception?.code || graphqlError.extensions?.code;
  const message = STATUS_CODE_MESSAGE().find((item) => item.key === code);
  return message
    ? {
        message: message.value
      }
    : error;
};
