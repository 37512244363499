/** https://en.wikipedia.org/wiki/Language_localisation for more country code */
import en from './lang/en-us';
import cn from './lang/zh-cn';
import ms from './lang/ms-MY';
import id from './lang/id-ID';
export default {
  'en-US': en,
  'zh-CN': cn,
  'ms-MY': ms,
  'id-ID': id
};
