import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const state = {
  version: '',
  maintenance: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
