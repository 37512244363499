import { apolloClient } from '@/main';
import { addAddress, getB2bAddresses, updateAddress } from '@/modules/b2b/services/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  ADD_ADDRESS: 'ADD_ADDRESS',
  GET_ADDRESSES: 'GET_ADDRESSES',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS'
};

export default {
  // update address
  async [ACTIONS.UPDATE_ADDRESS]({ commit }, params) {
    commit(MUTATIONS.UPDATE_ADDRESS_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: updateAddress,
        variables: params
      });
      commit(MUTATIONS.UPDATE_ADDRESS_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.UPDATE_ADDRESS_FAILURE, error);
    }
  },

  // add address
  async [ACTIONS.ADD_ADDRESS]({ commit }, params) {
    commit(MUTATIONS.ADD_ADDRESS_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: addAddress,
        variables: params
      });
      commit(MUTATIONS.ADD_ADDRESS_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.ADD_ADDRESS_FAILURE, error);
    }
  },

  // get addresses
  async [ACTIONS.GET_ADDRESSES]({ commit }, params) {
    commit(MUTATIONS.GET_ADDRESSES_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getB2bAddresses,
        variables: params
      });
      commit(MUTATIONS.GET_ADDRESSES_SUCCESS, data.getB2bAddresses);
    } catch (error) {
      commit(MUTATIONS.GET_ADDRESSES_FAILURE, error);
    }
  }
};
