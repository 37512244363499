import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const state = {
  version: ''
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
