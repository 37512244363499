import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import * as Sentry from '@sentry/capacitor';
import { Integrations } from '@sentry/tracing';

const {
  VUE_APP_ENV,
  VUE_APP_SENTRY_DSN_URL,
  VUE_APP_SENTRY_RELEASE,
  VUE_APP_SENTRY_DIST,
  VUE_APP_SENTRY_TRACE_RATE,
  MAX_MEMORY_LIMIT = 150
} = process.env;

let storage = null;

// Check if the apps is running on native
const isNative = Capacitor.isNativePlatform();

// Checking the environment
const isEnabled = () => ['testing', 'staging', 'production'].includes(VUE_APP_ENV);

export function init(TDStorage) {
  if (!isEnabled()) return;
  if (!isNative) return;

  // storage initiation, for checkMemory(), we need the user id to set the user_id tag
  storage = TDStorage;

  Sentry.init({
    dsn: VUE_APP_SENTRY_DSN_URL,
    integrations: [new Integrations.BrowserTracing()],
    release: VUE_APP_SENTRY_RELEASE,
    dist: VUE_APP_SENTRY_DIST,
    environment: VUE_APP_ENV,
    sampleRate: VUE_APP_SENTRY_TRACE_RATE
  });
}

/**
 *
 * @param {*} isActive if isActive is true, means the process is on the foreground
 *
 */
export async function checkMemory(isActive) {
  if (!isEnabled()) return;
  if (!isNative) return;
  try {
    const deviceInfo = await Device.getInfo();
    const usedMemory = Math.ceil(deviceInfo.memUsed / 1048576);

    if (MAX_MEMORY_LIMIT > usedMemory) return;
    const user = await storage.getUser();

    Sentry.withScope((scope) => {
      if (user) scope.setTag(`user_id`, user.id);
      scope.setTag(`is_background`, !isActive);
      scope.setTag(`memory_used`, `${usedMemory} MB`);
      scope.setLevel(`warning`);
      Sentry.captureException(new Error(`Memory Warning`));
    });
  } catch (error) {
    console.log(`sentry check memory error`, error);
  }
}
