import { MUTATIONS } from './mutations';
import { apolloClient } from '@/main';
import { getCategories } from '@/modules/sale/services/graphql';

export const ACTIONS = {
  GET_CATEGORIES: 'GET_CATEGORIES'
};

export default {
  async [ACTIONS.GET_CATEGORIES]({ commit }) {
    commit(MUTATIONS.GET_CATEGORIES_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getCategories,
        variables: {
          active: true
        }
      });
      commit(MUTATIONS.GET_CATEGORIES_SUCCESS, data.getCategories);
    } catch (error) {
      commit(MUTATIONS.GET_CATEGORIES_FAILURE, error);
    }
  }
};
