<template>
  <ion-alert
    mode="ios"
    :is-open="isOpenAlert"
    :header="header ? 'Alert' : ''"
    :message="message"
    css-class="my-custom-class"
    :buttons="withAction ? btnAction : btnNoAction"
  >
  </ion-alert>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
  emits: ['closeAlert'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const btnNoAction = [t('OK')];
    const btnAction = [
      {
        text: t('OK'),
        handler: () => {
          emit('closeAlert');
        }
      }
    ];
    return { btnNoAction, btnAction };
  },
  props: {
    withAction: {
      type: Boolean,
      default: false
    },
    isOpenAlert: {
      type: Boolean,
      default: true
    },
    message: {
      type: String
    },
    header: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
