import { FAILURE, REQUEST, SUCCESS } from '@/modules/b2b/services/libs/state';

export const MUTATIONS = {
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',
  GET_VIEWS_QUOTATIONS_REQUEST: 'GET_VIEWS_QUOTATIONS_REQUEST',
  GET_VIEWS_QUOTATIONS_SUCCESS: 'GET_VIEWS_QUOTATIONS_SUCCESS',
  GET_VIEWS_QUOTATIONS_FAILURE: 'GET_VIEWS_QUOTATIONS_FAILURE',
  UPDATE_QUERY_PARAMS_QUOTATIONS: 'UPDATE_QUERY_PARAMS_QUOTATIONS',
  UPDATE_SUB_BUYER_REQUEST: 'UPDATE_SUB_BUYER_REQUEST',
  UPDATE_SUB_BUYER_SUCCESS: 'UPDATE_SUB_BUYER_SUCCESS',
  UPDATE_SUB_BUYER_FAILURE: 'UPDATE_SUB_BUYER_FAILURE',
  GET_VIEWS_QUOTATIONS_DETAIL_REQUEST: 'GET_VIEWS_QUOTATIONS_DETAIL_REQUEST',
  GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS: 'GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS',
  GET_VIEWS_QUOTATIONS_DETAIL_FAILURE: 'GET_VIEWS_QUOTATIONS_DETAIL_FAILURE',
  MARK_ALL_AS_READ_NOTIFICATIONS_SUCCESS: 'MARK_ALL_AS_READ_NOTIFICATIONS_SUCCESS',
  MARK_ALL_AS_READ_NOTIFICATIONS_FAILURE: 'MARK_ALL_AS_READ_NOTIFICATIONS_FAILURE',
  RESET_QUERY_PARAMS: 'RESET_QUERY_PARAMS',

  GET_PENDING_QUOTATION_LIST_REQUEST: 'GET_PENDING_QUOTATION_LIST_REQUEST',
  GET_PENDING_QUOTATION_LIST_SUCCESS: 'GET_PENDING_QUOTATION_LIST_SUCCESS',
  GET_PENDING_QUOTATION_LIST_FAILURE: 'GET_PENDING_QUOTATION_LIST_FAILURE',

  GET_APPROVED_QUOTATION_LIST_REQUEST: 'GET_APPROVED_QUOTATION_LIST_REQUEST',
  GET_APPROVED_QUOTATION_LIST_SUCCESS: 'GET_APPROVED_QUOTATION_LIST_SUCCESS',
  GET_APPROVED_QUOTATION_LIST_FAILURE: 'GET_APPROVED_QUOTATION_LIST_FAILURE',

  GET_REJECTED_QUOTATION_LIST_REQUEST: 'GET_REJECTED_QUOTATION_LIST_REQUEST',
  GET_REJECTED_QUOTATION_LIST_SUCCESS: 'GET_REJECTED_QUOTATION_LIST_SUCCESS',
  GET_REJECTED_QUOTATION_LIST_FAILURE: 'GET_REJECTED_QUOTATION_LIST_FAILURE',

  SET_BADGE_NOTIFICATION_REQUEST: 'SET_BADGE_NOTIFICATION_REQUEST',
  SET_BADGE_NOTIFICATION_SUCCESS: 'SET_BADGE_NOTIFICATION_SUCCESS',
  SET_BADGE_NOTIFICATION_FAILED: 'SET_BADGE_NOTIFICATION_FAILED'
};

export default {
  [MUTATIONS.GET_NOTIFICATIONS_REQUEST](state, isLoadMore) {
    REQUEST(state);
    state.isLoadMore = isLoadMore;
  },
  [MUTATIONS.GET_NOTIFICATIONS_SUCCESS](state, { notificationsLists, notificationsBadges }) {
    SUCCESS(state);
    if (state.isLoadMore) {
      const data = [...state.notificationsLists.data, ...notificationsLists.data];
      state.notificationsLists = {
        ...notificationsLists,
        data
      };
    } else {
      state.notificationsLists = notificationsLists;
      state.notificationsBadges = notificationsBadges;
    }
  },
  [MUTATIONS.GET_NOTIFICATIONS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.UPDATE_QUERY_PARAMS](state, { params }) {
    state.params = {
      ...state.params,
      ...params
    };
  },
  [MUTATIONS.RESET_QUERY_PARAMS](state, { params }) {
    state.params = {
      ...params
    };
  },

  [MUTATIONS.UPDATE_SUB_BUYER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_SUB_BUYER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.UPDATE_SUB_BUYER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS](state, { quotationDetailData }) {
    SUCCESS(state);
    state.quotationDetailData = quotationDetailData.data[0];
  },
  [MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.MARK_ALL_AS_READ_NOTIFICATIONS_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.MARK_ALL_AS_READ_NOTIFICATIONS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // quotations

  [MUTATIONS.GET_PENDING_QUOTATION_LIST_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_PENDING_QUOTATION_LIST_SUCCESS](state, { quotationsPending }) {
    SUCCESS(state);
    state.quotationsPending = quotationsPending;
  },
  [MUTATIONS.GET_PENDING_QUOTATION_LIST_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.GET_APPROVED_QUOTATION_LIST_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_APPROVED_QUOTATION_LIST_SUCCESS](state, { quotationsApproved }) {
    SUCCESS(state);
    state.quotationsApproved = quotationsApproved;
  },
  [MUTATIONS.GET_APPROVED_QUOTATION_LIST_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.GET_REJECTED_QUOTATION_LIST_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_REJECTED_QUOTATION_LIST_SUCCESS](state, { quotationsRejected }) {
    SUCCESS(state);
    state.quotationsRejected = quotationsRejected;
  },
  [MUTATIONS.GET_REJECTED_QUOTATION_LIST_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update badge only
  [MUTATIONS.SET_BADGE_NOTIFICATION_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.SET_BADGE_NOTIFICATION_SUCCESS](state, { notificationsBadges }) {
    SUCCESS(state);
    state.notificationsBadges = notificationsBadges;
  },
  [MUTATIONS.SET_BADGE_NOTIFICATION_FAILED](state, error) {
    FAILURE(state, error);
  }
};
