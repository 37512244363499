export const customerGuard = async (to, from, next) => {
  const selectedCompany = await from.meta.$storage.getSelectedCompany();

  if (selectedCompany && to.name === 'select-customer') {
    // next('/b2b/main');
    next();
  } else if (to.name !== 'select-customer' && !selectedCompany) {
    next('/b2b/select-customer');
  } else {
    next();
  }
};
