import { DEFAULT_GETTERS } from '@/modules/sale/constants';

export default {
  ...DEFAULT_GETTERS,
  cardList: (state) => state.listCustomerCard?.data || [],
  defaultCardId: (state) => state.defaultCardId,
  removeStripeCard: (state) => state.removeStripeCard,
  saleListStripeCard: (state) => state.saleListStripeCard,
  listInvoices: (state) => state.listInvoices || [],
  totalCountInvoices: (state) => state.totalCountInvoices,
  xeroCustomer: (state) => state.xeroCustomer,
  totalUnpaidInvoices: (state) => state.totalUnpaidInvoices,
  totalOverdueInvoices: (state) => state.totalOverdueInvoices,
  paymentSummary: (state) => state.paymentSummary,
  selectedPaymentInvoices: (state) => state.selectedPaymentInvoices,
  selectedPaymentMethod: (state) => state.selectedPaymentMethod,
  isFinanceModuleEnabled: (state) => state.isFinanceModuleEnabled,
  paymentHistory: (state) => state.paymentHistory,
  paymentHistoryList: (state) => state.paymentHistoryList || [],
  cancelManualPayment: (state) => state.cancelManualPayment,
  isLoadingCancellingPayment: (state) => state.isLoadingCancellingPayment,
  paramsTopUpCredit: (state) => state.paramsTopUpCredit,
  paymentSummaryTopUp: (state) => state.paymentSummaryTopUp,
  topUpCreditEntry: (state) => state.topUpCreditEntry,
  paymentInvoiceDetails: (state) => state.paymentInvoiceDetails
};
