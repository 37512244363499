export const STORAGE_KEYS = {
  USER_INFO: '8^$:.BBpP4fkWW+r',
  USER_RESET_PASS: '3C8MDNWjOjhlIQ==',
  JWT_TOKEN: 'N4%=!Ub9K9ZT-wc"',
  SELECTED_COMPANY: 'y=~~{^mduTTZ55C>',
  SELECT_CUSTOMER: '2L8A05bWIZEoAjRz',
  SELECTED_DELIVERY_ADDRESS: 'C37oBqMud6JwykjH',
  SELECTED_BILLING_ADDRESS: 'fn2Sybe07SePcGhR',
  SKIPPED_ON_BOARDING: 'LuJf3zWvtAEBuesu',
  BACK_CHANGE_COMPANY: 'qbcYpC22#B^WLF?D',
  PAYMENT_KEY_TMP: 'DgoKDrNdm7ykI6K5',
  SET_COUNT_DOWN_TIME: 'kaj&m0ax#$kan?SN',
  CURRENT_LOCATION: '{N)k%9-vWufH!R+d',
  COMPANIE_INREVIEW_LIST: 'MkYTDcS9BLfdvugg',
  DISABLE_NATIVE_BACK_BUTTON: 'XPDq*fLs7jpzr9Dy'
};

const IGNORE_LOGOUT_KEYS = ['SKIPPED_ON_BOARDING'];
class TDStorage {
  constructor(ionicStorage) {
    this.$storage = ionicStorage;
  }

  // wrap basic function
  get(key) {
    return this.$storage.get(key);
  }

  set(key, value) {
    return this.$storage.set(key, value);
  }

  remove(key) {
    return this.$storage.remove(key);
  }

  getJWTToken() {
    return this.get(STORAGE_KEYS.JWT_TOKEN);
  }

  getUser() {
    return this.get(STORAGE_KEYS.USER_INFO);
  }

  setUser(value) {
    // set jwt token
    this.set(STORAGE_KEYS.JWT_TOKEN, value.tokenJWT);

    // set user info
    this.set(STORAGE_KEYS.USER_INFO, value);
  }

  async getUserResetPass() {
    const userResetPass = await this.get(STORAGE_KEYS.USER_RESET_PASS);
    this.remove(STORAGE_KEYS.USER_RESET_PASS);
    return userResetPass;
  }

  setUserResetPass(value) {
    this.set(STORAGE_KEYS.USER_RESET_PASS, value);
  }

  getSelectedCompany() {
    return this.get(STORAGE_KEYS.SELECTED_COMPANY);
  }

  setSelectedCompany(value) {
    this.set(STORAGE_KEYS.SELECTED_COMPANY, JSON.parse(JSON.stringify(value)));
  }

  getSelectCustomer() {
    return this.get(STORAGE_KEYS.SELECT_CUSTOMER);
  }

  setSelectedCustomer(value) {
    this.set(STORAGE_KEYS.SELECT_CUSTOMER, JSON.parse(JSON.stringify(value)));
  }

  setCountDownTime(value) {
    this.set(STORAGE_KEYS.SET_COUNT_DOWN_TIME, JSON.parse(JSON.stringify(value)));
  }

  getCountDownTime() {
    return this.get(STORAGE_KEYS.SET_COUNT_DOWN_TIME);
  }

  removeCountDownTime() {
    return this.remove(STORAGE_KEYS.SET_COUNT_DOWN_TIME);
  }
  // close modal select company
  getBackChangeCompany() {
    return this.get(STORAGE_KEYS.BACK_CHANGE_COMPANY);
  }
  setBackChangeCompany(value) {
    this.set(STORAGE_KEYS.BACK_CHANGE_COMPANY, value);
  }

  // delivery address
  getSelectedDeliveryAddress() {
    return this.get(STORAGE_KEYS.SELECTED_DELIVERY_ADDRESS);
  }
  setSelectedDeliveryAddress(value) {
    this.set(STORAGE_KEYS.SELECTED_DELIVERY_ADDRESS, value);
  }

  // billing address
  getSelectedBillingAddress() {
    return this.get(STORAGE_KEYS.SELECTED_BILLING_ADDRESS);
  }
  setSelectedBillingAddress(value) {
    this.set(STORAGE_KEYS.SELECTED_BILLING_ADDRESS, value);
  }

  setCurrentLocation(value) {
    this.set(STORAGE_KEYS.CURRENT_LOCATION, value);
  }

  getCurrentLocation() {
    return this.get(STORAGE_KEYS.CURRENT_LOCATION);
  }

  // skipped on boarding
  getSkippedOnBoarding() {
    return this.get(STORAGE_KEYS.SKIPPED_ON_BOARDING);
  }

  setSkippedOnBoarding() {
    this.set(STORAGE_KEYS.SKIPPED_ON_BOARDING, true);
  }

  async getPaymentInfo() {
    // get payment key into
    const paymentInfo = await this.get(STORAGE_KEYS.PAYMENT_KEY_TMP);

    // remove payment info
    await this.remove(STORAGE_KEYS.PAYMENT_KEY_TMP);
    return paymentInfo;
  }

  setPaymentInfo(value) {
    // set jwt token
    return this.set(STORAGE_KEYS.PAYMENT_KEY_TMP, value);
  }

  // delivery address
  async getCompanyReviewList() {
    const companyInreviewList = await this.get(STORAGE_KEYS.COMPANIE_INREVIEW_LIST);
    return companyInreviewList ? JSON.parse(companyInreviewList) : undefined;
  }
  setCompanyReviewList(value) {
    this.set(STORAGE_KEYS.COMPANIE_INREVIEW_LIST, JSON.stringify(value));
  }

  async getDisableNativeBackButton() {
    return this.get(STORAGE_KEYS.DISABLE_NATIVE_BACK_BUTTON);
  }

  async setDisableNativeBackButton(value) {
    return this.set(STORAGE_KEYS.DISABLE_NATIVE_BACK_BUTTON, value);
  }

  async clearStorage() {
    const storageKeys = Object.keys(STORAGE_KEYS);
    storageKeys.forEach((key) => {
      if (IGNORE_LOGOUT_KEYS.indexOf(key) === -1) {
        // only remove the key not in ignore list
        this.$storage.remove(STORAGE_KEYS[key]);
      }
    });
  }
}

export default TDStorage;
