export const CUSTOMER_REQUEST = {
  id: 1,
  message: "Customer's Request"
};
export const OTHER = {
  id: 3,
  message: 'Other - '
};
export const OUT_OF_STOCK = {
  id: 2,
  message: 'Out of Stock'
};
export const REASON_TYPE = [CUSTOMER_REQUEST, OTHER, OUT_OF_STOCK];

export const CARD_IMAGE_BRAND = {
  'DINERS CLUB': '/assets/icon/card/diners.svg',
  DISCOVER: '/assets/icon/card/discover.svg',
  UNIONPAY: '/assets/icon/card/unionpay.svg',
  VISA: '/assets/icon/card/visa.svg',
  MASTERCARD: '/assets/icon/card/mastercard.svg',
  'AMERICAN EXPRESS': '/assets/icon/card/amex.svg',
  JCB: '/assets/icon/card/jcb.svg',
  PAYNOW: '/assets/icon/card/paynow.svg',
  DEFAULT: '/assets/icon/card/generic.svg',
  FLUID: '/assets/icon/payment/fluid-icon.svg'
};

/** stripe only support this brand */
export const CARD_TYPE_REGEX = {
  UnionPay: /^(62|88)\d+$/,
  Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  MasterCard: /^5[1-5][0-9]{14}$/,
  'American Express': /^3[47][0-9]{13}$/,
  'Diners Club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  JCB: /^(?:2131|1800|35\d{3})\d{11}$/
};

export const TL_TASK_STATUSES_ID = {
  UNASSIGNED: 1,
  ASSIGNED: 2,
  ARRIVED_OR_IN_PROGRESS: 3,
  COMPLETED: 4,
  FAILED: 5,
  CANCELLED: 6,
  STARTED: 7,
  ACCEPTED: 8,
  DECLINE: 9,
  DELETED: 10,
  PARTIALLY_COMPLETE: 11
};
