import { DEFAULT_GETTERS } from '@/modules/b2b/constants';

export default {
  ...DEFAULT_GETTERS,
  cart: (state) => state.cart,
  checkDuplicateOrder: (state) => state.checkDuplicateOrder,
  finishOrder: (state) => state.finishOrder,
  isRedirect3DSecure: (state) => state.isRedirect3DSecure,
  paymentOrderId: (state) => state.paymentOrderId
};
