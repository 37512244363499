import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export const useConfig = () => {
  const { t } = useI18n();
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const storage = inject('$storage');
  const isMerchantUser = router.currentRoute.value.path.startsWith('/b2b/');
  const currentRoutePath = router.currentRoute.value.path;
  const storePath = isMerchantUser ? `b2b` : `sale`;
  return {
    t,
    route,
    currentRoutePath,
    isMerchantUser,
    storePath,
    store,
    router,
    storage
  };
};

export const useGetStorageData = () => {
  const storage = inject('$storage');
  const selectedUser = ref(storage.getUser());
  const selectedCompany = ref(storage.getSelectedCompany());
  const selectedLanguage = ref(storage.get('language'));
  return {
    selectedUser,
    selectedCompany,
    selectedLanguage
  };
};
