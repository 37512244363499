import { apolloClient } from '@/main';
import {
  getUpdateBadgeNotifications,
  getViewsUserNotifications,
  readAllUserNotificationsB2b,
  rejectQuotation,
  saleApproveQuotation,
  saleGetQuotationsDetail,
  saleGetViewQuotations,
  updateUserNotification
} from '@/modules/sale/services/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  LOAD_MORE_NOTIFICATION: 'LOAD_MORE_NOTIFICATION',

  GET_VIEWS_QUOTATIONS_DETAIL: 'GET_VIEWS_QUOTATIONS_DETAIL',

  UPDATE_SUB_BUYER: 'UPDATE_SUB_BUYER',
  SALE_APPROVE_QUOTATION: 'SALE_APPROVE_QUOTATION',
  SALE_REJECT_QUOTATION: 'SALE_REJECT_QUOTATION',
  GET_BADGE_NUMBERS: 'GET_BADGE_NUMBERS',

  MARK_ALL_AS_READ_NOTIFICATIONS: 'MARK_ALL_AS_READ_NOTIFICATIONS',

  RESET_QUERY_PARAMS: 'RESET_QUERY_PARAMS',

  GET_PENDING_QUOTATION_LIST: 'GET_PENDING_QUOTATION_LIST',
  GET_APPROVED_QUOTATION_LIST: ' GET_APPROVED_QUOTATION_LIST',
  GET_REJECTED_QUOTATION_LIST: 'GET_REJECTED_QUOTATION_LIST',

  UPDATE_BADGE_NOTIFICATION: 'UPDATE_BADGE_NOTIFICATION'
};

export default {
  async [ACTIONS.GET_NOTIFICATIONS]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_NOTIFICATIONS_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsUserNotifications,
        variables: {
          ...state.params,
          ...params
        }
      });
      if (state.params.isIncludeTotalCount) state.totalCount = data.saleGetViewsUserNotifications.totalCount;
      if (onFinish) onFinish(data.saleGetViewsUserNotifications);
      commit(MUTATIONS.GET_NOTIFICATIONS_SUCCESS, {
        notificationsLists: data.saleGetViewsUserNotifications,
        notificationsBadges: data.getViewsUserNotificationBadges
      });
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.GET_NOTIFICATIONS_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_NOTIFICATION]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_NOTIFICATIONS, { isLoadMore, onFinish });
  },

  async [ACTIONS.UPDATE_BADGE_NOTIFICATION]({ commit }) {
    commit(MUTATIONS.SET_BADGE_NOTIFICATION_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getUpdateBadgeNotifications
      });
      commit(MUTATIONS.SET_BADGE_NOTIFICATION_SUCCESS, {
        notificationsBadges: data.getViewsUserNotificationBadges
      });
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.SET_BADGE_NOTIFICATION_FAILED, error);
    }
  },

  async [ACTIONS.UPDATE_SUB_BUYER]({ commit, dispatch }, params) {
    commit(MUTATIONS.UPDATE_SUB_BUYER_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: updateUserNotification,
        variables: params
      });
      await dispatch(ACTIONS.UPDATE_BADGE_NOTIFICATION);
      commit(MUTATIONS.UPDATE_SUB_BUYER_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.UPDATE_SUB_BUYER_FAILURE, error);
    }
  },

  async [ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]({ commit }, params) {
    commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: saleGetQuotationsDetail,
        variables: params
      });

      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS, { list: data.saleGetQuotationsDetail });
    } catch (error) {
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_FAILURE, error);
    }
  },

  async [ACTIONS.SALE_APPROVE_QUOTATION]({ commit }, params) {
    commit(MUTATIONS.SALE_APPROVE_QUOTATION_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleApproveQuotation,
        variables: params
      });

      commit(MUTATIONS.SALE_APPROVE_QUOTATION_SUCCESS, {
        data: data.saleApproveQuotation,
        quotationId: params.quotationId
      });
    } catch (error) {
      commit(MUTATIONS.SALE_APPROVE_QUOTATION_FAILURE, error);
    }
  },

  async [ACTIONS.SALE_REJECT_QUOTATION]({ commit }, params) {
    commit(MUTATIONS.SALE_REJECT_QUOTATION_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: rejectQuotation,
        variables: params
      });

      commit(MUTATIONS.SALE_REJECT_QUOTATION_SUCCESS, {
        data: data.rejectQuotation,
        quotationId: params.quotationId
      });
    } catch (error) {
      commit(MUTATIONS.SALE_REJECT_QUOTATION_FAILURE, error);
    }
  },

  async [ACTIONS.MARK_ALL_AS_READ_NOTIFICATIONS]({ commit, dispatch }) {
    try {
      await apolloClient.mutate({
        mutation: readAllUserNotificationsB2b,
        variables: {}
      });
      await dispatch(ACTIONS.UPDATE_BADGE_NOTIFICATION);
      commit(MUTATIONS.MARK_ALL_AS_READ_NOTIFICATIONS_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.MARK_ALL_AS_READ_NOTIFICATIONS_FAILURE, error);
    }
  },

  async [ACTIONS.RESET_QUERY_PARAMS]({ commit }) {
    commit(MUTATIONS.RESET_QUERY_PARAMS, {
      params: {
        isIncludeTotalCount: true,
        limit: 20,
        offset: 0
      }
    });
  },

  // quotations
  async [ACTIONS.GET_PENDING_QUOTATION_LIST]({ commit }, { params, isLoadMore = false }) {
    commit(MUTATIONS.GET_PENDING_QUOTATION_LIST_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: saleGetViewQuotations,
        variables: {
          ...params,
          status: `PENDING`,
          addPendingAndReceivedQuotation: false
        }
      });

      commit(MUTATIONS.GET_PENDING_QUOTATION_LIST_SUCCESS, {
        quotationsPending: data.saleGetQuotations
      });
    } catch (error) {
      commit(MUTATIONS.GET_PENDING_QUOTATION_LIST_FAILURE, error);
    }
  },

  async [ACTIONS.GET_APPROVED_QUOTATION_LIST]({ commit }, { params, isLoadMore = false }) {
    commit(MUTATIONS.GET_APPROVED_QUOTATION_LIST_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: saleGetViewQuotations,
        variables: {
          ...params,
          status: `APPROVED`,
          addPendingAndReceivedQuotation: false
        }
      });

      commit(MUTATIONS.GET_APPROVED_QUOTATION_LIST_SUCCESS, {
        quotationsApproved: data.saleGetQuotations
      });
    } catch (error) {
      commit(MUTATIONS.GET_APPROVED_QUOTATION_LIST_FAILURE, error);
    }
  },

  async [ACTIONS.GET_REJECTED_QUOTATION_LIST]({ commit }, { params, isLoadMore = false }) {
    commit(MUTATIONS.GET_REJECTED_QUOTATION_LIST_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: saleGetViewQuotations,
        variables: {
          ...params,
          status: `REJECTED`,
          addPendingAndReceivedQuotation: false
        }
      });

      commit(MUTATIONS.GET_REJECTED_QUOTATION_LIST_SUCCESS, {
        quotationsRejected: data.saleGetQuotations
      });
    } catch (error) {
      commit(MUTATIONS.GET_REJECTED_QUOTATION_LIST_FAILURE, error);
    }
  }
};
