<template>
  <ion-page>
    <ion-header no-border class="bar-header">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true" class="content">
      <div id="home-container">
        <div class="logo-container">
          <div class="logo">
            <ion-img src="/assets/images/splash_logo.png" alt="Treedots logo" />
          </div>
        </div>
        <Form @submit="onResetPassword" v-slot="{ meta, errors, values }">
          <Field v-if="email" name="email" :label="$t('email')" :rules="{ required: false }">
            <ion-item class="box">
              <ion-input disabled :value="emailInfo" type="email" :placeholder="$t('email')"></ion-input>
            </ion-item>
          </Field>
          <Field
            name="userPassword"
            :label="$t('password')"
            :rules="{ required: true }"
            v-slot="{ field, errorMessage }"
          >
            <ion-item
              lines="none"
              class="box"
              :class="{
                error:
                  errorMessage !== undefined || errors.confirmPassword === 'Password entered does not match'
              }"
            >
              <ion-input
                v-bind="field"
                id="userPassword"
                :type="passVisibility.type"
                :placeholder="$t('password')"
              ></ion-input>
              <ion-img @click="passToggle" :src="passVisibility.visibility" />
            </ion-item>
          </Field>

          <Field
            name="confirmPassword"
            :label="$t('password')"
            :rules="{ required: true, is: values.userPassword }"
            v-slot="{ field, errorMessage }"
          >
            <ion-item
              lines="none"
              class="box"
              :class="{
                error: errorMessage !== undefined
              }"
            >
              <ion-input
                v-bind="field"
                id="confirmPassword"
                :type="confirmPassVisibility.type"
                :placeholder="$t('confirm_password')"
              ></ion-input>
              <ion-img @click="confirmPassToggle" :src="confirmPassVisibility.visibility" />
            </ion-item>
          </Field>
          <Error-Message name="confirmPassword" class="error" as="div" />
          <div class="button-groups t-m-2">
            <ion-button class="home-btn" expand="block" :disabled="!meta.valid" type="submit">
              <span>{{ $t('confirm') }}</span>
            </ion-button>
          </div>
        </Form>
      </div>
    </ion-content>
    <ion-toast
      :is-open="openToast && email"
      mode="ios"
      color="dark"
      :message="$t('password_successfully_set')"
      :duration="2000"
      position="top"
    >
    </ion-toast>
    <ion-loading
      :is-open="isOpenLoadingref"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButton,
  IonInput,
  IonItem,
  IonImg
} from '@ionic/vue';
import { defineComponent, ref, reactive, inject } from 'vue';
import { cipher } from '../../../../services/shared/helper/cipher';
import { useRouter } from 'vue-router';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useMutation } from '@vue/apollo-composable';
import { updatePassword } from '@/services/shared/graphql';
const PASSWORD_SHOW = '/assets/images/password-show.svg';
const PASSWORD_HIDE = '/assets/images/password-hide.svg';
export default defineComponent({
  name: 'ResetPassword',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonInput,
    IonItem,
    IonImg,
    Form,
    Field,
    ErrorMessage
  },
  setup() {
    const storage = inject('$storage');
    const router = useRouter();
    const userId = ref('');
    const email = ref('');
    const setUserId = (state) => (userId.value = state);
    const setEmail = (state) => (email.value = state);

    const encrypt = cipher();
    const openToast = ref(false);
    const passVisibility = reactive({
      type: 'password',
      visibility: PASSWORD_HIDE
    });
    const confirmPassVisibility = reactive({
      type: 'password',
      visibility: PASSWORD_HIDE
    });
    const { mutate: updatePasswordFn } = useMutation(updatePassword);
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (value) => {
      isOpenLoadingref.value = value;
    };
    const setOpenToast = (state) => (openToast.value = state);
    const onResetPassword = async (values, { resetForm }) => {
      try {
        setOpenLoading(true);
        const res = await updatePasswordFn({
          userId: userId.value,
          password: encrypt(values.userPassword)
        });

        setOpenLoading(false);
        if (res.data.updatePassword) {
          setOpenToast(true);
          resetForm();
          router.push({
            name: 'LoginEmail'
          });
        }
      } catch (e) {
        setOpenLoading(false);
        console.log(e);
      }
    };
    const passToggle = () => {
      if (passVisibility.type === 'password') {
        passVisibility.type = 'text';
        passVisibility.visibility = PASSWORD_SHOW;
      } else {
        passVisibility.type = 'password';
        passVisibility.visibility = PASSWORD_HIDE;
      }
    };
    const confirmPassToggle = () => {
      if (confirmPassVisibility.type === 'password') {
        confirmPassVisibility.type = 'text';
        confirmPassVisibility.visibility = PASSWORD_SHOW;
      } else {
        confirmPassVisibility.type = 'password';
        confirmPassVisibility.visibility = PASSWORD_HIDE;
      }
    };
    return {
      onResetPassword,
      passVisibility,
      confirmPassVisibility,
      passToggle,
      confirmPassToggle,
      openToast,
      setOpenLoading,
      isOpenLoadingref,
      setUserId,
      setEmail,
      storage,
      router
    };
  },
  async mounted() {
    const resetPassUser = await this.storage.getUserResetPass();
    // backto login page if userId is null
    if (!resetPassUser || !resetPassUser.userId) {
      this.router.push({
        name: 'Home'
      });
    } else {
      this.setUserId(resetPassUser.userId);
      this.setEmail(resetPassUser?.email);
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/views/Home/home.scss';
@import 'ResetPassword.scss';
</style>
