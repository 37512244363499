import { REQUEST, SUCCESS, FAILURE } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_FAILURE',

  SALE_UPDATE_CUSTOMER_REQUEST: 'SALE_UPDATE_CUSTOMER_REQUEST',
  SALE_UPDATE_CUSTOMER_SUCCESS: 'SALE_UPDATE_CUSTOMER_SUCCESS',
  SALE_UPDATE_CUSTOMER_FAILURE: 'SALE_UPDATE_CUSTOMER_FAILURE',

  // reset state
  RESET_STATE: 'RESET_STATE',
  SEND_AVATAR_LINK: 'SEND_AVATAR_LINK'
};

export default {
  [MUTATIONS.GET_CURRENT_USER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CURRENT_USER_SUCCESS](state, user) {
    SUCCESS(state);
    state.user = user;
  },
  [MUTATIONS.GET_CURRENT_USER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.SALE_UPDATE_CUSTOMER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.SALE_UPDATE_CUSTOMER_SUCCESS](state, updateUser) {
    SUCCESS(state);
    state.updateUser = updateUser;
  },
  [MUTATIONS.SALE_UPDATE_CUSTOMER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.RESET_STATE](state) {
    Object.assign(state, {});
  },
  async [MUTATIONS.SEND_AVATAR_LINK](state) {
    state.cropImg = new Date().getTime();
  }
};
