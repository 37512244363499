import { DEFAULT_STATE } from '@/modules/b2b/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  ...DEFAULT_STATE,
  list: {
    data: []
  },
  item: null,
  totalCount: 0,
  params: {
    statusIds: [],
    customerBuyerIds: [],
    isIncludeTotalCount: true,
    isLoadMore: false,
    limit: 5,
    offset: 0,
    startDeliveryDate: null,
    endDeliveryDate: null,
    blockStatus: null
  },
  customerAndStatus: null,
  detailPurchase: null,
  itemDisputes: null,
  invoiceList: null,
  invoice: null,
  sendInvoiceToPeppolByOrderId: false,
  customerPeppol: null,
  tenantIntegrationStorecove: null,
  repeatOrder: null,
  viewPrice: null,
  itemAmendments: null,
  list_customer_b2b: [],
  totalCountCompany: 0,
  isRepeatOrderSuccess: false,
  unAvailableRepeatSkuIds: [],
  isEnableSoftBlockOrder: false
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
