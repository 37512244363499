import { alertController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

export const errorAlert = async (message, mode = 'ios') => {
  const { t } = useI18n();
  const alert = alertController.create({
    mode,
    cssClass: 'my-custom-class',
    header: 'Alert',
    message,
    buttons: [
      {
        text: t('OK')
      }
    ]
  });

  await alert.present();
};
