export const DEFAULT_GETTERS = {
  status: (state) => state.status,
  loading: (state) => state.loading,
  error: (state) => state.error,
  message: (state) => (state.error ? state.error.message : ''),
  resetCategory: (state) => state.resetCategory
};

export const DEFAULT_STATE = {
  status: false,
  error: null,
  message: '',
  loading: false,
  resetCategory: false
};
