export const INVOICE_PAYMENT_TYPE = {
  BANK_TRANSFER: 4,
  CASH_DEPOSIT: 8,
  CHEQUE: 3,
  CASH_PAYMENT_COLLECTION: 2,
  PAYNOW: 6,
  CREDIT_CARD: 1, // MASTER CARD, VISA
  CUSTOMER_CREDIT: 7,
  NETS: 5
};
export const INVOICE_PAYMENT_NAME = {
  4: 'Bank Transfer',
  8: 'Cash Deposit',
  3: 'Cheque',
  2: 'Cash Payment (Collection)',
  6: 'Paynow',
  1: 'Credit / Debit Card',
  7: 'Customer Credit',
  5: 'NETS'
};
export const TOP_UP_CREDIT_ENTRY = {
  ACCOUNT_PAGE: `ACCOUNT_PAGE`,
  INVOICES_PAGE: `INVOICES_PAGE`
};
export const INVOICE_PAYMENT_METHOD = {
  DIGITAL: [6, 1, 7, 5],
  OTHER: [4, 8, 3, 2]
};

export const CARD_INVOICES_IMAGE = {
  BANK_IMAGE: '/assets/images/bank.svg',
  CASH_DEPOSIT_IMAGE: '/assets/images/cash-deposit.svg',
  CHEQUE_IMAGE: '/assets/images/cheque.svg',
  CASH_PAYMENT_IMAGE: '/assets/images/cash.svg'
};

export const PAYMENT_SUMMARY_STATUS_LABEL = {
  approved: 'Paid',
  pending: 'Pending',
  rejected: 'Rejected',
  canceled: 'Cancelled'
};

export const PAYMENT_HISTORY_STATUS = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  PARTIAL_PAID: 'partially paid'
};

export const PAYMENT_HISTORY_LIST_COLOR = {
  Paid: 'text-success-500 success-bg',
  'Pending Approval': 'text-gray-500 gray-bg',
  'Pending Validation': 'text-primary-orange-500 orange-bg',
  Rejected: 'text-error-500 red-bg',
  Cancelled: 'text-error-500 red-bg'
};

export const PAYMENT_HISTORY_LIST_TEXT_COLOR = {
  approved: 'text-success-500 ',
  pending: 'text-primary-orange-500 ',
  rejected: 'text-error-500 ',
  canceled: 'text-error-500 ',
  Paid: 'text-success-500',
  'Pending Approval': 'text-gray-500',
  'Pending Validation': 'text-primary-orange-500 ',
  Rejected: 'text-error-500 ',
  Cancelled: 'text-error-500 '
};

export const CARD_INVOICES_LIST_COLOR = {
  PAID_COLOR: 'text-success-500 success-bg',
  UNPAID_COLOR: 'text-error-500 red-bg',
  AWAITING_PAYMENT_COLOR: 'text-primary-orange-500 orange-bg',
  PENDING_APPROVAL_COLOR: 'text-gray-500 gray-bg'
};

export const PAYMENT_HISTORY_LIST_STATUS = {
  PAID: 'Paid',
  PENDING_APPROVAL: 'Pending Approval',
  PENDING_VALIDATION: 'Pending Validation',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled'
};

export const MANUAL_PAYMENT_METHOD = {
  BANK_TRANSFER: 'Bank Transfer',
  CASH_DEPOSIT: 'Cash deposit',
  CHEQUE: 'Cheque',
  CASH_PAYMENT_COLLECTION: 'Cash Payment (Collection)'
};

export const DIGITAL_PAYMENT_METHOD = {
  FLUID: 'Fluid'
};

export const DIGITAL_PAYMENT_TYPE_LABEL = {
  [INVOICE_PAYMENT_TYPE.PAYNOW]: 'Paynow',
  [INVOICE_PAYMENT_TYPE.CREDIT_CARD]: 'Credit / Debit Card',
  [INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT]: 'Customer Credit',
  [INVOICE_PAYMENT_TYPE.NETS]: 'Nets'
};
