import { modalController } from '@ionic/vue';

export const useModal = (component, cssName) => {
  let modal = null;

  const createModal = async (props) => {
    modal = await modalController.create({
      mode: 'md',
      component: component,
      cssClass: cssName,
      backdropDismiss: true,
      swipeToClose: true,
      componentProps: {
        ...props
      }
    });
    return modal.present();
  };

  const dismissModal = () => {
    return modal.dismiss();
  };
  return {
    createModal,
    modal,
    dismissModal
  };
};
