import { apolloClient } from '@/main';
import { getAppVersion, getMaintenanceInfo } from '@/modules/b2b/services/graphql';
import { getPlatforms } from '@ionic/vue';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  CHECK_VERSION_APP: 'CHECK_VERSION_APP',
  CHECK_MAINTENANCE_APP: 'CHECK_MAINTENANCE_APP'
};

export default {
  async [ACTIONS.CHECK_VERSION_APP]({ commit }, params) {
    const { data } = await apolloClient.query({
      query: getAppVersion,
      variables: params
    });

    commit(MUTATIONS.CHECK_VERSION_APP, data);
  },
  async [ACTIONS.CHECK_MAINTENANCE_APP]({ commit }) {
    var today = new Date().toISOString();
    const platformIonic = getPlatforms();
    const platform = platformIonic.includes('mobileweb') ? 'mobileweb' : platformIonic[0];
    const { data } = await apolloClient.query({
      query: getMaintenanceInfo,
      variables: {
        timeNow: today,
        platform
      }
    });
    commit(MUTATIONS.CHECK_MAINTENANCE_APP, data.getMaintenanceInfo);
  }
};
