import { DEFAULT_STATE } from '@/modules/sale/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state = {
  ...DEFAULT_STATE,
  listCustomerCard: {
    data: []
  },
  saleListStripeCard: [],
  listInvoices: {
    invoices: []
  },
  totalCountInvoices: 0,
  paramsListInvoices: {
    tenantId: 0,
    customerId: 0,
    isIncludeTotalCount: true,
    limit: 20,
    offset: 0,
    isLoadMore: false
  },
  paramsCreateBankPayment: {
    invoiceIDS: [],
    transactionDate: null,
    valueDate: null,
    paymentTypeId: 4,
    paymentProof: null,
    referenceCode: null,
    notes: null,
    totalPaidAmount: null,
    balanceAllocationAmount: null
  },
  paramsCreateCashChequePayment: {
    invoiceIDS: [],
    depositDate: null,
    valueDate: null,
    paymentTypeId: 4,
    paymentProof: null,
    referenceCode: null,
    notes: null,
    totalPaidAmount: null,
    balanceAllocationAmount: null
  },
  paymentSummary: {
    totalData: 0,
    totalPaidAmount: 0,
    totalCustomerBalanceUsed: 0,
    transactionDate: null,
    valueDate: null,
    notes: null,
    balanceAllocationNotes: null,
    details: []
  },
  xeroCustomer: null,
  totalUnpaidInvoices: 0,
  totalOverdueInvoices: 0,
  selectedPaymentInvoices: null,
  selectedPaymentMethod: null,
  isFinanceModuleEnabled: false,
  paymentHistory: null,
  paymentHistoryList: [],
  paramsPaymentHistoryList: {
    tenant_id: 0,
    customer_id: 0,
    limit: 20,
    offset: 0,
    isLoadMore: false,
    isIncludeTotalCount: true
  },
  paramsTopUpCredit: {
    customerId: 0,
    tenantId: 0,
    transactionDate: '',
    valueDate: null,
    paymentTypeId: 0,
    totalPaidAmount: 0.0,
    paymentProof: [],
    referenceNumber: '',
    notes: null,
    stripeCardId: null,
    stripeCustomerId: null
  },
  paymentSummaryTopUp: null,
  cancelManualPayment: false,
  isLoadingCancellingPayment: false,
  topUpCreditEntry: `ACCOUNT_PAGE` || `INVOICES_PAGE`,
  paymentInvoiceDetails: null
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
