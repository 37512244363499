import { Capacitor } from '@capacitor/core';
import { CleverTap } from '@ionic-native/clevertap';
import meta from '../../../../package.json';
import { getAddressFormat } from '../../../modules/b2b/services/libs/helper';
import { stringifyArrays } from './helper';
const ct = CleverTap;

const { VUE_APP_ENV } = process.env;

const initialize = () => {
  if (!Capacitor.isNativePlatform()) return;
  document.addEventListener('deviceready', onDeviceReady, false);
};

const onDeviceReady = () => {
  VUE_APP_ENV !== 'production' && ct.setDebugLevel(1);

  ct.notifyDeviceReady();

  Capacitor.getPlatform() === 'ios' && ct.registerPush();

  console.log(`echo: onDeviceReady clevetap OK`);

  ct.enablePersonalization();

  //Create notification channel for Android 8 and above
  // const channel = VUE_APP_ENV === 'production' ? 'com.thetreedots' : 'com.thetreedots.testing';
  // ct.createNotificationChannel(channel, 'Notification', 'TreeDots App Push Notification', 5, true);
  /** use this for development testing on real device */
  ct.profileGetCleverTapID().then((id) => {
    console.log(`echo: CleverTapID: ${id} ==`);
  });
};

const setPushToken = (token) => {
  if (!Capacitor.isNativePlatform()) return;
  ct.setPushToken(token);
};

const uninitialize = () => {
  if (!Capacitor.isNativePlatform()) return;
  document.removeEventListener('deviceready', onDeviceReady, false);
};

const setUserProfile = async (signInData) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    const {
      id,
      email,
      first_name,
      last_name,
      mobile,
      gender,
      birth_date,
      country,
      type,
      language
    } = signInData;
    const phone = mobile ? (mobile.startsWith('+') ? mobile : `+${mobile}`) : '';
    const fullName = `${first_name} ${last_name}`;
    const dob = birth_date ? new Date(birth_date) : '';
    const profile = {
      Identity: id,
      Name: fullName,
      Email: email,
      Phone: phone,
      Gender: gender ? gender.toUpperCase() : '',
      DOB: dob,
      Country_Code: country?.name,
      Country: country?.description,
      User_ID: id,
      User_Type: type?.name,
      User_App_Version: meta.version,
      User_App_Release_Date: meta.htmlVersion,
      Language: language ? language : 'en-US',
      'MSG-email': true,
      'MSG-push': true,
      'MSG-sms': true,
      'MSG-whatsapp': true
    };
    await ct.onUserLogin(profile);
  } catch (error) {
    console.log('onUserLogin', error);
  }
};

export const updateUserProfile = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    const { email, firstName, lastName, gender, birthDate, mobile, language } = data;
    const fullName = `${firstName} ${lastName}`;
    const dob = birthDate ? new Date(birthDate) : '';
    await ct.profileSet({
      Name: fullName,
      Email: email,
      Phone: mobile,
      DOB: dob,
      Gender: gender ? gender.toUpperCase() : '',
      Language: language ? language : undefined
    });
  } catch (error) {
    console.error('cleverTap: updateUserProfile: ', error);
  }
};

export const updateUserLanguage = async (language) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.profileSet({
      Language: language
    });
  } catch (error) {
    console.error('cleverTap: updateUserLanguage: ', error);
  }
};

const clearUserProfile = () => {
  if (!Capacitor.isNativePlatform()) return;
  ct.profileSet({
    'MSG-push': false
  });
};

const setState = (state) => {
  if (!Capacitor.isNativePlatform()) return;
  ct.profileSet({
    State: state
  });
};
const onUserSignUpStarted = async () => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithName('Sign Up Started');
  } catch (error) {
    console.log('onUserSignUpStarted', error);
  }
};
const onUserSignUpSuccess = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { first_name, last_name, user_type_id, language, email, country_id, mobile, device_info } = data;
  try {
    await ct.recordEventWithNameAndProps('Sign Up SuccessFul', {
      First_Name: first_name,
      Last_Name: last_name,
      User_ID: user_type_id,
      User_Created_At: new Date(),
      Country: country_id,
      Language: language,
      Mobile_No: mobile,
      Email: email,
      Campaign_Referral: null,
      Device_Information: device_info
    });
  } catch (error) {
    console.log('onUserSignUpSuccess', error);
  }
};
const onUserSignInStarted = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Log in Started', {
      Login_Method: data
    });
  } catch (error) {
    console.log('onUserSignInStarted', error);
  }
};
const onUserSignInSuccess = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const {
    first_name,
    last_name,
    id,
    type,
    date_created,
    country,
    language,
    mobile,
    email,
    device_info,
    login_method,
    login_date_time
  } = data;
  try {
    await ct.recordEventWithNameAndProps('Log in SuccessFul', {
      First_Name: first_name,
      Last_Name: last_name,
      User_ID: id,
      User_Type: type.name,
      User_Created_At: new Date(date_created) || '',
      Country: country.description,
      Language: language,
      Mobile_No: mobile,
      Email: email,
      Campaign_Referral: null,
      Login_Method: login_method,
      Login_Datetime: login_date_time,
      Device_Information: device_info
    });
  } catch (error) {
    console.log('onUserSignInSuccess', error);
  }
};
const onUserSelectCompany = async (data) => {
  const { customer_type_id, name, branches, halal_products } = data;
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Selected Company', {
      Customer_Id: customer_type_id,
      Company_Name: name,
      Created_At: null,
      Branch: branches.length > 0 ? 'Yes' : 'No',
      Halal: halal_products ? 'Yes' : 'No',
      Blacklist: 'No',
      Is_Duplicate: 'No',
      Profile: 'No',
      Cuisines: 'No'
    });
  } catch (error) {
    console.log('onUserSelectCompany', error);
  }
};
const onUserAddCompany = async () => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithName('Add Company Started');
  } catch (error) {
    console.log('onUserAddCompany', error);
  }
};
const onUserCompanySubmittedApproval = async (customer_id) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Company Submitted Approval', {
      Customer_Id: customer_id || ''
    });
  } catch (error) {
    console.log('onUserCompanySubmittedApproval', error);
  }
};
const onUserSuccessAddCompany = async (data) => {
  const {
    customer_type_id,
    profile,
    address,
    first_name,
    last_name,
    phone,
    is_duplicate,
    halal_products
  } = data;
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Add Company Successful', {
      Customer_Id: customer_type_id || '',
      Profile: profile || '',
      Full_Address: getAddressFormat(address) || '',
      Key_Contact_First_Name: first_name || '',
      Key_Contact_Last_Name: last_name || '',
      Key_Contact_Phone_No: phone || '',
      Is_Duplicate: is_duplicate ? 'Yes' : 'No',
      Halal: halal_products ? 'Yes' : 'No'
    });
  } catch (error) {
    console.log('onUserSuccessAddCompany', error);
  }
};
const onUserBrowseProduct = async () => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithName('Browsing Product Started');
  } catch (error) {
    console.log('onUserBrowseProduct', error);
  }
};
const onUserSearchProduct = async (terms) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Search Product Started', terms);
  } catch (error) {
    console.log('onUserSearchProduct', error);
  }
};

const onUserOpenFilterProduct = async () => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithName('Sort Products Started');
  } catch (error) {
    console.log('onUserOpenFilterProduct', error);
  }
};
const onUserApplyFilterProduct = async (filter_name) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Sort Products Successful', {
      Filter_Name: filter_name.value
    });
  } catch (error) {
    console.log('onUserApplyFilterProduct', error);
  }
};

const onUserSelectCategory = async (category_name) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Select Category', {
      Category_Name: category_name
    });
  } catch (error) {
    console.log('onUserSelectCategory', error);
  }
};
const onUserViewProductDetail = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { id, name, categories, halal, oos, tenant } = data;
  try {
    await ct.recordEventWithNameAndProps('View Product Detail', {
      Product_Id: id,
      Product_Name: name,
      Product_Sub_Category: categories.sub_categories.name,
      Halal_Certification: halal,
      Supplier_Name: tenant.tenant.name,
      Oos: oos ? 'Yes' : 'No'
    });
  } catch (error) {
    console.log('onUserViewProductDetail', error);
  }
};
const onUserSelectOtherVariants = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const {
    product_id,
    product_name,
    product_sub_category,
    halal_certification,
    product_brand,
    sku_name
  } = data;
  try {
    await ct.recordEventWithNameAndProps('User Select Other Variants', {
      Product_Id: product_id,
      Product_Name: product_name,
      Product_Sub_Category: product_sub_category,
      Halal_Certification: halal_certification,
      Supplier_Name: product_brand,
      Sku_Name: sku_name
    });
  } catch (error) {
    console.log('onUserSelectOtherVariants', error);
  }
};
const onUserViewRecommendProduct = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { name, halal, id, categories, tenant, skuIdsByCategory } = data;
  try {
    await ct.recordEventWithNameAndProps('Recommended product viewed', {
      Product_Name: name,
      Product_Id: id,
      Product_Sub_Category: categories.sub_categories.name,
      Sku_Name: skuIdsByCategory[1],
      Sku_Id: skuIdsByCategory,
      Halal_Certification: halal,
      Supplier_Name: tenant.tenant.name
    });
  } catch (error) {
    console.log('onUserViewRecommendProduct', error);
  }
};
const onUserShareProduct = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { id, name, halal, tenant } = data;
  try {
    await ct.recordEventWithNameAndProps('Share Product Started', {
      Product_Name: name,
      Product_Id: id,
      Halal_Certification: halal,
      Supplier_Name: tenant.tenant.name
    });
  } catch (error) {
    console.log('onUserShareProduct', error);
  }
};

const onUserShareProductSuccess = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('Share Product Successful', data);
  } catch (error) {
    console.log('onUserShareProductSuccess', error);
  }
};
const onUserAddToCart = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { id, name, categories, halal, tenant } = data.data;
  const { sku, sku_id, unit_price } = data.sku;
  try {
    await ct.recordEventWithNameAndProps('Product Added to Cart', {
      Product_Id: id,
      Product_Name: name,
      Sku_Id: sku_id,
      Sku_Name: sku,
      Product_Sub_Category: categories.sub_categories.name,
      Quantity: data.orderQty,
      Total_Price: unit_price * data.orderQty,
      Clearance_Item: '',
      Halal_Certification: halal ? 'Yes' : 'No',
      Supplier_Name: tenant.tenant.name,
      Company_Name: tenant.tenant.name
    });
  } catch (error) {
    console.log('onUserAddToCart', error);
  }
};

const onUserPlaceOrder = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { cartList, items, companyName } = data;
  const supplierName = cartList[0].tenant_name;
  const subTotal = cartList.reduce((total, current) => total + current.total_price, 0);
  const stringifiedArrays = stringifyArrays({
    Product_Name: cartList.map((item) => item.product_name),
    Sku_Name: cartList.map((item) => item.sku),
    Sku_Id: cartList.map((item) => item.sku_id),
    Sku_Unit_Price: cartList.map((item) => item.unit_price),
    Sku_Quantity: cartList.map((item) => item.order_qty),
    Tax_Amount: cartList.map((item) => item.tax_rate)
  });
  try {
    await ct.recordEventWithNameAndProps('User Place Order', {
      Company_Name: companyName,
      Supplier_Name: supplierName,
      Delivery_Date: items.deliveryDate,
      Delivery_Time: items.deliveryTime,
      Sub_Total: subTotal,
      Total_Amount: subTotal,
      Payment_Method: items.paymentMethod || 'STRIPE',
      Billing_Address_Id: items.billingAddressId,
      Delivery_Address: items.deliveryAddress,
      ...stringifiedArrays
    });
  } catch (error) {
    console.log('onUserPlaceOrder', error);
  }
};

const onUserPushNotification = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { id, created_at, message, sender_user_id, target_user_id, title } = data;
  try {
    await ct.recordEventWithNameAndProps('User Tap Push Notification', {
      Notification_Id: id,
      Notification_Title: title,
      Notification_Message: message,
      Notification_Sender_Id: sender_user_id,
      Notification_Target_Id: target_user_id,
      Notification_Created_At: created_at
    });
  } catch (error) {
    console.log('onUserPushNotification', error);
  }
};
const onUserProceedInvoices = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { companyName, invoices, totalAmountInvoices } = data;
  const invoicesNo = invoices.map((item) => item.invoiceNumber);
  try {
    await ct.recordEventWithNameAndProps('User Proceed to Payment Page', {
      Company_Name: companyName,
      No_Invoices: invoicesNo,
      Total_Outstanding_Amount: totalAmountInvoices
    });
  } catch (error) {
    console.log('onUserShareProduct', error);
  }
};
const onUserPayInvoice = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  const { amountSubmitted, paymentType } = data;
  try {
    await ct.recordEventWithNameAndProps('User Successfully Submit Payment', {
      Amount_Submitted: amountSubmitted,
      Payment_Type: paymentType
    });
  } catch (error) {
    console.log('onUserShareProduct', error);
  }
};
const recordEventWithName = async (name) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithName(name);
  } catch (error) {
    console.log(name, error);
  }
};

const onUserRepeatOrder = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('User Repeat Order', {
      Order_Status: data
    });
  } catch (error) {
    console.log('onUserRepeatOrder', error);
  }
};

const onUserBrowseProductCategory = async (product_name) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('User Browse Product Category', {
      Product_Name: product_name
    });
  } catch (error) {
    console.log('onUserBrowseProductCategory', error);
  }
};

const onUserAppliedFilterOrSort = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('User Applied Filter or Sort', {
      Filter_Name: data.filters ?? null,
      Sort_Name: data.sort ?? null
    });
  } catch (error) {
    console.log('onUserAppliedFilterOrSort', error);
  }
};

const onUserAppliedQuickFilter = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('User Applied Quick Filter', {
      Filter_Name: data
    });
  } catch (error) {
    console.log('onUserAppliedQuickFilter', error);
  }
};
const onUserAppliedQuickSort = async (data) => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithNameAndProps('User Applied Quick Sort', {
      Sort_Name: data
    });
  } catch (error) {
    console.log('onUserAppliedQuickSort', error);
  }
};

const onUserMakePaymentToUnblockBookingOrder = async () => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithName('User Make Payment to Unblock Booking Order');
  } catch (error) {
    console.log('onUserMakePaymentToUnblockBookingOrder', error);
  }
};

const onUserMakePaymentToUnblockSoftBlockedOrder = async () => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await ct.recordEventWithName('User Make Payment to Unblock Soft Blocked Order');
  } catch (error) {
    console.log('onUserMakePaymentToUnblockSoftBlockedOrder', error);
  }
};

const clevertap = {
  setPushToken,
  initialize,
  uninitialize,
  setUserProfile,
  clearUserProfile,
  setState,
  updateUserProfile,
  updateUserLanguage,
  recordEventWithName,
  onUserSelectCompany,
  onUserAddCompany,
  onUserCompanySubmittedApproval,
  onUserSuccessAddCompany,
  onUserBrowseProduct,
  onUserSearchProduct,
  onUserOpenFilterProduct,
  onUserApplyFilterProduct,
  onUserSelectCategory,
  onUserViewProductDetail,
  onUserSelectOtherVariants,
  onUserViewRecommendProduct,
  onUserShareProduct,
  onUserShareProductSuccess,
  onUserAddToCart,
  onUserPlaceOrder,
  onUserPushNotification,
  onUserPayInvoice,
  onUserProceedInvoices,
  onUserSignUpStarted,
  onUserSignUpSuccess,
  onUserSignInStarted,
  onUserSignInSuccess,
  onUserRepeatOrder,
  onUserBrowseProductCategory,
  onUserAppliedFilterOrSort,
  onUserAppliedQuickFilter,
  onUserAppliedQuickSort,
  onUserMakePaymentToUnblockBookingOrder,
  onUserMakePaymentToUnblockSoftBlockedOrder
};

export default clevertap;
