import gql from 'graphql-tag';

export const getCategories = gql`
  query getCategories($active: Boolean) {
    getCategories(active: $active) {
      id
      name
      image
    }
  }
`;
