const STORE_KEY = 'shared';

export const setBadgeNumber = async (store, customerId) => {
  const { dispatch } = store;
  await dispatch(`${STORE_KEY}/setBadgeNumber`, customerId);
};

export const getNotificationBadge = (store) => {
  const { getters } = store;
  return getters[`${STORE_KEY}/getNotificationBadgeNumber`];
};

export const getCartBadge = (store) => {
  const { getters } = store;
  return getters[`${STORE_KEY}/getCartBadgeNumber`];
};
