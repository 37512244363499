export const sortBy = {
  name: 'name',
  popular: 'popular',
  newest: 'newest',
  purchase: 'purchased'
};

export const sortType = {
  asc: 'asc',
  desc: 'desc'
};

const listSortForAll = [
  {
    key: 'popular',
    sortBy: sortBy.popular,
    sortType: sortType.desc,
    message: 'most_popular'
  },
  {
    key: 'newest',
    sortBy: sortBy.newest,
    sortType: sortType.desc,
    message: 'newest'
  },
  {
    key: 'a-z',
    sortBy: sortBy.name,
    sortType: sortType.asc,
    message: 'product_name_a_z'
  },
  {
    key: 'z-a',
    sortBy: sortBy.name,
    sortType: sortType.desc,
    message: 'product_name_z_a'
  }
];

const listSortForHistory = [
  {
    key: 'popular',
    sortBy: sortBy.popular,
    sortType: sortType.desc,
    message: 'most_purchased'
  },
  {
    key: 'NTO-purchase',
    sortBy: sortBy.purchase,
    sortType: sortType.desc,
    message: 'date_purchased_new_to_old'
  },
  {
    key: 'OTN-purchase',
    sortBy: sortBy.purchase,
    sortType: sortType.asc,
    message: 'date_purchased_old_to_new'
  },
  {
    key: 'a-z',
    sortBy: sortBy.name,
    sortType: sortType.asc,
    message: 'product_name_a_z'
  },
  {
    key: 'z-a',
    sortBy: sortBy.name,
    sortType: sortType.desc,
    message: 'product_name_z_a'
  }
];

const categoryList = {
  history: listSortForHistory,
  all: listSortForAll
};

export const SORTING_DATA = {
  categoryList,
  listSortForHistory,
  listSortForAll
};
