export const NOTIFICATION_CATEGORY_ICON_PATH = {
  BOX: '/assets/images/box-product.svg',
  FRUITS: '/assets/images/fruits.svg',
  PRICE_TAG: '/assets/images/price-tag.svg',
  SETTINGS: '/assets/images/settings.svg',
  INFO: '/assets/images/info.svg'
};

export const QUOTATION_STATUSSES = {
  ALL: 'ALL',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',

  PENDING_APPROVAL: 'PENDING APPROVAL',
  REQUESTED: 'REQUESTED',
  RECEIVED: 'RECEIVED',
  REJECTED: 'REJECTED'
};
