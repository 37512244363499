import Home from '../../views/Home/index';
import ForgotPassword from './views/ForgotPassword';
import LoginEmail from './views/LoginEmail/index';
import LoginMobile from './views/LoginMobile';
import Onboarding from './views/onboarding';
import ResetPassword from './views/ResetPassword';
import SetPassword from './views/SetPassword';
import SignUpInfo from './views/SignUp/SignUpInfo';
const sharedRoutes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/shared/auth/signup-info',
    name: 'SignUpInfo',
    component: SignUpInfo
  },
  {
    path: '/shared/auth/email',
    name: 'LoginEmail',
    component: LoginEmail
  },
  {
    path: '/shared/auth/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/shared/auth/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/shared/auth/set-password',
    name: 'SetPassword',
    component: SetPassword
  },
  {
    path: '/shared/auth/mobile',
    name: 'LoginMobile',
    component: LoginMobile
  },
  {
    path: '/shared/auth/onboarding',
    name: 'Onboarding',
    props: true,
    component: Onboarding
  }
];

export default sharedRoutes;
