import { toastController } from '@ionic/vue';

export const useToast = () => {
  const showToast = async (message) => {
    const toast = await toastController.create({
      message,
      position: `bottom`,
      color: `dark`,
      duration: 2000
    });
    return toast.present();
  };

  return {
    showToast
  };
};
