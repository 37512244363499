import dayjs from 'dayjs';

const localeChinese = {
  name: 'zh-custom',
  weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
  weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  weekdaysMin: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  weekStart: 1,
  yearStart: 4,
  months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
  monthsShort: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
  ordinal: (n) => `${n}º`,
  formats: {
    LTS: 'hh:mm:ss A',
    LT: 'hh:mm A',
    L: 'MM/DD/YYYY',
    LL: 'YYYY年MM月DD日',
    LLL: 'YYYY年MM月DD日 hh:mm A',
    LLLL: 'dddd, YYYY年MM月DD日 hh:mm A',
    l: 'D/M/YYYY',
    ll: 'YYYY年MM月DD日',
    lll: 'YYYY年MM月DD日 hh:mm A',
    llll: 'ddd, YYYY年MM月DD日 hh:mm A'
  },
  relativeTime: {
    future: '在 %s',
    past: '%s 前',
    s: '几秒钟',
    m: '一分钟',
    mm: '%d 分钟',
    h: '一小时',
    hh: '%d 小时',
    d: '一天',
    dd: '%d 天',
    M: '一个月',
    MM: '%d 个月',
    y: '一年',
    yy: '%d 年'
  }
};

dayjs.locale(localeChinese, null, true);

export default localeChinese;
