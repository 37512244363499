<template>
  <ion-img :src="img" @ionError="errorLoadImage" />
</template>
<script>
import { DEFAULT_NO_IMAGE } from '@/services/shared/helper/constants';
import { getImage } from '@/utils/';
import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
  name: 'Image',
  emits: ['errorLoadImg', 'errorLoadImageCustomer'],
  props: {
    image: {
      type: String,
      required: true
    },
    imagePath: {
      type: String,
      required: true
    },
    imageDefault: {
      type: String,
      default: DEFAULT_NO_IMAGE
    }
  },
  setup(props, { emit }) {
    const img = ref(props.imageDefault);
    onMounted(async () => {
      img.value = await getImage(props.image, props.imagePath);
    });
    const errorLoadImage = (event) => {
      event.target.src = props.imageDefault;
      emit('errorLoadImg', event.target.src);
      if (props.imagePath === `customer-pictures`) {
        emit('errorLoadImageCustomer', event.target.src);
      }
    };
    return { errorLoadImage, img };
  }
});
</script>
<style lang="scss" scoped></style>
