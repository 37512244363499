import { state as shared } from '../modules/shared/store';
import { state as b2b } from '../modules/b2b/store/state';
import { state as sale } from '../modules/sale/store/state';

const defaultState = {
  shared,
  b2b,
  sale
};

export const MUTATIONS = {
  RESET_STATE: 'RESET_STATE'
};

export default {
  [MUTATIONS.RESET_STATE](state) {
    Object.assign(state, defaultState);
  }
};
