import { FAILURE, REQUEST, SUCCESS } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  GET_PURCHASE_REQUEST: 'GET_PURCHASE_REQUEST',
  GET_PURCHASE_SUCCESS: 'GET_PURCHASE_SUCCESS',
  GET_PURCHASE_FAILURE: 'GET_PURCHASE_FAILURE',

  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',

  GET_PURCHASE_DETAIL_REQUEST: 'GET_PURCHASE_DETAIL_REQUEST',
  GET_PURCHASE_DETAIL_SUCCESS: 'GET_PURCHASE_DETAIL_SUCCESS',
  GET_PURCHASE_DETAIL_FAILURE: 'GET_PURCHASE_DETAIL_FAILURE',

  ORDER_ITEM_DISPUTES_REQUEST: 'ORDER_ITEM_DISPUTES_REQUEST',
  ORDER_ITEM_DISPUTES_SUCCESS: 'ORDER_ITEM_DISPUTES_SUCCESS',
  ORDER_ITEM_DISPUTES_FAILURE: 'ORDER_ITEM_DISPUTES_FAILURE',

  GET_LIST_INVOICE_REQUEST: 'GET_LIST_INVOICE_REQUEST',
  GET_LIST_INVOICE_SUCCESS: 'GET_LIST_INVOICE_SUCCESS',
  GET_LIST_INVOICE_FAILURE: 'GET_LIST_INVOICE_FAILURE',

  GET_INVOICE_BY_ID_REQUEST: 'GET_INVOICE_BY_ID_REQUEST',
  GET_INVOICE_BY_ID_SUCCESS: 'GET_INVOICE_BY_ID_SUCCESS',
  GET_INVOICE_BY_ID_FAILURE: 'GET_INVOICE_BY_ID_FAILURE',

  SEND_INVOICE_TO_MAIL_BY_ORDER_ID_REQUEST: 'SEND_INVOICE_TO_MAIL_BY_ORDER_ID_REQUEST',
  SEND_INVOICE_TO_MAIL_BY_ORDER_ID_SUCCESS: 'SEND_INVOICE_TO_MAIL_BY_ORDER_ID_SUCCESS',
  SEND_INVOICE_TO_MAIL_BY_ORDER_ID_FAILURE: 'SEND_INVOICE_TO_MAIL_BY_ORDER_ID_FAILURE',

  SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_REQUEST: 'SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_REQUEST',
  SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_SUCCESS: 'SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_SUCCESS',
  SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_FAILURE: 'SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_FAILURE',

  GET_CUSTOMER_PEPPOL_REQUEST: 'GET_CUSTOMER_PEPPOL_REQUEST',
  GET_CUSTOMER_PEPPOL_SUCCESS: 'GET_CUSTOMER_PEPPOL_SUCCESS',
  GET_CUSTOMER_PEPPOL_FAILURE: 'GET_CUSTOMER_PEPPOL_FAILURE',

  GET_TENANT_INTEGRATION_STORECOVE_REQUEST: 'GET_TENANT_INTEGRATION_STORECOVE_REQUEST',
  GET_TENANT_INTEGRATION_STORECOVE_SUCCESS: 'GET_TENANT_INTEGRATION_STORECOVE_SUCCESS',
  GET_TENANT_INTEGRATION_STORECOVE_FAILURE: 'GET_TENANT_INTEGRATION_STORECOVE_FAILURE',

  REPEAT_ORDER_SALE_REQUEST: 'REPEAT_ORDER_SALE_REQUEST',
  REPEAT_ORDER_SALE_SUCCESS: 'REPEAT_ORDER_SALE_SUCCESS',
  REPEAT_ORDER_SALE_FAILURE: 'REPEAT_ORDER_SALE_FAILURE',

  GET_VIEWS_PRICES_REQUEST: 'GET_VIEWS_PRICES_REQUEST',
  GET_VIEWS_PRICES_SUCCESS: 'GET_VIEWS_PRICES_SUCCESS',
  GET_VIEWS_PRICES_FAILURE: 'GET_VIEWS_PRICES_FAILURE',

  CANCEL_ORDER_REQUEST: 'CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE: 'CANCEL_ORDER_FAILURE',

  SALE_GET_CUSTOMERS_ORDERS_SUCCESS: 'SALE_GET_CUSTOMERS_ORDERS_SUCCESS',
  SALE_GET_CUSTOMERS_ORDERS_FAILURE: 'SALE_GET_CUSTOMERS_ORDERS_FAILURE',

  SALE_GET_TOTAL_COUNT_ORDER_SUCCESS: 'SALE_GET_TOTAL_COUNT_ORDER_SUCCESS',
  SALE_GET_TOTAL_COUNT_ORDER_FAILURE: 'SALE_GET_TOTAL_COUNT_ORDER_FAILURE',

  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',

  CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_SUCCESS: 'CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_SUCCESS',
  CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_FAILURE: 'CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_FAILURE'
};

export default {
  [MUTATIONS.GET_PURCHASE_REQUEST](state, isLoadMore) {
    REQUEST(state);
    state.isLoadMore = isLoadMore;
  },
  [MUTATIONS.GET_PURCHASE_SUCCESS](state, { list }) {
    SUCCESS(state);

    if (state.isLoadMore) {
      const data = [...state.list.data, ...list.data];
      state.list = {
        ...list,
        data
      };
    } else {
      state.list = list;
    }
  },
  [MUTATIONS.GET_PURCHASE_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.UPDATE_ORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_ORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.UPDATE_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.UPDATE_QUERY_PARAMS](state, { params }) {
    state.params = {
      ...state.params,
      ...params
    };
  },

  // get purchase detail
  [MUTATIONS.GET_PURCHASE_DETAIL_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_PURCHASE_DETAIL_SUCCESS](state, detailPurchase) {
    SUCCESS(state);
    state.detailPurchase = detailPurchase;
  },
  [MUTATIONS.GET_PURCHASE_DETAIL_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.ORDER_ITEM_DISPUTES_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ORDER_ITEM_DISPUTES_SUCCESS](state, itemDisputes) {
    SUCCESS(state);
    state.itemDisputes = itemDisputes;
  },
  [MUTATIONS.ORDER_ITEM_DISPUTES_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get invoice list
  [MUTATIONS.GET_LIST_INVOICE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_LIST_INVOICE_SUCCESS](state, invoiceList) {
    SUCCESS(state);
    state.invoiceList = invoiceList;
  },
  [MUTATIONS.GET_LIST_INVOICE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get invoice by id
  [MUTATIONS.GET_INVOICE_BY_ID_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_INVOICE_BY_ID_SUCCESS](state, invoice) {
    SUCCESS(state);
    state.invoice = invoice;
  },
  [MUTATIONS.GET_INVOICE_BY_ID_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // send invoice to mail by order id
  [MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_SUCCESS](state, sendInvoiceToPeppolByOrderId) {
    SUCCESS(state);
    state.sendInvoiceToPeppolByOrderId = sendInvoiceToPeppolByOrderId;
  },
  [MUTATIONS.SEND_INVOICE_TO_PEPPOL_BY_ORDER_ID_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get customer peppol
  [MUTATIONS.GET_CUSTOMER_PEPPOL_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CUSTOMER_PEPPOL_SUCCESS](state, customerPeppol) {
    SUCCESS(state);
    state.customerPeppol = customerPeppol;
  },
  [MUTATIONS.GET_CUSTOMER_PEPPOL_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get Tenant Integration Storecove
  [MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_SUCCESS](state, tenantIntegrationStorecove) {
    SUCCESS(state);
    state.tenantIntegrationStorecove = tenantIntegrationStorecove;
  },
  [MUTATIONS.GET_TENANT_INTEGRATION_STORECOVE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // Repeat order
  [MUTATIONS.REPEAT_ORDER_SALE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.REPEAT_ORDER_SALE_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.REPEAT_ORDER_SALE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get view price
  [MUTATIONS.GET_VIEWS_PRICES_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_VIEWS_PRICES_SUCCESS](state, viewPrice) {
    SUCCESS(state);
    state.viewPrice = viewPrice;
  },
  [MUTATIONS.GET_VIEWS_PRICES_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.CANCEL_ORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CANCEL_ORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.CANCEL_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.SALE_GET_CUSTOMERS_ORDERS_SUCCESS](state, payload) {
    SUCCESS(state);
    state.list_customer_sale = payload.list;
    state.totalCountCompany = payload.list.totalCount;
  },
  [MUTATIONS.SALE_GET_CUSTOMERS_ORDERS_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.SALE_GET_TOTAL_COUNT_ORDER_SUCCESS](state, payload) {
    SUCCESS(state);
    state.totalCount = payload;
  },
  [MUTATIONS.SALE_GET_TOTAL_COUNT_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_SUCCESS](state, params) {
    SUCCESS(state);
    state.isEnableSoftBlockOrder = params;
  },
  [MUTATIONS.CHECK_IS_ENABLE_SOFT_BLOCK_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  }
};
