export const REQUEST = (state) => {
  state.loading = true;
  state.error = null;
};

export const SUCCESS = (state) => {
  state.status = true;
  state.loading = false;
  state.error = null;
};

export const FAILURE = (state, error) => {
  state.loading = false;
  state.status = false;
  state.error = error;
};
